import React, { useRef, useState, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Button, Modal } from 'react-bootstrap';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import Icon from '../Icon/index';
import ArrowRight from '../../assets/images/icons/arrow-right.svg';
import Close from '../../assets/images/icons/close.svg';
import CarouselImage from '../../assets/images/temp/carousel-image-people.jpg';
import { isServer } from '../../util';

import './people.scss';

const People = (props) => {
  if (isServer()) {
    return <div className="people-main-container"></div>;
  }

  const [swiperRef, setSwiperRef] = useState(null);
  const [sliderActive, setSliderActive] = useState(false);
  const thisSlider = useRef(null);
  const showSlider = (item, index) => {
    setSliderActive(true);
    swiperRef.slideTo(index);
  };

  const peopleTab = Object.keys(props.fields.peopleTab).map((key) => {
    return props.fields.peopleTab[key];
  });

  return (
    <div className="people-main-container">
      <div className="container-fluid">
        <div className="row">
          {peopleTab.map((item, index) => {
            return (
              <div className="col-md-4 col-lg-3" key={index}>
                <div className="people-item">
                  <div className="people-item-image">
                    <img src={item.fields.img.value.src} alt={item.fields.img.value.alt} />
                  </div>
                  <div className="people-item-body">
                    <div className="people-item-name custom-secondary-button">
                      <Button onClick={() => showSlider(item, index)}>
                        <Text field={item.fields.name} />
                        {/* <img src={ArrowRight} width="40" height="40" /> */}
                        <Icon icon={'ArrowRight'} />
                      </Button>
                    </div>
                    <div className="people-item-role">
                      <p>
                        <Text field={item.fields.role} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={
          sliderActive ? 'show people-slider-modal-container' : 'people-slider-modal-container'
        }
        ref={thisSlider}
      >
        <div className="people-slider-background">
          <div className="people-slider-content">
            <div className="people-slider-head">
              <button className="close" onClick={() => setSliderActive(false)}>
                <img src={Close} alt="close icone" />
              </button>
            </div>
            <div className="people-slider-body">
              <Swiper
                onSwiper={setSwiperRef}
                slidesPerView={1}
                freeMode={true}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="people-slider"
              >
                {peopleTab.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="people-slider-inner-content">
                        <div className="people-slider-image">
                          <img src={item.fields.img.value.src} alt={item.fields.img.value.alt} />
                        </div>
                        <div className="people-slider-inner-body">
                          <div className="people-slider-body-content">
                            <h4>
                              <Text field={item.fields.name} />
                            </h4>
                            <h3>
                              <Text field={item.fields.role} />
                            </h3>
                            <p>
                              <Text field={item.fields.description} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(People);
