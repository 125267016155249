import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import Image from '../../assets/images/temp/people-01.jpg';

import './result-key-numbers.scss';
import GradientText from '../GradientText/index';

const ResultsKeyMembers = (props) => {
  const keyCard = Object.keys(props.fields.cardTab).map((key) => {
    return props.fields.cardTab[key];
  });

  return (
    <div className="results-key-container container-fluid">
      <div className="results-key-caption-container">
        <div className="results-key-caption-content">
          <GradientText text={props.fields.caption.value} />
        </div>
        {props.fields.author.value !== '' ? (
          <div className="results-key-caption-author">
            <div className="results-key-caption-author-image">
              <img src={props.fields.img.value.src} alt="image" />
            </div>
            <div className="results-key-caption-author-title">
              <h4>{props.fields.author.value}</h4>
              <p>{props.fields.role.value}</p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="results-key-cards-container">
        <div className="results-key-cards-title">
          <h3>{props.fields.title.value}</h3>
        </div>
        <div className="results-key-cards-content">
          {keyCard.map((item, index) => {
            return (
              <div className="results-key-cards-item" key={index}>
                <div className="results-key-cards-item-content">
                  <label>{item.fields.label.value}</label>
                  <h2>{item.fields.price.value}</h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(ResultsKeyMembers);
