import React from 'react';
import logoWhite from '../../assets/images/logo/tinexta-white-logo.svg';
import { withSitecoreContext, Text, Placeholder, Link } from '@sitecore-jss/sitecore-jss-react';
import SwitchLanguage from '../SwitchLanguage/index';
import { NavLink } from 'react-router-dom';
import IconLinkedin from '../../assets/images/icons/linkedin.svg';
import IconYouTube from '../../assets/images/icons/youtube.svg';
import { Link as RouterLink } from 'react-router-dom';

const FooterContact = (props) => {
  return (
    <div className="footer-contact-container">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-2">
            <div className="footer-logo-container">
              <img src={logoWhite} alt="logo white" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="footer-info-container">
              <p>{props.fields.info.value}</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-action-container">
              <div className="social-icon">
                <a href={props.fields.linkedinUrl.value.url} rel="noreferrer" target={'_blank'}>
                  <img src={IconLinkedin} alt="linkedin" />
                </a>
              </div>
              <div className="social-icon">
                <a href={props.fields.youtubeUrl.value.url} rel="noreferrer" target={'_blank'}>
                  <img src={IconYouTube} alt="youtube" />
                </a>
              </div>
              <SwitchLanguage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(FooterContact);

// /sitecore/api/graph/items/master/ui?sc_apikey={A95D9C66-CD90-492E-A7B6-2597508CE8A2}
