import React, { useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import SecondaryButton from '../SecondaryButton/index';
const { isServer } = require('../../util');

import './side-text-banner.scss';

import { ReactComponent as BgBlur } from '../../assets/images/bg-images/bg-blur.svg';

import Image from '../../assets/images/bg-images/side-text-banner.jpg';
import MobileImage from '../../assets/images/bg-images/side-text-banner-mobile.jpg';

const SideTextBanner = (props) => {
  let bgImage;
  // console.log('sidetext', props);

  if (isServer()) {
    return <div></div>;
  }
  // useEffect(() => {
  if (window.innerWidth > 543) {
    // console.log(1);
    bgImage = props.fields.image.value.src;
  } else {
    // console.log(2);
    if (
      props.fields.mobileImage.value.src !== null &&
      props.fields.mobileImage.value.src !== undefined
    ) {
      // console.log(3);
      bgImage = props.fields.mobileImage.value.src;
    } else {
      // console.log(4);
      bgImage = props.fields.image.value.src;
    }
  }
  // });
  // console.log('bgImage', bgImage);
  return (
    <div className="side-text-banner-container">
      <div
        className="side-text-banner-content"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className="blur-backkground"></div>
        <div className="side-text-banner-body">
          <h2>{props.fields.title.value}</h2>
          <p>{props.fields.description.value}</p>
          <SecondaryButton
            link={props.fields.linkUrl.value.href}
            linkLabel={props.fields.linkLabel.value}
            white
          />
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SideTextBanner);
