import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import Carousel from 'react-bootstrap/Carousel';
import BtnPrimary from '../BtnPrimary/index';
import SecondaryButton from '../SecondaryButton/index';
// import heroVideo from '../../assets/video/example.mp4';

import './card-slider.scss';

import HeroImage from '../../assets/images/temp/hero-image.jpg';
import HeroImageMobile from '../../assets/images/temp/hero-image-mobile.jpg';
import { isServer } from '../../util';

const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const CardSlider = (props) => {
  if (isServer()) {
    return <></>;
  }

  // console.log('CardSlider', props);
  // const isSsr = useIsSsr();
  // if (isSsr) return null;

  const cardEl = useRef(null);
  const cardImage = useRef(null);
  // const [width, height] = useWindowSize();
  const [swiperRef, setSwiperRef] = useState(0);

  // const isSsr = useIsSsr();
  // if (isSsr) return null;

  let cards, cardsList, thisCarousel, indicatorsList, image;

  let indicators, thisSlider, itemsLength, itemActive, slidePosition, indicatorsContainer;
  const cardsSlider = Object.keys(props.item).map((key) => {
    return props.item[key];
  });

  useEffect(() => {
    // call api or anything
    if (swiperRef) {
      // console.log('teeeeeeeeeeeeeeeeeest');
      // swiperRef.slideTo(1);
      // setSwiperRef(1);
      // setTimeout(() => {
      thisCarousel = cardEl.current.element;
      indicators = thisCarousel.querySelector('.carousel-indicators').querySelectorAll('button');
      cards = thisCarousel
        .querySelector('.carousel-inner')
        .querySelectorAll('.slider-card-item-wrapper');

      indicatorsList = Object.keys(indicators).map((key) => {
        return indicators[key];
      });

      cardsList = Object.keys(cards).map((key) => {
        return cards[key];
      });
      resizeCard();
      setTimeout(() => {
        thisCarousel.classList.remove('card-start');
      }, 500);
      // }, 10);
    }
  }, [swiperRef]);

  const resizeCardHeight = () => {
    for (var i = 0; i < cardsList.length; i++) {
      cardsList[i].style.height = '';
    }
  };

  const getCardHeight = (elemHeight) => {
    for (var i = 0; i < cardsList.length; i++) {
      cardsList[i].style.height = `${elemHeight}px`;
    }
  };
  const resizeCard = () => {
    let thisHeight = 0;
    resizeCardHeight();
    thisCarousel.classList.add('get-height');
    cardsList.map((item, index) => {
      if (thisHeight < item.offsetHeight) {
        thisHeight = item.offsetHeight;
      }
    });

    getCardHeight(thisHeight);

    setTimeout(() => {
      thisCarousel.classList.remove('get-height');
    }, 100);
  };

  const getPreviousElement = (elem) => {
    while ((elem = elem.previousSibling)) {
      elem.classList.add('activated');
    }
  };

  if (props.type === 'hero') {
    image = HeroImage;
    // if (window?.innerWidth <= 543) {
    //   image = HeroImageMobile;
    // }
  }

  const slideFunction = () => {
    indicatorsList?.map((item, index) => {
      if (item.classList.contains('active')) {
        if (index === 0) {
          thisCarousel.classList.add('reset-indicators');
          indicatorsList.map((item) => {
            setTimeout(() => {
              item.classList.remove('activated');
            }, 400);
          });
          setTimeout(() => {
            thisCarousel.classList.remove('reset-indicators');
          }, 400);
        } else {
          getPreviousElement(item);
        }
        if (props.type === 'hero') {
          swiperRef.slideTo(index);
        }
      }
    });
  };
  return (
    <>
      {props.type === 'hero' ? (
        <div className="hero-card-image-content">
          <Swiper ref={cardImage} slidesPerView={1} onSwiper={setSwiperRef}>
            {cardsSlider.map((item, index) => {
              // console.log('cardslider', item);
              return (
                <SwiperSlide className={`hero-img-${index}`} key={index}>
                  {item.fields.img.value.alt === 'Video' ? (
                    <div className="hero-card-video-container">
                      <video controls autoPlay muted loop>
                        <source src={item.fields.img.value.src} type="video/mp4" />
                      </video>
                    </div>
                  ) : (
                    <div
                      className="hero-card-image-current"
                      style={{ backgroundImage: `url(${item.fields.img.value.src})` }}
                    >
                      <img src={item.fields.img.value.src} alt="image" />
                    </div>
                  )}
                  {/* <div
                    className="hero-card-image-current"
                    style={{ backgroundImage: `url(${item.fields.img.value.src})` }}
                  >
                    <img src={item.fields.img.value.src} alt="image" />
                  </div> */}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : null}
      <Carousel
        bsPrefix="carousel"
        className="slide-card-container card-start"
        ref={cardEl}
        onSlide={slideFunction}
        interval={4000}
      >
        {cardsSlider.map((item, index) => {
          return (
            <Carousel.Item key={index} className="slider-card-item">
              <div className="slider-card-item-wrapper">
                <div className="slider-card-item-content">
                  <div className="slider-card-label">
                    <label>{item.fields.label.value}</label>
                  </div>
                  <div className="slider-card-title space-xs bottom">
                    <h2>{item.fields.title.value}</h2>
                  </div>
                  <div className="slider-card-body">
                    <p>{item.fields.body.value}</p>
                  </div>
                </div>
                {/* <div className="slider-card-cta">
                  <SecondaryButton
                    link={item.fields.url.value.href}
                    linkLabel={item.fields.urlLabel.value}
                    linkType={item.fields.url.value.linktype}
                  />
                  {item.fields.secondaryUrl.value.href !== '' ? (
                    <BtnPrimary
                      link={item.fields.secondaryUrl.value.href}
                      label={item.fields.secondaryUrlLabel.value}
                      type={item.fields.secondaryUrlType.value}
                    />
                  ) : (
                    ''
                  )}
                </div> */}
                <div className="slider-card-cta">
                  {item.fields.secondaryUrl.value.href !== '' ? (
                    <BtnPrimary
                      link={item.fields.secondaryUrl.value.href}
                      label={item.fields.secondaryUrlLabel.value}
                      type={item.fields.secondaryUrlType.value}
                    />
                  ) : (
                    ''
                  )}
                  <SecondaryButton
                    link={item.fields.url.value.href}
                    linkLabel={item.fields.urlLabel.value}
                    linkType={item.fields.url.value.linktype}
                  />
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default withSitecoreContext()(CardSlider);
