import React from 'react';
import { withSitecoreContext, Link, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import PlatformNavigationItem from './PlatformNavigationItem';
import logoWhite from '../../assets/images/logo/tinexta-white-logo.svg';

import './platform-menu.scss';
import Icon from '../Icon/index';
import { NavLink } from 'react-router-dom';
import { isServer } from '../../util';

const PlatformMenu = (props) => {
  if (isServer()) {
    return <div className="platform-menu-container"></div>;
  }
  const platformNavigationTab = Object.keys(props.fields.platformNavigationTab).map((key) => {
    return props.fields.platformNavigationTab[key];
  });

  const closePlatformMenu = (e) => {
    e.preventDefault();
    document.body.classList.remove('platform-menu-open');
  };

  return (
    <div className="platform-menu-container">
      <div className="platform-menu-mobile-nav">
        <div className="platform-menu-logo-container">
          <span className="back-icon" onClick={closePlatformMenu}>
            <Icon icon={'DropdownOpen'} />
          </span>
          <div className="platform-menu-logo">
            <NavLink to="/">
              <img className="white-logo" src={logoWhite} alt="logo white" />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="platform-menu-content">
        <nav>
          <div>
            <ul className="platform-nav-content">
              {platformNavigationTab.map((item, index) => {
                // console.log('url', item.fields.internalLink);
                return (
                  <li key={index} className="platform-nav-item">
                    {item.fields.url && item.fields.url.value.href !== '' ? (
                      <>
                        <Link field={item.fields.url} style={{ textDecoration: 'none' }}>
                          <h3>
                            {item.fields.heading.value}{' '}
                            {item.fields.websiteUrl && item.fields.websiteUrl.value.href !== '' ? (
                              <Link field={item.fields.websiteUrl}>
                                <svg
                                  style={{
                                    height: 16,
                                    marginLeft: 16,
                                    width: 16,
                                  }}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    {' '}
                                    <g id="Interface / External_Link">
                                      {' '}
                                      <path
                                        id="Vector"
                                        d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11"
                                        stroke="#AAAAAA"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              </Link>
                            ) : (
                              ''
                            )}
                          </h3>
                        </Link>
                      </>
                    ) : (
                      <h3>{item.fields.heading.value}</h3>
                    )}
                    <PlatformNavigationItem menuItem={item} id={index} />
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default withSitecoreContext()(PlatformMenu);
