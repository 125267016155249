import React from 'react';
import { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
const { isServer } = require('../../util');

import flagIT from '../../assets/images/flag/flag-it.svg';
import flagEN from '../../assets/images/flag/flag-en.svg';

import { Dropdown } from 'react-bootstrap';

import './switch-language.scss';

const SwitchLanguage = (props) => {
  // if (isServer()) {
  //   return <div></div>;
  // }
  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;
  // console.log(currentLanguage);
  const selectOption = [
    { value: 'it-IT', label: 'Italiano', flag: flagIT },
    { value: 'en', label: 'English', flag: flagEN },
  ];

  return (
    <Dropdown className="select-language" drop="up">
      {selectOption.map((item, index) => {
        if (item.value === currentLanguage) {
          return (
            <Dropdown.Toggle key={index} className="select-language-dropdown" id="dropdown-basic">
              <span>
                <img src={item.flag} alt="flag icon" />
              </span>
              {item.label}
            </Dropdown.Toggle>
          );
        }
      })}

      {!isServer() && (
        <Dropdown.Menu>
          {selectOption.map((item, index) => {
            let url = document.location.origin + '/';
            // console.log(url);
            // console.log(item);
            // console.log(window.location.pathname);
            // console.log(props);
            if (item.value === currentLanguage) {
              return (
                <Dropdown.Item className="active disabled" key={index} href={item.value}>
                  <span>
                    <img src={item.flag} alt="flag icon" />
                  </span>
                  {item.label}
                </Dropdown.Item>
              );
            } else {
              const urlToRedirect = window.location.pathname;
              const params = window.location.search;
              const path = window.location.pathname.split('/');
              // console.log('pt', path);
              // console.log(path.indexOf('en'));
              if (path.indexOf('en') > 0 || path.indexOf('it-IT') > 0) {
                path[1] = '';
                if (path[0].indexOf('' > 0)) {
                  path.splice(0, 1);
                }
              }

              return (
                <Dropdown.Item key={index} href={'/' + item.value + path.join('/') + params}>
                  <span>
                    <img src={item.flag} alt="flag icon" />
                  </span>
                  {item.label}
                </Dropdown.Item>
              );
            }
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default withTranslation()(SwitchLanguage);
