import React, { Fragment } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Comunicato from '../ComunicatiStampa/Comunicato';
import FilterTab from '../FilterTab';

import { isServer } from '../../util';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';

const ConnectedDemoQuery = gqlLoader('./query.graphql');
let language = 'it-IT';
let quantity = 10;

const ComunicatiListCustom = (props) => {
  if (isServer()) {
    return <div className="comunicato-container"></div>;
  }
  window.scrollTo(0, 0);
  // console.log('cmnctLst', props);
  let contentHTML = [];
  const comunicati = props.fields;
  // if (props.fields.title && props.fields.title.value !== '') {
  //   contentHTML.push(
  //     <div className="comunicati-titles">
  //       <h2>{props.fields.title.value}</h2>
  //       {props.fields.subtitle ? <p>{props.fields.subtitle.value}</p> : null}
  //     </div>
  //   );
  // }
  // else {
  //   contentHTML.push(<FilterTab items={comunicati} key="filterTab" />);
  // }

  contentHTML.push(
    comunicati.map((item, index) => {
      // console.log('pd', item.fields);
      const item2 = {
        displayName: item.displayName,
        actionLabel: item.fields.actionLabel,
        actionType: item.fields.actionType,
        date: item.fields.date,
        description: item.fields.description,
        labelsTab: item.fields.labelsTab,
        linkLabel: item.fields.linkLabel,
        pageTitle: item.fields.pageTitle,
        paragraph: item.fields.paragraph,
        title: item.fields.title,
        actionUrl: { url: item.fields.actionUrl.value.href },
        // item: {
        //   actionUrl: item.fields.actionUrl,
        // },
        url: {
          value: item.url,
        },
        id: item.id,
        name: item.name,
      };

      // console.log('item2', item2);

      return <Comunicato item={item2} key={index} />;
    })
  );
  // if (comunicatiList.total > quantity) {
  //   let pages = comunicatiList.total / quantity;
  //   if (comunicatiList.total % quantity > 0) {
  //     pages++;
  //   }
  //   // getPagination(pages, contentHTML);
  // }
  // }
  return <Fragment>{contentHTML}</Fragment>;
};

// };

// const getPagination = (pages, contentHTML) => {
//   return contentHTML.push(
//     <Pagination>
//       <Pagination.First key="page-first" />
//       <Pagination.Prev key="page-prev" />
//       {/* <Pagination.Item key={new Date()}>{i}</Pagination.Item>;
//       <Pagination.Item>{index}</Pagination.Item>
//       <Pagination.Item>{1}</Pagination.Item>
//       <Pagination.Item>{10}</Pagination.Item>
//       <Pagination.Item>{11}</Pagination.Item>
//       <Pagination.Item active>{12}</Pagination.Item>
//       <Pagination.Item>{13}</Pagination.Item>
//       <Pagination.Item disabled>{14}</Pagination.Item>
//       <Pagination.Ellipsis />
//       <Pagination.Item>{20}</Pagination.Item> */}
//       <Pagination.Next key="page-next" />
//       <Pagination.Last key="page-last" />
//     </Pagination>
//   );
// };

export default GraphQLData(ConnectedDemoQuery, {
  name: 'connectedQuery',
  options: {
    variables: {
      quantity: quantity,
      language: language,
    },
  },
})(ComunicatiListCustom);
