import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';

import BtnPrimary from '../BtnPrimary/index';
import SecondaryButton from '../SecondaryButton';
// import heroVideo from '../../assets/video/example.mp4';

import './homepage-slider.scss';
import { isServer } from '../../util';

const HomepageSlider = (props) => {
  if (isServer()) {
    return <div className="homepage-slider-container"></div>;
  }

  const cardEl = useRef(null);
  const cardImage = useRef(null);

  // const [videoType, setVideoType] = useState('video/mp4');
  let videoType = 'video/mp4';

  const [swiperRef, setSwiperRef] = useState(0);
  const swiperDelay = 5000;
  let indicators, thisSlider, itemsLength, itemActive, slidePosition, indicatorsContainer;

  const homepageSliderItem = Object.keys(props.fields.homepageSliderItem).map((key) => {
    return props.fields.homepageSliderItem[key];
  });

  useLayoutEffect(() => {
    if (swiperRef) {
      thisSlider = cardEl.current;
      indicatorsContainer = thisSlider.querySelector('.swiper-pagination');
      indicators = thisSlider.querySelectorAll('.swiper-pagination-bullet');
      itemsLength = indicators.length;
      // console.log(indicators);
      indicators.forEach((item, i) => {
        item.setAttribute('slideIndex', i);
      });
      itemActive = document.querySelector('.swiper-pagination-bullet-active');
      if (itemsLength < 10) {
        slidePosition = `01 / 0${itemsLength}`;
      } else {
        slidePosition = `01 / ${itemsLength}`;
      }

      if (document.querySelector('.numbers').classList.contains('empty')) {
        document.querySelector('.numbers').classList.remove('empty');
        document.querySelector('.numbers').append(`${slidePosition}`);
      }
      itemActive.classList.add('activated');
    }
  }, [swiperRef]);

  const changeSlide = (index) => {
    itemActive = document.querySelector('.swiper-pagination-bullet-active');
    let activeIndex = parseInt(itemActive.getAttribute('slideIndex'));
    activeIndex = activeIndex + 1;
    if (activeIndex < 10) {
      slidePosition = `0${activeIndex} / 0${itemsLength}`;
    } else {
      slidePosition = `${activeIndex} / ${itemsLength}`;
    }
    document.querySelector('.numbers').innerHTML = `${slidePosition}`;

    // if (itemActive.classList.contains('activated')) {
    //   itemActive.classList.remove('activated');
    //   itemActive.nextElementSibling.classList.remove('activated');
    // } else {
    //   itemActive.classList.add('activated');
    // }
  };

  return (
    <div className="homepage-slider-container">
      <div className="numbers empty"></div>
      <Swiper
        ref={cardEl}
        slidesPerView={1}
        onSwiper={setSwiperRef}
        onSlideChange={changeSlide}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: swiperDelay,
          disableOnInteraction: false,
          loop: true,
        }}
        navigation={true}
        pagination={{
          clickable: false,
        }}
        effect="fade"
      >
        {homepageSliderItem.map((item, index) => {
          let image = item.fields.img.value.src;

          let regex = /\.\w{3,4}($|\?)/gim;
          let path = image.match(regex);
          // console.log('homepageslider', path);

          if (path && path[0].includes('webm')) {
            videoType = 'video/webm';
          } else {
            videoType = 'video/mp4';
          }

          return (
            <SwiperSlide className={`hero-img-${index}`} key={index}>
              {path[0].includes('webm') || path[0].includes('mp4') ? (
                <div className="homepage-video-container">
                  <video autoPlay playsInline muted loop poster={item.fields.imgMobile.value.src}>
                    <source src={item.fields.img.value.src} type={videoType} />
                  </video>
                </div>
              ) : (
                <div className="homepage-slider-image" style={{ backgroundImage: `url(${image})` }}>
                  <img src={image} alt="image" />
                </div>
              )}
              {/* <div className="homepage-slider-image" style={{ backgroundImage: `url(${image})` }}>
                <img src={image} alt="image" />
              </div> */}
              <div className="homepage-slider-content">
                <div className="homepage-slider-category">
                  <label>{item.fields.label.value}</label>
                </div>
                <h1>{item.fields.heading.value}</h1>
                <div className="cta-container">
                  {item.fields.linkUrl.value !== '' ? (
                    <BtnPrimary
                      link={item.fields.linkUrl.value}
                      label={item.fields.linkLabel.value}
                      icon={'ArrowRight'}
                    />
                  ) : (
                    ''
                  )}
                  {item.fields.secondLinkUrl.value.href !== '' ? (
                    <SecondaryButton
                      link={item.fields.secondLinkUrl.value.href}
                      linkLabel={item.fields.secondLinkLabel.value}
                      icon={'Download'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default withSitecoreContext()(HomepageSlider);
