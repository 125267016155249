import React, { Fragment } from 'react';
import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';

import './event-detail-content.scss';
import Icon from '../Icon/index';
import { Link } from 'react-router-dom';

import { Redirect } from 'react-router-dom';
import { isServer } from '../../util';
import BtnPrimary from '../BtnPrimary/index';
import ComunicatoGallery from '../ComunicatoGallery';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/esm/DropdownButton';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { ICalendar } from 'datebook';
import { useTranslation } from 'react-i18next';

const EventiDetailContent = (props) => {
  if (isServer()) {
    return <></>;
  }

  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;

  const toMonthName = (date, monthNumber) => {
    date.setMonth(monthNumber);
    return date.toLocaleString(currentLanguage, {
      month: 'long',
    });
  };

  let componentProps;
  if (props && props.sitecoreContext && props.sitecoreContext.route) {
    componentProps = props.sitecoreContext.route.fields;
  } else if (props.fields) {
    componentProps = props.fields;
  } else {
    return <Redirect to="/" />;
  }

  // const additionalLink = componentProps.additionalLink; // props.item.additionalLink;
  // const socialLink = componentProps.socialLink; // props.item.socialLink;

  let socialLinkIcon, itemClass, nextCta;

  // console.log('EventiDetailContent', props);
  const isEvent = props.sitecoreContext.route.templateName
    ? props.sitecoreContext.route.templateName.toLowerCase().includes('event')
    : true;

  const now = new Date(Date.now());
  let firstDate = componentProps.firstDate ? new Date(componentProps.firstDate.value) : undefined;
  let secondDate = componentProps.secondDate
    ? new Date(componentProps.secondDate.value)
    : undefined;

  let firstDateYear, firstDateMonth, firstDateDay, firstDateHour;
  let secondDateYear, secondDateMonth, secondDateDay, secondDateHour;

  if (firstDate) {
    firstDateYear = firstDate.getFullYear();
    firstDateMonth = toMonthName(firstDate, firstDate.getMonth());
    firstDateDay = firstDate.getDate();
    firstDateHour =
      String(firstDate.getHours()).padStart(2, '0') +
      ':' +
      String(firstDate.getMinutes()).padStart(2, '0');
    firstDateMonth = toMonthName(firstDate, firstDate.getMonth());
  }
  if (secondDate) {
    secondDateYear = secondDate.getFullYear();
    secondDateMonth = toMonthName(secondDate, secondDate.getMonth());
    secondDateDay = secondDate.getDate();
    secondDateHour =
      String(secondDate.getHours()).padStart(2, '0') +
      ':' +
      String(secondDate.getMinutes()).padStart(2, '0');
    secondDateMonth = toMonthName(secondDate, secondDate.getMonth());
  }

  if (props.next) {
    itemClass = 'event-detail-item next';
    nextCta = (
      <Link className="next-event" to="/next">
        Vai al prossimo evento
      </Link>
    );
  } else {
    itemClass = 'event-detail-item';
  }

  let icalendarStart, icalendarEnd;
  if (firstDate && firstDate > now) {
    icalendarStart = new ICalendar({
      title: componentProps.title.value,
      location: componentProps.firstLocation.value,
      description: componentProps.subtitles.value,
      start: firstDate,
      end: firstDate,
    });
  }
  if (secondDate && secondDate > now) {
    icalendarEnd = new ICalendar({
      title: componentProps.title.value,
      location: componentProps.secondLocation.value,
      description: componentProps.subtitles.value,
      start: secondDate,
      end: secondDate,
    });
  }

  // console.log(componentProps);

  // title: componentProps.title,
  //   location: componentProps.firstLocation,
  //   description: componentProps.subtitles,
  //   start: firstDate,
  //   recurrence: {},
  // console.log('SECOND DATE', secondDateYear);

  return (
    <div className="container-fluid comunicati-detail-container">
      <div className="breadcrumb-container">
        <ul>
          <li>{currentLanguage === 'it-IT' ? 'Media' : 'Media'} /</li>
          <li>{currentLanguage === 'it-IT' ? 'Eventi' : 'Events'} /</li>
        </ul>
      </div>
      <div className="row">
        <div className="m-auto col-lg-10 col-xl-8">
          <div className={itemClass}>
            <div className="event-detail-heading">
              {/* {nextCta} */}
              <div className="event-detail-title-container">
                <div className="event-detail-title">
                  <h1>
                    <Text field={componentProps.title} />
                  </h1>
                  {/* <p className="event-detail-type">{isEvent ? 'TBD: Eventi' : 'News'}</p> */}
                </div>
                <div className="event-detail-subtitle">
                  <RichText field={componentProps.subtitles} />
                </div>
              </div>
              <div className="event-detail-date-container">
                {/* {firstDate.toString() === secondDate.toString() ? (
              <div className="event-detail-date-item">
                <div className="event-detail-date">
                  <span>{secondDateDay + ' ' + secondDateMonth + ' ' + secondDateYear}</span>
                </div>
                <div className="event-detail-time">
                  <Icon icon={'Clock'} />
                  <p>{secondDateHour}</p>
                </div>
                <div className="event-detail-place">
                  <Icon icon={'Pin'} />
                  <p>{componentProps.secondLocation}</p>
                </div>
              </div>
            ) : ( */}
                <>
                  {firstDate && firstDateYear !== 1 && (
                    <div className="event-detail-date-item">
                      <div className="event-detail-date">
                        {currentLanguage === 'it-IT' && (
                          <span>
                            {firstDateDay +
                              ' ' +
                              firstDateMonth.toLocaleString() +
                              ' ' +
                              firstDateYear}
                          </span>
                        )}
                        {currentLanguage === 'en' && (
                          <span>
                            {firstDateMonth.toLocaleString() +
                              ' ' +
                              firstDateDay +
                              ', ' +
                              firstDateYear}
                          </span>
                        )}
                      </div>
                      {firstDateHour && firstDateHour !== '00:00' && (
                        <div className="event-detail-time">
                          <Icon icon={'Clock'} />
                          <p>{firstDateHour}</p>
                        </div>
                      )}
                      {componentProps.firstLocation && componentProps.firstLocation.value !== '' && (
                        <div className="event-detail-place">
                          <Icon icon={'Pin'} />
                          <p>
                            <Text field={componentProps.firstLocation} />
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {secondDate && secondDateYear !== 1 && (
                    <div className="event-detail-date-item">
                      <div className="event-detail-date">
                        {currentLanguage === 'it-IT' && (
                          <span>
                            {secondDateDay +
                              ' ' +
                              secondDateMonth.toLocaleString() +
                              ' ' +
                              secondDateYear}
                          </span>
                        )}
                        {currentLanguage === 'en' && (
                          <span>
                            {secondDateMonth.toLocaleString() +
                              ' ' +
                              secondDateDay +
                              ', ' +
                              secondDateYear}
                          </span>
                        )}
                      </div>
                      {secondDateHour && secondDateHour !== '00:00' && (
                        <div className="event-detail-time">
                          <Icon icon={'Clock'} />
                          <p>{secondDateHour}</p>
                        </div>
                      )}
                      {componentProps.secondLocation && componentProps.secondLocation.value !== '' && (
                        <div className="event-detail-place">
                          <Icon icon={'Pin'} />
                          <p>
                            <Text field={componentProps.secondLocation} />
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </>
                {/* )} */}
              </div>
              <div className="event-detail-action-container">
                {componentProps.addToCalendar.value && (
                  <div className="event-detail-action-item">
                    <BtnPrimary
                      link="/"
                      label={
                        currentLanguage === 'it-IT' ? 'Aggiungi al calendario' : 'Add to calendar'
                      }
                      type="add"
                      onClick={function () {
                        event.preventDefault();
                        icalendarStart &&
                          icalendarStart.download(
                            componentProps.title.value + '_' + firstDate + '.ics'
                          );
                        icalendarEnd &&
                          icalendarEnd.download(
                            componentProps.title.value + '_' + secondDate + '.ics'
                          );
                      }}
                    />
                  </div>
                )}
                {componentProps.link1 && componentProps.link1.value.href !== '' && (
                  <div className="event-detail-action-item">
                    <BtnPrimary
                      link={componentProps.link1.value.href}
                      label={componentProps.linkLabel1.value}
                      type={componentProps.linkType1.value}
                    />
                  </div>
                )}
                {componentProps.link2 && componentProps.link2.value.href !== '' && (
                  <div className="event-detail-action-item">
                    <BtnPrimary
                      link={componentProps.link2.value.href}
                      label={componentProps.linkLabel2.value}
                      type={componentProps.linkType2.value}
                    />
                  </div>
                )}
                {componentProps.link3 && componentProps.link3.value.href !== '' && (
                  <div className="event-detail-action-item">
                    <BtnPrimary
                      link={componentProps.link3.value.href}
                      label={componentProps.linkLabel3.value}
                      type={componentProps.linkType3.value}
                    />
                  </div>
                )}

                <div className="social-cta">
                  <DropdownButton
                    as={ButtonGroup}
                    key={'end'}
                    id="social-dropdown-email"
                    drop={'end'}
                    title={<Icon icon={'Share'} />}
                  >
                    <Dropdown.Item
                      eventKey="0"
                      href={'mailto:?body=' + window.location.href}
                      className="social-link"
                    >
                      <Icon icon={'Mail'} />
                      Email
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>

              {/* {props.sitecoreContext.route.fields.additionalLink || props.item.additionalLink ? (
            <div className="event-detail-action-container">
              {additionalLink.map((item, index) => {
                return (
                  <div className="event-detail-action-item" key={index}>
                    <BtnPrimary link={item.linkUrl} label={item.linkLabel} type={item.linkType} />
                  </div>
                );
              })}
              {props.sitecoreContext.route.fields.socialLink || props.item.socialLink ? (
                <div className="social-cta">
                  <DropdownButton
                    as={ButtonGroup}
                    key={'end'}
                    id="social-dropdown"
                    drop={'end'}
                    title={<Icon icon={'Share'} />}
                  >
                    {socialLink.map((item, index) => {
                      switch (item.socialName) {
                        case 'facebook':
                          socialLinkIcon = <Icon icon={'SocialFacebook'} />;
                          break;
                        case 'linkedin':
                          socialLinkIcon = <Icon icon={'SocialLinkedin'} />;
                          break;
                        case 'twitter':
                          socialLinkIcon = <Icon icon={'SocialTwitter'} />;
                          break;
                        case 'email':
                          socialLinkIcon = <Icon icon={'Mail'} />;
                          break;
                        default:
                          socialLinkIcon = null;
                      }
                      return (
                        <Dropdown.Item
                          key={index}
                          eventKey={index}
                          href={item.socialLink}
                          className="social-link"
                        >
                          {socialLinkIcon}
                          {item.socialName}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </div>
              ) : null}
            </div>
          ) : null} */}
              {componentProps.image.value.src && (
                <div className="event-detail-media">
                  <div className="event-detail-media-item image">
                    <img
                      src={componentProps.image.value.src}
                      alt={componentProps.image.value.alt}
                    />
                  </div>
                </div>
              )}
              {componentProps.videoUrl && componentProps.videoUrl.value.href !== '' && (
                <div>
                  <div className="event-detail-media-item video">
                    <iframe
                      src={componentProps.videoUrl.value.href}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="event-detail-content">
              <RichText field={componentProps.paragraph} />
            </div>
            {componentProps.mediaGallery !== undefined &&
              componentProps.mediaGallery.imageList !== '' && (
                <ComunicatoGallery imageList={componentProps.mediaGallery} />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(EventiDetailContent);
