import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import GrdientText from '../GradientText/index';
import BtnPrimary from '../BtnPrimary/index';

import './forma-al-futuro.scss';

const FormaAlFuturoComponent = (props) => {
  const futureTab = Object.keys(props.fields.futureTab).map((key) => {
    return props.fields.futureTab[key];
  });
  return (
    <div className="forma-al-futuro-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
            <div className="forma-al-futuro-title">
              <GrdientText text={props.fields.heading.value} />
            </div>
          </div>
        </div>
        <div className="row">
          {futureTab.map((item, index) => {
            // console.log(item);
            return (
              <div className="col-md-4" key={index}>
                <div className="forma-al-futuro-item">
                  <h3>
                    <Text field={item.fields.title} />
                  </h3>
                  <p>
                    <Text field={item.fields.body} />
                  </p>
                  <BtnPrimary
                    label={item.fields.linkLabel.value}
                    link={item.fields.linkUrl.value}
                  />
                  {/* <h3>Scopri chi siamo</h3>
                  <p>
                    Dal 2009 portiamo nel mondo digitale la fiducia e la prossimità che sostengono
                    il tessuto imprenditoriale del nostro Paese. Dal 2017 la nostra presenza è
                    globale.
                  </p>
                  <BtnPrimary label={'Scopri di più'} link={'/'} /> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FormaAlFuturoComponent;
