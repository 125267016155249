import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/lens.svg';

import Icon from '../Icon/index';

import './search-bar.scss';

const hideBar = (e) => {
  e.preventDefault();
  let hideBar = document
    .querySelector('.right-nav-container')
    .querySelector('.search-bar-container');
  hideBar.classList.remove('show');
};

const showBar = (e) => {
  e.preventDefault();
  document.body.classList.remove('platform-menu-open');
  if (window.innerWidth > 543) {
    let showBar = document
      .querySelector('.right-nav-container')
      .querySelector('.search-bar-container');
    showBar.classList.add('show');
  }
};

const SearchBar = () => {
  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;

  const handleChange = (event) => {
    event.preventDefault();
    let inputButton = document
      .querySelector('.right-nav-container')
      .querySelector('.search-button');
    setMessage(event.target.value);
    if (event.target.value.trim().length < 3) {
      inputButton.classList.remove('show');
    } else {
      inputButton.classList.add('show');
    }
  };

  const [message, setMessage] = useState('');
  const handleSubmit = (event) => {
    if (message.trim().length < 3) {
      event.preventDefault();
    }
  };

  return (
    <div className="search-bar-container">
      <div className="search-bar-content">
        <form action="/search" onSubmit={handleSubmit}>
          <input
            type="text"
            name="search"
            onChange={handleChange}
            autoComplete="off"
            placeholder={currentLanguage === 'it-IT' ? 'Cerca in Tinexta' : 'Search in Tinexta'}
            value={message}
          />
          <button type="submit" className="search-button">
            <Icon icon={'ArrowExternal'} />
          </button>
        </form>
      </div>
      <div className="search-bar-controller">
        <div onClick={(e) => showBar(e)} className="search-icon">
          <SearchIcon />
        </div>
        <div onClick={(e) => hideBar(e)} className="close-icon">
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SearchBar);
