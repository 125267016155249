import React from 'react';
import { useLocation } from 'react-router-dom';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { withTranslation, useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import 'bootstrap/dist/css/bootstrap.css';
import './assets/app.scss';
import logo from './assets/sc_logo.svg';
import SmoothScroll from './SmoothScroll';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const removeLanguage = (fullUrl) => {
  // console.log('fullurl', fullUrl);
  if (fullUrl.startsWith('/en/')) {
    return fullUrl.replace('/en/', '/');
  }
  if (fullUrl.startsWith('/en')) {
    return fullUrl.replace('/en', '/');
  }
  if (fullUrl.startsWith('/it-IT/')) {
    return fullUrl.replace('/it-IT/', '/');
  }
  if (fullUrl.startsWith('/it-IT')) {
    return fullUrl.replace('/it-IT', '/');
  }
  return fullUrl;
};

const Layout = ({ route }) => (
  <React.Fragment>
    {/* <SmoothScroll /> */}
    {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
    <Helmet>
      <title>
        {(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Tinexta.com'}
      </title>
      <meta
        name="description"
        content={
          (route.fields && route.fields.metaDescription && route.fields.metaDescription.value) ||
          (route.fields && route.fields.pageTitle && route.fields.pageTitle.value)
        }
      />
      <link
        rel="canonical"
        href={'https://tinexta.com/' + i18n.language + removeLanguage(useLocation().pathname)}
      />
      <meta
        property="og:title"
        content={
          (route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Tinexta.com'
        }
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={
          (route.fields && route.fields.metaDescription && route.fields.metaDescription.value) ||
          (route.fields && route.fields.pageTitle && route.fields.pageTitle.value)
        }
      />
      <meta property="og:url" content={'https://tinexta.com' + useLocation().pathname} />
      <meta
        property="og:image"
        content={
          (route.fields &&
            route.fields.metaImage &&
            route.fields.metaImage.value &&
            route.fields.metaImage.value.src) ||
          ''
        }
      />
      <meta
        property="og:image:width"
        content={
          (route.fields &&
            route.fields.metaImage &&
            route.fields.metaImage.value &&
            route.fields.metaImage.value.width) ||
          ''
        }
      />
      <meta
        property="og:image:height"
        content={
          (route.fields &&
            route.fields.metaImage &&
            route.fields.metaImage.value &&
            route.fields.metaImage.value.height) ||
          ''
        }
      />

      <meta
        property="twitter:title"
        content={
          (route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Tinexta.com'
        }
      />
      <meta
        property="twitter:description"
        content={
          (route.fields && route.fields.metaDescription && route.fields.metaDescription.value) ||
          (route.fields && route.fields.pageTitle && route.fields.pageTitle.value)
        }
      />
      <meta
        property="twitter:image"
        content={
          (route.fields &&
            route.fields.metaImage &&
            route.fields.metaImage.value &&
            route.fields.metaImage.value.src) ||
          ''
        }
      />
    </Helmet>

    {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
    {/* <VisitorIdentification /> */}

    {/* root placeholder for the app, which we add components to using route data */}
    <Placeholder name="jss-navigation" rendering={route} />

    <div className="main-content">
      <Placeholder name="jss-main" rendering={route} />
    </div>

    {/* <div className="space-xl"></div> */}
    <Placeholder name="jss-footer" rendering={route} />
  </React.Fragment>
);

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
