import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withSitecoreContext, Text, Placeholder, Image } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
const { isServer } = require('../../util');
import BtnPrimary from '../BtnPrimary/index';
gsap.registerPlugin(ScrollTrigger);

import './identity-slider.scss';

const IdentitySlider = (props) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  let killTimeline, identitySliderAnimation;
  let disabelTrigger, enableTrigger, anchorId, loadScrollTrigger, panelsLength, numbers;

  useEffect(() => {
    return history.listen((location) => {
      // console.log('location');
      // console.log(location);
      if (history.action === 'PUSH') {
        // console.log('push');
        setLocationKeys([location.key]);
        if (killTimeline !== undefined) {
          killTimeline();
        }
        if (enableTrigger !== undefined) {
          // console.log('enableTrigger');
          enableTrigger();
        }
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // console.log('forward');
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // console.log('back');
        }
      }
    });
  }, [locationKeys]);

  if (isServer()) {
    // console.log('isServer');
    return <div className="identity-slider-container"></div>;
  }

  const itemRef = useRef(null);

  const identityItems = Object.keys(props.fields.IdentitySliderItems).map((key) => {
    return props.fields.IdentitySliderItems[key];
  });
  // console.log(props);

  identitySliderAnimation = () => {
    const elContainer = itemRef.current.querySelector('.identity-slider-content');
    let panels = gsap.utils.toArray('.identity-slider-item');
    panelsLength = panels.length;
    let endValue = 100 * (panels.length - 1);
    panels.forEach((panel, i) => {
      panel.querySelector('.slide-number').append(`${i + 1} / ${panelsLength}`);
      panel.classList.add(`item-${i}`);
      itemRef.current.style.height = `${(panels.length + 1) * 100}vh`;
      panel.style.transform = `translateY(${i * 100}%)`;
      loadScrollTrigger = () => {
        setTimeout(() => {
          ScrollTrigger.create({
            start: 'top top',
            end: '100%',
            markers: false,
            pin: elContainer,
            pinSpacing: false,
            trigger: panel,
            onEnter: (self) => {
              panel.style.transform = 'translateY(0)';
              panel.classList.add('item-enter');
              panel.classList.remove('item-leave-back');
              panel.classList.remove('item-leave');
            },
            onLeave: (self) => {
              panel.classList.remove('item-leave-back');
              if (!panel.classList.contains(`item-${panelsLength - 1}`)) {
                panel.classList.remove('item-enter');
                panel.classList.add('item-leave');
              }
            },
            onEnterBack: (self) => {
              panel.classList.remove('item-leave-back');
              panel.classList.remove('item-leave');
              panel.classList.add('item-enter');
            },
            onLeaveBack: (self) => {
              panel.classList.remove('item-leave');
              if (!panel.classList.contains('item-0')) {
                panel.classList.remove('item-enter');
                panel.classList.add('item-leave-back');
                setTimeout(() => {
                  if (panel.classList.contains('item-0')) {
                    panel.style.transform = `translateY(${i * 100}%)`;
                  }
                }, 1000);
              }
            },
          });
        }, 600);
      };
      enableTrigger = () => {
        ScrollTrigger.enable();
        // heroSliderAnimation();
        loadScrollTrigger();
      };
      enableTrigger();

      disabelTrigger = () => {
        ScrollTrigger.disable();
        panel.classList = 'identity-slider-item ';
        document.querySelector('.identity-slider-container').style.height = '100vh';
        panel.style.transform = 'translateY(0)';
      };
    });
  };

  useEffect(() => {
    identitySliderAnimation();

    return () => {
      // console.log('change');
      disabelTrigger();
    };
  }, []);

  return (
    <div className="identity-slider-container" ref={itemRef}>
      <div className="identity-slider-content">
        {identityItems.map((item, index) => {
          return (
            <div className="identity-slider-item" key={index}>
              <div
                className="identity-slider-item-image"
                style={{ backgroundImage: `url(${item.fields.img.value.src})` }}
              >
                <img src={item.fields.img.value.src} alt={item.fields.img.value.alt} />
              </div>
              <div className="identity-slider-item-body">
                <div className="container-fluid">
                  <div className="identity-slider-item-body-content">
                    <div className="slide-number"></div>
                    <h3>
                      <Text field={item.fields.heading} />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withSitecoreContext()(IdentitySlider);
