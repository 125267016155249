import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
const { isServer } = require('../../util');

import './corporate-academy.scss';

const CorporateAcademyHero = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div className="corporate-academy-hero-container"></div>;
  }
  const itemRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to('.corporate-academy-hero-body', { yPercent: 350 });
      let corporateStepTl = gsap.timeline({
        scrollTrigger: {
          trigger: '.corporate-academy-hero-main-content',
          scrub: true,
          start: 'top top',
          end: '+=300%',
          pin: '.corporate-academy-hero-main-content',
          pinSpacer: false,
          onEnter: (self) => {
            if (self.progress > 0.1) {
              document
                .querySelector('.corporate-academy-hero-main-content')
                .classList.add('show-gradient');
            }
          },
          onUpdate: (self) => {
            if (self.progress < 0.1) {
              if (
                document
                  .querySelector('.corporate-academy-hero-main-content')
                  .classList.contains('show-gradient')
              ) {
                document
                  .querySelector('.corporate-academy-hero-main-content')
                  .classList.remove('show-gradient');
              }
            } else {
              if (
                !document
                  .querySelector('.corporate-academy-hero-main-content')
                  .classList.contains('show-gradient')
              ) {
                document
                  .querySelector('.corporate-academy-hero-main-content')
                  .classList.add('show-gradient');
              }
            }
          },
          onLeaveBack: (self) => {
            document
              .querySelector('.corporate-academy-hero-main-content')
              .classList.remove('show-gradient');
            // self.disable();
          },
        },
      });
      corporateStepTl.fromTo('.corporate-academy-hero-body ', { yPercent: 450 }, { yPercent: -50 });
    }, itemRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className="corporate-academy-hero-container" ref={itemRef}>
      <div
        className="corporate-academy-hero-main-content"
        style={{ backgroundImage: `url(${props.fields.img.value.src})` }}
      >
        <div className="corporate-academy-hero-content">
          <div className="corporate-academy-hero-heading">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                  <h2>{props.fields.heading.value}</h2>
                  <p>{props.fields.subHeading.value}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="corporate-academy-hero-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                  <h4>{props.fields.bodyTitle.value}</h4>
                  <p>{props.fields.body.value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateAcademyHero;
