import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Icon from '../Icon/index';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';

import CarouselImage from '../../assets/images/temp/carousel-image.jpg';

import { Pagination, Navigation } from 'swiper';
import SecondaryButton from '../SecondaryButton/index';

import './comunicato-gallery.scss';

import { isServer } from '../../util';

const ComunicatoGallery = (props) => {
  if (isServer()) {
    return <div className="image-carousel-container"></div>;
  }

  // console.log('carousel', props);
  const slider = useRef(null);
  const sliderVideo = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);

  const sliderItems = Object.keys(props.imageList).map((key) => {
    return props.imageList[key];
  });

  //   useEffect(() => {
  //     if (swiperRef) {
  //       swiperRef.slideTo(1);
  //       setTimeout(() => {
  //         let loadHeight = slider.current.offsetHeight;
  //         slider.current.querySelector('.swiper-wrapper').style.minHeight = `${loadHeight}px`;
  //       }, 600);
  //     }
  //   });

  const playVideo = (e) => {
    e.preventDefault();
    const video = sliderVideo.current;
    const playIcon = sliderVideo.current.parentElement.querySelector('.play-video');
    video.controls = 'controls';
    video.play();
    playIcon.classList.add('play');
    video.addEventListener('ended', () => {
      video.controls = '';
      playIcon.classList.remove('play');
    });
    video.addEventListener('pause', () => {
      video.controls = '';
      playIcon.classList.remove('play');
    });
  };

  return (
    <div className="space-m">
      {/* OLD <div className="comunicato-carousel-container"> */}
      {/* <div className="image-carousel-heading">
        <h2>Media Gallery</h2>
      </div> */}

      <div className="">
        {/* OLD <div className="comunicato-carousel-content"> */}
        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={'auto'}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          ref={slider}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          autoHeight={true}
          observer={true}
          observeParents={true}
        >
          {sliderItems.map((item, index) => {
            // if (item.fields.video !== undefined) {
            //   return (
            //     <SwiperSlide key={index}>
            //       <div className="swiper-slide-content">
            //         <div className="slide-video">
            //           <div className="play-video" onClick={playVideo}>
            //             <Icon icon={'Play'} />
            //           </div>
            //           <video className="hero-video" ref={sliderVideo}>
            //             <source src="/" type="video/mp4" />
            //           </video>
            //         </div>
            //         <div className="slide-body">
            //           <label>
            //             <Text field={item.fields.label} />
            //           </label>
            //           <h3>
            //             <Text field={item.fields.title} />
            //           </h3>
            //           <div className="slide-body-description">
            //             <RichText field={item.fields.description} />
            //           </div>
            //         </div>
            //       </div>
            //     </SwiperSlide>
            //   );
            // } else if (item.fields.image !== undefined) {
            //   // console.log('aaaaa', item);
            return (
              <SwiperSlide key={index}>
                {/* <div className="swiper-slide-content"> */}
                <div className="slide-image">
                  {/* <a href={item.fields.link.value.href}> */}
                  <img src={item.url} alt={item.url} />
                  {/* </a> */}
                </div>
                {/* <div className="slide-body">
                      <label>
                        <Text field={item.fields.label} />
                      </label>
                      <h3>
                        <Text field={item.fields.title} />
                      </h3>
                      <div className="slide-body-description">
                        <RichText field={item.fields.description} />
                      </div>
                    </div> */}
                {/* </div> */}
              </SwiperSlide>
            );
            // }
          })}
        </Swiper>
      </div>
      {/* {props.fields.linkUrl && props.fields.linkUrl.value && (
        <>
          <div className="space-m"></div>
          <div className="row justify-content-center">
            <div className="col-md-2">
              <div style={{ textAlign: 'center' }}>
                {props.fields.linkLabel.value !== '' ? (
                  <SecondaryButton
                    link={props.fields.linkUrl.value.href}
                    linkLabel={props.fields.linkLabel.value}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </>
      )} */}
    </div>
  );
};

export default withSitecoreContext()(ComunicatoGallery);
