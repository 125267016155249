import React, { useEffect, useState } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import BtnPrimary from '../BtnPrimary/index';
import GradientText from '../GradientText/index';
import BusinessUnit from '../BusinessUnit/index';

import './homepage-component.scss';

// import HeroImage from '../../assets/images/temp/hero-image.jpg';
import Group01 from '../../assets/images/bg-images/group-01.png';
import Group02 from '../../assets/images/bg-images/group-02.png';
import Group03 from '../../assets/images/bg-images/group-03.png';
import Group01Mobile from '../../assets/images/bg-images/group-01-mobile.png';
import Group02Mobile from '../../assets/images/bg-images/group-02-mobile.png';
import Group03Mobile from '../../assets/images/bg-images/group-03-mobile.png';

import Line01 from '../../assets/images/lines/hp-component-line-01.svg';
import Line02 from '../../assets/images/lines/hp-component-line-02.svg';
import Line03 from '../../assets/images/lines/hp-component-line-03.svg';
import Line04 from '../../assets/images/lines/hp-component-line-04.svg';
import Line05 from '../../assets/images/lines/hp-component-line-05.svg';
import Line06 from '../../assets/images/lines/hp-component-line-06.svg';
import Line01mob from '../../assets/images/lines/hp-component-line-01-mob.svg';
import Line02mob from '../../assets/images/lines/hp-component-line-02-mob.svg';
import Line03mob from '../../assets/images/lines/hp-component-line-03-mob.svg';
import Line04mob from '../../assets/images/lines/hp-component-line-04-mob.svg';
import Line05mob from '../../assets/images/lines/hp-component-line-05-mob.svg';
import Line06mob from '../../assets/images/lines/hp-component-line-06-mob.svg';

import CardGradient from '../CardGradient';
import { isServer } from '../../util';

const VisionMissionScroll = (props) => {
  // const groupSteps = Object.keys(props.fields.groupSection).map((key) => {
  //   return props.fields.groupSection[key];
  // });
  // console.log('vision', props);
  // console.log('sdasdasda', props.fields.first_section_text);

  if (isServer()) {
    return <div className="homepage-container"></div>;
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const groupSteps = [
    {
      text: props.fields.first_section_text,
      label: props.fields.first_section_label,
    },
    {
      text: props.fields.second_section_text,
      label: props.fields.second_section_label,
    },
    {
      text: props.fields.third_section_text,
      label: props.fields.third_section_label,
    },
    {
      text: props.fields.fourth_section_text,
      label: props.fields.fourth_section_label,
    },
    {
      text: props.fields.fifth_section_text,
      label: props.fields.fifth_section_label,
    },
    {
      text: props.fields.sixth_section_text,
      label: props.fields.sixth_section_label,
    },
  ];
  let groupStepsItems;

  for (var i = 0; i <= groupSteps.length; i++) {
    groupStepsItems = i;
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (window.innerWidth > 991) {
        let stepsContainer = document.querySelector('.group-container');
        let stepsWidth = 100 * groupStepsItems;
        document.querySelector('.group-container').style.width = `${stepsWidth}vw`;

        gsap.to('.group-container', {
          xPercent: -100,
          x: () => innerWidth,
          ease: 'none',
          scrollTrigger: {
            trigger: '.group-container',
            start: 'top top',
            end: () => innerWidth * groupStepsItems,
            scrub: true,
            pin: true,
            invalidateOnRefresh: true,
            anticipatePin: 1,
          },
        });
      }
    });
  });

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  waitForElm('.group-item').then((elm) => {
    if (windowWidth < 991) {
      checkGroupItemHeight();
    }
  });

  const checkGroupItemHeight = () => {
    const allGroupItem = document.querySelectorAll('.group-item');
    allGroupItem.forEach((groupItem, index) => {
      const elemeHeight = groupItem.offsetHeight;
      // console.log(elemeHeight);
      if (index + 1 === 2) {
        document.documentElement.style.setProperty('--block03Space', `${elemeHeight}px`);
      }
      if (index + 1 === 4) {
        document.documentElement.style.setProperty('--block05Space', `${elemeHeight}px`);
      }
    });
  };

  return (
    <div className="homepage-container">
      <div className="group-section">
        <div className="group-container">
          {groupSteps.map((item, index) => {
            let groupImage, linesImage;
            if (index === 0) {
              if (window.innerWidth > 991) {
                groupImage = Group01;
                linesImage = Line01;
              } else {
                linesImage = Line01mob;
                groupImage = Group01Mobile;
              }
            } else if (index === 1) {
              if (window.innerWidth > 991) {
                linesImage = Line02;
              } else {
                linesImage = Line02mob;
              }
            } else if (index === 2) {
              if (window.innerWidth > 991) {
                linesImage = Line03;
                groupImage = Group02;
              } else {
                linesImage = Line03mob;
                groupImage = Group02Mobile;
              }
            } else if (index === 3) {
              if (window.innerWidth > 991) {
                linesImage = Line04;
              } else {
                linesImage = Line04mob;
              }
            } else if (index === 4) {
              if (window.innerWidth > 991) {
                groupImage = Group03;
                linesImage = Line05;
              } else {
                groupImage = Group03Mobile;
                linesImage = Line05mob;
              }
            } else if (index === 5) {
              if (window.innerWidth > 991) {
                linesImage = Line06;
              } else {
                linesImage = Line06mob;
              }
            }
            return (
              <div className="group-item" key={index}>
                <div className="group-item-body">
                  <div className="number-content">
                    <span>{`0${index + 1}`}</span>
                  </div>
                  <div className="group-item-body-content">
                    <h4>{item.label.value}</h4>
                    <GradientText text={item.text.value} />
                    <div className="group-item-line-container">
                      <img src={linesImage} />
                    </div>
                    {index === 5 && (
                      <div>
                        <div className="space-m"></div>
                        <BtnPrimary
                          link={props.fields.linkUrl.value.href}
                          label={props.fields.linkLabel.value}
                          icon={'ArrowRight'}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="group-item-image">
                  {groupImage !== undefined ? (
                    <img src={groupImage} alt="alt" />
                  ) : (
                    <img className="d-none" alt="none" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="space-l"></div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(VisionMissionScroll);
