import React from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const DocumentiSocietari = (props) => {
  // console.log('documentiSocietari', props);

  return (
    <RichText
      field={props.fields.content}
      tag="section"
      editable={false}
      className="text-center"
      data-sample="other-attributes-pass-through"
    />
  );
};

export default withSitecoreContext()(DocumentiSocietari);

// const FooterNavLinks = (props) => {
//   // const footerNavLinks = Object.keys(props.items).map((key) => {
//   //   return props.items[key];
//   // });

//   console.log('FooterNavLinks -> ', props);

//   const footerNavLinks = props.items;

//   return footerNavLinks.map((item, index) => (
//     <li key={index} className="footer-navigation-links">
//       {/* <NavLink to={item.url}>{item.displayName}</NavLink> */}
//       <NavLink to="#">{item.displayName}</NavLink>
//     </li>
//   ));
// };

// export default withSitecoreContext()(FooterNavLinks);
