import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Icon from '../Icon/index';
import { isServer } from '../../util';

import './icon-tab-item.scss';

const IconTabItems = (props) => {
  // console.log('IconTabItems', props);

  return (
    <div className="icon-tab-content">
      {!isServer() &&
        props.items.map((item, index) => {
          return (
            <div className="icon-tab-item" key={index}>
              <div className="icon-tab-item-body">
                <Icon icon={item.fields.icon.value} />
                <h4>{item.fields.label.value}</h4>
                <p>{item.fields.content.value}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default withSitecoreContext()(IconTabItems);
