import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import SecondaryButton from '../SecondaryButton/index';

import './basic-card.scss';
import { isServer } from '../../util';

const BasicCard = (props) => {
  if (isServer()) {
    return <></>;
  }

  return (
    <div className="basic-card-container">
      <div className="basic-card-content">
        <div className="basic-card-title">
          <h3>{props.item.fields.title.value}</h3>
        </div>
        <div className="basic-card-body">
          <p>{props.item.fields.body.value}</p>
        </div>
        <div className="basic-card-cta">
          <SecondaryButton
            link={props.item.fields.url.value}
            linkLabel={props.item.fields.urlLabel.value}
            icon={'ArrowRight'}
          />
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(BasicCard);
