import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './gradient-text.scss';

const GradientText = (props) => {
  return <div className="gradient-text">{props.text}</div>;
};

export default withSitecoreContext()(GradientText);
