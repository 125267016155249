import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import BtnPrimary from '../BtnPrimary/index';

import './side-content.scss';

const SideContentComponent = (props) => {
  // console.log('SideContentComponent', props.fields.linkLabel.value);
  let columns, button;
  if (props.fields.linkUrl.value !== '') {
    button = (
      <div>
        <BtnPrimary
          link={props.fields.linkUrl.value}
          label={props.fields.linkLabel.value}
          icon={'ArrowRight'}
        />
      </div>
    );
  }
  // console.log(button);
  return (
    <div className="side-content-container">
      <div className="container-fluid">
        <div className="row">
          <div className={props.fields.leftColumns.value}>
            <div className="side-content-body">
              <h2>{props.fields.title.value}</h2>
              <p>{props.fields.body.value}</p>
              <div className="space-s"></div>
              {button}
            </div>
          </div>
          <div className={props.fields.rightColumns.value}>
            <Placeholder name="dotlist-placeholder" rendering={props.rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SideContentComponent);
