import React, { Fragment } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

// import PreFooter from '../PreFooter/index';
import FooterNav from './FooterNav';
import FooterContact from './FooterContact';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import './footer.scss';

const Footer = (props) => {
  // console.log('Footer -> ', props);

  // if (preFooter !== undefined && preFooter !== null) {
  return (
    <Fragment>
      <div className="footer-container">
        <FooterNav fields={props.fields} />
        <FooterContact fields={props.fields} />
      </div>
    </Fragment>
  );
  // } else {
  //   return (
  //     <>
  //       <div className="space-xl"></div>
  //       <div className="footer-container">
  //         <Placeholder name="jss-preFooter" rendering={rendering} />
  //         <Placeholder name="jss-navFooter" rendering={rendering} />
  //         {/* <FooterNav items={navFooter} />
  //         <FooterContact contact={contactFooter} /> */}
  //       </div>
  //     </>
  //   );
  // }
};

export default withSitecoreContext()(Footer);
