import React, { useRef, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import SecondaryButton from '../SecondaryButton/index';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const ContentBlock = ({ fields }) => {
  const itemRef = useRef();
  useEffect(() => {
    // console.log('itemref', itemRef);
    if (itemRef.current !== null && itemRef.current !== undefined) {
      const animationItem = itemRef.current.querySelector('.gradient-animation');
      if (animationItem) {
        const gradientItem = animationItem.querySelector('.gradient-text');
        const ctx = gsap.context(() => {
          let gradientTl = gsap.timeline({
            scrollTrigger: {
              trigger: animationItem,
              scrub: true,
              start: 'top top',
              end: 'bottom',
              pin: animationItem,
              pinSpacer: false,
              markers: false,
              onUpdate: (self) => {
                if (self.progress > 0.3) {
                  gsap.to(gradientItem, {
                    opacity: 1,
                    duration: 0.5,
                  });
                }
              },
            },
          });
        }, itemRef);
        return () => ctx.revert();
      }
    }
  }, []);

  return (
    <div className="contentBlock">
      {/* <Text tag="h2" className="contentTitle" field={fields.heading} /> */}

      <RichText className="contentDescription" field={fields.content} ref={itemRef} />

      {fields.linkUrl && fields.linkUrl.value && fields.linkLabel.value !== '' && (
        <>
          <div className="row justify-content-center">
            <div className="col-md-2">
              <div style={{ textAlign: 'center' }}>
                <SecondaryButton
                  link={fields.linkUrl.value.href}
                  linkLabel={fields.linkLabel.value}
                />
              </div>
            </div>
          </div>
          <div className="space-m"></div>
        </>
      )}
    </div>
  );
};

export default withSitecoreContext()(ContentBlock);
