import React from 'react';

import { ReactComponent as ArrowExternal } from '../../assets/images/icons/arrow-external.svg';
import { ReactComponent as ArrowLeft } from '../../assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';
import { ReactComponent as CardEnviromental } from '../../assets/images/icons/card-enviromental.svg';
import { ReactComponent as CardGovernance } from '../../assets/images/icons/card-governance.svg';
import { ReactComponent as CardSocial } from '../../assets/images/icons/card-social.svg';
import { ReactComponent as Clock } from '../../assets/images/icons/clock.svg';
import { ReactComponent as Close } from '../../assets/images/icons/close.svg';
import { ReactComponent as Corruzione } from '../../assets/images/icons/corruzione.svg';
import { ReactComponent as Crescita } from '../../assets/images/icons/crescita.svg';
import { ReactComponent as CrescitaOrganica } from '../../assets/images/icons/crescita-organica.svg';
import { ReactComponent as CrescitaProfessionale } from '../../assets/images/icons/crescita-professionale.svg';
import { ReactComponent as Download } from '../../assets/images/icons/download.svg';
import { ReactComponent as DropdownClose } from '../../assets/images/icons/dropdown-close.svg';
import { ReactComponent as DropdownOpen } from '../../assets/images/icons/dropdown-open.svg';
import { ReactComponent as Energia } from '../../assets/images/icons/energia.svg';
import { ReactComponent as Flessibilita } from '../../assets/images/icons/flessibilita.svg';
import { ReactComponent as Formazione } from '../../assets/images/icons/formazione.svg';
import { ReactComponent as GroupMenu } from '../../assets/images/icons/group-menu.svg';
import { ReactComponent as ImpattoEconomico } from '../../assets/images/icons/impatto-economico.svg';
import { ReactComponent as Innovazione } from '../../assets/images/icons/innovazione.svg';
import { ReactComponent as Integrazione } from '../../assets/images/icons/integrazione.svg';
import { ReactComponent as Internazionalizzazione } from '../../assets/images/icons/internazionalizzazione.svg';
import { ReactComponent as Investimento } from '../../assets/images/icons/investimento.svg';
import { ReactComponent as Lens } from '../../assets/images/icons/lens.svg';
import { ReactComponent as LineeEsterne } from '../../assets/images/icons/linee-esterne.svg';
import { ReactComponent as Link } from '../../assets/images/icons/link.svg';
import { ReactComponent as Linkedin } from '../../assets/images/icons/linkedin.svg';
import { ReactComponent as Magnify } from '../../assets/images/icons/magnify.svg';
import { ReactComponent as Mail } from '../../assets/images/icons/mail.svg';
import { ReactComponent as MeritocraziaWelfare } from '../../assets/images/icons/meritocrazia-welfare.svg';
import { ReactComponent as PariOpportunita } from '../../assets/images/icons/pari-opportunita.svg';
import { ReactComponent as Persone } from '../../assets/images/icons/persone.svg';
import { ReactComponent as Pin } from '../../assets/images/icons/pin.svg';
import { ReactComponent as PoliticaFinanziaria } from '../../assets/images/icons/politica-finanziaria.svg';
import { ReactComponent as ProdottiServizi } from '../../assets/images/icons/prodotti-servizi.svg';
import { ReactComponent as Play } from '../../assets/images/icons/play.svg';
import { ReactComponent as Plus } from '../../assets/images/icons/plus.svg';
import { ReactComponent as Reduce } from '../../assets/images/icons/reduce.svg';
import { ReactComponent as Rifiuti } from '../../assets/images/icons/rifiuti.svg';
import { ReactComponent as Share } from '../../assets/images/icons/share.svg';
import { ReactComponent as Sicurezza } from '../../assets/images/icons/sicurezza.svg';
import { ReactComponent as SmArrowLeft } from '../../assets/images/icons/sm_arrow-left.svg';
import { ReactComponent as SmArrowRight } from '../../assets/images/icons/sm_arrow-right.svg';
import { ReactComponent as SocialFacebook } from '../../assets/images/icons/social-facebook.svg';
import { ReactComponent as SocialLinkedin } from '../../assets/images/icons/social-linkedin.svg';
import { ReactComponent as SocialTwitter } from '../../assets/images/icons/social-twitter.svg';
import { ReactComponent as Sostenibilita } from '../../assets/images/icons/sostenibilita.svg';
import { ReactComponent as Suolo } from '../../assets/images/icons/suolo.svg';
import { ReactComponent as Tempi } from '../../assets/images/icons/tempi.svg';
import { ReactComponent as Verified } from '../../assets/images/icons/verified.svg';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const Icon = (props) => {
  switch (props.icon) {
    case 'ArrowExternal':
      return <ArrowExternal />;
      break;
    case 'ArrowLeft':
      return <ArrowLeft />;
      break;
    case 'ArrowRight':
      return <ArrowRight />;
      break;
    case 'CardEnvironmental':
      return <CardEnviromental />;
      break;
    case 'CardGovernance':
      return <CardGovernance />;
      break;
    case 'CardSocial':
      return <CardSocial />;
      break;
    case 'Clock':
      return <Clock />;
      break;
    case 'Close':
      return <Close />;
      break;
    case 'Crescita':
      return <Crescita />;
      break;
    case 'Corruzione':
      return <Corruzione />;
      break;
    case 'CrescitaOrganica':
      return <CrescitaOrganica />;
      break;
    case 'CrescitaProfessionale':
      return <CrescitaProfessionale />;
      break;
    case 'Download':
      return <Download />;
      break;
    case 'DropdownClose':
      return <DropdownClose />;
      break;
    case 'DropdownOpen':
      return <DropdownOpen />;
      break;
    case 'Energia':
      return <Energia />;
      break;
    case 'Flessibilita':
      return <Flessibilita />;
      break;
    case 'Formazione':
      return <Formazione />;
      break;
    case 'GroupMenu':
      return <GroupMenu />;
      break;
    case 'ImpattoEconomico':
      return <ImpattoEconomico />;
      break;
    case 'Integrazione':
      return <Integrazione />;
      break;
    case 'Innovazione':
      return <Innovazione />;
      break;
    case 'Internazionalizzazione':
      return <Internazionalizzazione />;
      break;
    case 'Investimento':
      return <Investimento />;
      break;
    case 'Lens':
      return <Lens />;
      break;
    case 'Link':
      return <Link />;
      break;
    case 'LineeEsterne':
      return <LineeEsterne />;
      break;
    case 'Linkedin':
      return <Linkedin />;
      break;
    case 'Magnify':
      return <Magnify />;
      break;
    case 'Mail':
      return <Mail />;
      break;
    case 'MeritocraziaWelfare':
      return <MeritocraziaWelfare />;
      break;
    case 'PariOpportunita':
      return <PariOpportunita />;
      break;
    case 'Persone':
      return <Persone />;
      break;
    case 'Pin':
      return <Pin />;
      break;
    case 'Play':
      return <Play />;
      break;
    case 'Plus':
      return <Plus />;
      break;
    case 'PoliticaFinanziaria':
      return <PoliticaFinanziaria />;
      break;
    case 'ProdottiServizi':
      return <ProdottiServizi />;
      break;
    case 'Reduce':
      return <Reduce />;
      break;
    case 'Rifiuti':
      return <Rifiuti />;
      break;
    case 'Share':
      return <Share />;
      break;
    case 'Sicurezza':
      return <Sicurezza />;
      break;
    case 'SmArrowLeft':
      return <SmArrowLeft />;
      break;
    case 'SmArrowRight':
      return <SmArrowRight />;
      break;
    case 'SocialFacebook':
      return <SocialFacebook />;
      break;
    case 'SocialLinkedin':
      return <SocialLinkedin />;
      break;
    case 'SocialTwitter':
      return <SocialTwitter />;
      break;
    case 'Sostenibilita':
      return <Sostenibilita />;
      break;
    case 'Suolo':
      return <Suolo />;
      break;
    case 'Tempi':
      return <Tempi />;
      break;
    case 'Verified':
      return <Verified />;
      break;
    default:
      return <ArrowRight />;
  }
};

export default withSitecoreContext()(Icon);
