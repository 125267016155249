import React, { useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { isServer } from '../../util';
// import { scrollToTop } from '../helpers/scroll';

const ListPagination = ({
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  alwaysShown = true,
}) => {
  if (isServer()) {
    return <></>;
  }
  // console.log('count', itemsCount);
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = (number) => {
    // console.log('changePage', currentPage);
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', number);
    window.location.search = searchParams.toString();
    if (currentPage === number) return;
    setCurrentPage(number);
    // scrollToTop();
  };

  const onPageNumberClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', parseInt(currentPage) - 1);
    window.location.search = searchParams.toString();
    // changePage((currentPage) => currentPage - 1);
  };

  const onNextPageClick = () => {
    // console.log('onNextPageClick', currentPage);
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', parseInt(currentPage) + 1);
    window.location.search = searchParams.toString();
    // changePage((currentPage) => currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  };

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    // const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 5;
    let isCurrentPageWithinTwoPageNumbers;
    if (window.innerWidth > 543) {
      isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 5;
    } else {
      isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 0;
    }
    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === parseInt(currentPage)}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  useEffect(setLastPageAsCurrent, [pagesCount]);

  return (
    <>
      {isPaginationShown && (
        <Pagination>
          <Pagination.Prev key="prev" onClick={onPreviousPageClick} disabled={isCurrentPageFirst} />
          {pageNumbers}
          <Pagination.Next key="next" onClick={onNextPageClick} disabled={isCurrentPageLast} />
        </Pagination>
      )}
    </>
  );
};

export default ListPagination;
