import React from 'react';
import { Link } from 'react-router-dom';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const EpicaTest = ({
  sitecoreContext: {
    route: { fields },
  },
}) => {
  return <div></div>;
};

export default withSitecoreContext()(EpicaTest);
