import React from 'react';
import { withSitecoreContext, Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const CustomBackgroundComponent = (props) => {
  return (
    <div
      className="custom-background-container"
      style={{
        backgroundSize: '100% 100%',
        backgroundImage: `url(${props.fields.backgroundImage.value.src})`,
      }}
    >
      {/* <Image field={props.fields.backgroundImage} /> */}
      <Placeholder name="custombackground-placeholder" rendering={props.rendering} />
    </div>
  );
};

export default withSitecoreContext()(CustomBackgroundComponent);
