import React from 'react';
import './label.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const Label = (props) => {
  // console.log('PROPS LABEL -> ', props);

  return <div className="label-item">{<label>{props.label}</label>}</div>;
};
export default withSitecoreContext()(Label);
