import React, { useRef, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '../../util';
import BtnPrimary from '../BtnPrimary';
import SecondaryButton from '../SecondaryButton';

import './hero-images.scss';

// import Image from '../../assets/images/temp/hero-image.jpg';
// import ImageMobile from '../../assets/images/temp/hero-image-mobile.jpg';
import { ReactComponent as PlayIcon } from '../../assets/images/icons/play.svg';

const HeroImages = (props) => {
  if (isServer()) {
    return <div></div>;
  } else {
    let image, breadcrumb, heading, body, ctaSection, ctaSection2;
    let containerClass = 'hero-images-container';

    const thisHero = useRef(null);

    const playVideo = () => {
      const video = document.querySelector('.hero-video');
      const playIcon = document.querySelector('.play-video');
      video.play();
      playIcon.classList.add('play');
      video.addEventListener('ended', () => {
        playIcon.classList.remove('play');
      });
    };

    if (props.fields.video.value !== null) {
      image = (
        <div className="hero-video-content">
          <div className="play-video">
            <PlayIcon onClick={playVideo} />
          </div>
          <video className="hero-video" poster={image}>
            <source src="/" type="video/mp4" />
          </video>
        </div>
      );
    } else if (
      (props.fields.video.value === null &&
        props.fields.img.value === null &&
        props.fields.mobileImage.value === null) ||
      (props.fields.img.value.src !== undefined &&
        props.fields.img.value.src.includes('no-hero-bg.png'))
    ) {
      containerClass = 'hero-images-container no-image';
    } else {
      // console.log('mobileimage', props.fields.mobileImage.value.src === null);
      let mobileImage =
        props.fields.mobileImage.value.src !== undefined
          ? props.fields.mobileImage.value.src
          : props.fields.img.value.src;
      image = (
        <div className="hero-image">
          <img className="d-none d-sm-block" src={props.fields.img.value.src} alt="hero" />
          <img className="d-block d-sm-none" src={mobileImage} alt="hero" />
        </div>
      );
    }

    if (props.fields.breadcrumb !== undefined) {
      breadcrumb = props.fields.breadcrumb.value;
    }
    if (props.fields.heading !== undefined) {
      heading = props.fields.heading.value;
    }
    if (props.fields.body !== undefined) {
      body = <RichText field={props.fields.body} />;
    }

    if (props.fields.ctaLabel !== undefined && props.fields.ctaLabel.value !== '') {
      if (props.fields.ctaType.value.toLowerCase() === 'download') {
        ctaSection = (
          <div className="cta-container">
            <BtnPrimary
              link={props.fields.ctaLink.value.href}
              label={props.fields.ctaLabel.value}
              type={'download'}
              icon={'Download'}
            />
          </div>
        );
      } else {
        ctaSection = (
          <div className="cta-container">
            <BtnPrimary
              link={props.fields.ctaLink.value.href}
              label={props.fields.ctaLabel.value}
              icon={'ArrowRight'}
            />
          </div>
        );
      }
    }

    if (props.fields.ctaLabel2 !== undefined && props.fields.ctaLabel2.value !== '') {
      let typeValue, iconValue;
      if (props.fields.ctaType2.value.toLowerCase() === 'download') {
        typeValue = 'download';
        iconValue = 'Download';
      } else {
        typeValue = '';
        iconValue = 'ArrowRight';
      }
      // console.log(props.fields.ctaLink2);
      ctaSection2 = (
        <div className="secondary-cta cta-container">
          <SecondaryButton
            link={props.fields.ctaLink2.value.href}
            linkLabel={props.fields.ctaLabel2.value}
            type={typeValue}
            icon={iconValue}
          />
        </div>
      );
    }

    // console.log(props);

    // console.log(props.fields.breadcrumb);
    // console.log('image url', props.fields.img.value.src);
    return (
      <div>
        {window && (
          <div className={containerClass} ref={thisHero}>
            {image}
            <div className="hero-images-body">
              {props.fields.breadcrumb.value !== '' ? (
                <h4 className="space-s bottom">{breadcrumb}</h4>
              ) : (
                ''
              )}
              <h1>{heading}</h1>
              {props.fields.body.value !== '' ? <p>{body}</p> : ''}
              {ctaSection}
              {ctaSection2}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default withSitecoreContext()(HeroImages);
