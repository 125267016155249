import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import CardEvento from '../CardEvento/index';
import CardLavori from '../CardLavori/index';
import EventiDetailContent from '../EventiDetailContent/index';

const EventsMainComponent = (props) => {
  const elementType = props.fields.contentType.value;
  const eventsList = [
    {
      id: '1',
      type: 'event',
      title: 'Assemblea Azionisti Tinexta Aprile 2022',
      subtitles: {
        value:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
      },
      startDate: 'April 28, 2022 09:00:00',
      endDate: 'April 28, 2022 09:00:00',
      location: 'Milano',
      description: {
        value:
          '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>',
      },
      eventTag: ['Price Sensitive', 'Business Innovation'],
      eventLink: '/link',
      eventMedia: [
        {
          img: 'event.jpg',
        },
      ],
      socialLink: [
        {
          socialName: 'facebook',
          socialLink: 'https://facebook.com',
        },
        {
          socialName: 'linkedin',
          socialLink: 'https://www.linkedin.com/',
        },
        {
          socialName: 'twitter',
          socialLink: 'https://www.twitter.com/',
        },
        {
          socialName: 'email',
          socialLink: 'mailto:email@gmail.com',
        },
      ],
      additionalLink: [
        {
          linkLabel: 'Webcast',
          linkType: 'external-link',
          linkUrl: 'http/link.com',
        },
        {
          linkLabel: 'Download',
          linkType: 'download',
          linkUrl: 'http/link.com',
        },
        {
          linkLabel: 'Sito dell’evento',
          linkType: 'external-link  ',
          linkUrl: 'http/link.com',
        },
      ],
    },
    {
      id: '2',
      type: 'event',
      title: 'Italy Insurance Forum',
      subtitles: {
        value:
          'L&apos;evento di riferimento per una visione completa sulla trasformazione dell&apos;ecosistema assicurativo.',
      },
      startDate: 'April 27, 2022 09:00:00',
      endDate: 'April 28, 2022 09:00:00',
      location: 'Milano',
      description: {
        value:
          '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>',
      },
      eventTag: ['Price Sensitive', 'Business Innovation'],
      eventLink: '/link',
      eventMedia: [
        {
          img: 'event.jpg',
        },
      ],
      socialLink: [
        {
          socialName: 'facebook',
          socialLink: 'https://facebook.com',
        },
        {
          socialName: 'linkedin',
          socialLink: 'https://www.linkedin.com/',
        },
        {
          socialName: 'twitter',
          socialLink: 'https://www.twitter.com/',
        },
        {
          socialName: 'email',
          socialLink: 'mailto:email@gmail.com',
        },
      ],
      additionalLink: [
        {
          linkLabel: 'Webcast',
          linkType: 'external-link',
          linkUrl: 'http/link.com',
        },
        {
          linkLabel: 'Download',
          linkType: 'download',
          linkUrl: 'http/link.com',
        },
        {
          linkLabel: 'Sito dell’evento',
          linkType: 'external-link',
          linkUrl: 'http/link.com',
        },
      ],
    },
    {
      id: '3',
      type: 'event',
      title: 'PNRR e PMI: istruzioni per l’uso',
      subtitles: {
        value:
          'Tinexta è sponsor dell’evento digitale organizzato da Unioncamere e Il Sole 24 Ore sull’accesso ai fondi del PNRR, oggi più cruciali che mai per rimanere competitivi sul mercato.',
      },
      startDate: 'May 16, 2022 15:00:00',
      endDate: 'May 16, 2022 15:00:00',
      location: 'Online',
      description: {
        value:
          '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>',
      },
      eventTag: ['Price Sensitive'],
      eventLink: '/link',
      eventMedia: [
        {
          video: 'https://www.youtube.com/embed/b7c1mm3goDs',
        },
      ],
      socialLink: [
        {
          socialName: 'facebook',
          socialLink: 'https://facebook.com',
        },
        {
          socialName: 'linkedin',
          socialLink: 'https://www.linkedin.com/',
        },
        {
          socialName: 'twitter',
          socialLink: 'https://www.twitter.com/',
        },
        {
          socialName: 'email',
          socialLink: 'mailto:email@gmail.com',
        },
      ],
      additionalLink: [],
    },
  ];

  let cardColumn;
  let rowClass = 'row';

  if (elementType === 'detail') {
    rowClass = 'row justify-content-center';
  }

  // console.log(props);

  return (
    <div className="container-fluid">
      <div className={`${rowClass} space-l`}>
        {elementType === 'card-evento' ? (
          <>
            {eventsList.map((item, index) => {
              if (eventsList.length > 2) {
                cardColumn = 'col-md-6 col-lg-3';
              } else {
                rowClass = 'row justify-content-center';
                cardColumn = 'col-lg-6';
              }
              return (
                <div className={cardColumn} key={index}>
                  <div>
                    <CardEvento item={item} />
                  </div>
                </div>
              );
            })}
          </>
        ) : elementType === 'detail' ? (
          <>
            {eventsList.map((item, index) => {
              rowClass = 'row justify-content-center';
              if (item.title === props.fields.detailItem.value) {
                return <EventiDetailContent item={item} key={index} />;
              }
              if (props.fields.nextItem.value !== false) {
                if (item.title === props.fields.nextItem.value) {
                  return <EventiDetailContent item={item} key={index} next="true" />;
                }
              }
            })}
          </>
        ) : elementType === 'card-lavori' ? (
          <>
            {eventsList.map((item, index) => {
              return (
                <div className="col-md-6" key={index}>
                  <div>
                    <CardLavori item={item} />
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default withSitecoreContext()(EventsMainComponent);
