import React, { useState, useRef } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import SecondaryButton from '../SecondaryButton';

import './card-gradient.scss';
import { isServer } from '../../util';

const CardGradient = (props) => {
  if (isServer()) {
    return <div className="card-gradient-container"></div>;
  }

  const hoverBackground = (e) => {
    const itemElement = e.target.closest('.card-gradient-item');
    itemElement.classList.add('hover-state');
  };
  const unhoverBackground = (e) => {
    const itemElement = e.target.closest('.card-gradient-item');
    itemElement.classList.remove('hover-state');
  };

  let columnValue, containerValue, rowValue;
  const CardGradientTab = Object.keys(props.fields.cardGradientTab).map((key) => {
    return props.fields.cardGradientTab[key];
  });
  CardGradientTab.map((item, index) => {
    if (item.fields.type.value === 'half') {
      columnValue = 'col-lg-6';
      containerValue = 'card-gradient-container double-item';
      rowValue = 'row gx-5 gy-4';
    } else {
      columnValue = 'col-md-12';
      containerValue = 'card-gradient-container single-item';
      rowValue = 'row';
    }
  });
  return (
    <div className={containerValue}>
      <div className="container-fluid">
        <div className={rowValue}>
          {CardGradientTab.map((item, index) => {
            return (
              <div className={columnValue} key={index}>
                <div className="card-gradient-item">
                  <div className="card-gradient-item-content">
                    <h2>{item.fields.heading.value}</h2>
                    <p>{item.fields.body.value}</p>
                    <div
                      className="cta-container"
                      onMouseEnter={(e) => {
                        hoverBackground(e);
                      }}
                      onMouseLeave={(e) => {
                        unhoverBackground(e);
                      }}
                    >
                      <SecondaryButton
                        link={item.fields.linkUrl.value}
                        linkLabel={item.fields.linkLabel.value}
                        icon={'ArrowRight'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardGradient;

// onMouseEnter={setHoverState('hover-state')}
// onMouseLeave={setHoverState('')}
