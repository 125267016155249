import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
// import { ArrowRight } from '../../assets/images/icons/arrow-right.svg';

import Icon from '../Icon/index';

import './btn-primary.scss';
import { isServer } from '../../util';

const BtnPrimary = (props) => {
  // console.log('BtnPrimary', props);
  if (isServer()) {
    return <></>;
  }

  let iconContent, linkType;

  // console.log('type props', props.type);
  // debugger;

  if (props.icon !== undefined && props.icon !== null) {
    iconContent = <Icon icon={props.icon} />;
  } else {
    iconContent = <Icon icon={'ArrowRight'} />;
  }

  if (props.type) {
    // console.log('TYPE', props.type);
    if (props.type.toLowerCase() === 'download') {
      iconContent = <Icon icon={'Download'} />;
      linkType = {
        download: props.link,
      };
    }
    if (props.type.toLowerCase() === 'external-link') {
      iconContent = <Icon icon={'ArrowExternal'} />;
      linkType = {
        download: props.link,
      };
    }
    if (props.type.toLowerCase() === 'internal') {
      iconContent = <Icon icon={'ArrowRight'} />;
      linkType = {
        download: props.link,
      };
    }
    if (props.type.toLowerCase() === 'add') {
      iconContent = <Icon icon={'Plus'} />;
      linkType = {
        download: props.link,
      };
    }
    // if (props.type === undefined || props.type === null || props.type === '') {
    //   console.log('aaaa');
    //   iconContent = <Icon icon={'ArrowRight'} />;
    //   linkType = {
    //     download: props.link,
    //   };
    // }
  } else {
    if (!props.icon) {
      iconContent = <Icon icon={'ArrowRight'} />;
      linkType = {
        download: props.link,
      };
    } else {
      iconContent = <Icon icon={'ArrowRight'} />;
      linkType = {
        externallink: props.link,
      };
    }
  }

  // console.log('props', props);
  // console.log('iconContent', iconContent);
  // console.log('linkType', linkType);
  // console.log({
  //   label: props.label,
  //   type: Object.keys(linkType)[0],
  // });
  // console.log('props.onClick', props.onClick);
  // console.log(
  //   '!props.onClick && linkType && !linkType.download',
  //   !props.onClick && linkType && !linkType.download
  // );
  // console.log(
  //   '!props.onClick && linkType && linkType.download',
  //   !props.onClick && linkType && linkType.download
  // );
  return (
    <div className="custom-btn-primary">
      {props.onClick && (
        <a href={props.link} onClick={props.onClick}>
          {props.label}
          {iconContent}

          {/* <img src={ArrowRight}></img> */}
        </a>
      )}
      {!props.onClick && linkType && !linkType.download && (
        // <NavLink to={props.link} {...linkType}>
        //   {props.label}
        //   {iconContent}
        // </NavLink>
        <a href={props.link}>
          {props.label}
          {iconContent}
        </a>
      )}
      {!props.onClick && linkType && linkType.download && (
        <a href={props.link} target="_blank" rel="noreferrer">
          {props.label}
          {iconContent}
        </a>
      )}
    </div>
  );
};

export default withSitecoreContext()(BtnPrimary);
