import React from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import DropdownButton from 'react-bootstrap/esm/DropdownButton';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import './voci-dal-gruppo.scss';
// import { Link } from 'react-router-dom';
import Icon from '../Icon/index';
import BtnPrimary from '../BtnPrimary/index';
import { isServer } from '../../util';
import { Redirect } from 'react-router-dom';

const VociDalGruppoDetailContent = (props) => {
  // console.log('VociDalGruppoDetailContent', props);

  if (isServer()) {
    return <></>;
  }
  window.scrollTo(0, 0);
  let componentProps;
  if (props && props.sitecoreContext && props.sitecoreContext.route) {
    componentProps = props.sitecoreContext.route.fields;
  } else if (props.fields) {
    componentProps = props.fields;
  } else {
    return <Redirect to="/" />;
  }

  // console.log('VociDalGruppoDetailContent', componentProps.videoUrl);

  return (
    <div className="container-fluid voci-detail-container">
      <div className="row">
        {componentProps.videoUrl && (
          <div>
            <div className="event-detail-media-item video">
              {/* <iframe
              src={componentProps.videoUrl.value.href}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}
              <div dangerouslySetInnerHTML={{ __html: componentProps.videoUrl.value }} />
              {/* <>{componentProps.videoUrl.value}</> */}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="m-auto col-lg-10 col-xl-8">
          <div className="voci-detail-item">
            <div className="voci-detail-heading">
              <div className="voci-detail-date-container">
                {/* {componentProps.date && ( */}
                <div className="event-detail-date-item">
                  <div className="event-detail-date">
                    <span>
                      <Text field={componentProps.label} />
                      {/* {new Date(componentProps.date.value).getDate() +
                          '/' +
                          (new Date(componentProps.date.value).getMonth() + 1) +
                          '/' +
                          new Date(componentProps.date.value).getFullYear()} */}
                    </span>
                  </div>
                </div>
                {/* )} */}
              </div>
              <div className="voci-detail-title">
                <h1>
                  <Text field={componentProps.title} />
                </h1>
                {componentProps.description && (
                  <h3>
                    <RichText field={componentProps.description} />
                  </h3>
                )}
              </div>
              <div className="event-detail-action-container">
                <div className="social-cta">
                  <DropdownButton
                    as={ButtonGroup}
                    key={'end'}
                    id="social-dropdown-email"
                    drop={'end'}
                    title={<Icon icon={'Share'} />}
                  >
                    <Dropdown.Item
                      eventKey="0"
                      href={'mailto:?body=' + window.location.href}
                      className="social-link"
                    >
                      <Icon icon={'Mail'} />
                      Email
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              {componentProps.actionUrl ? (
                <div className="voci-detail-action-container">
                  <div className="voci-detail-action-item">
                    <BtnPrimary
                      link={componentProps.actionUrl.value.href}
                      label={componentProps.actionLabel.value}
                      type={componentProps.actionType.value}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {componentProps.paragraph && (
              <div className="voci-detail-content">
                <RichText field={componentProps.paragraph} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(VociDalGruppoDetailContent);
