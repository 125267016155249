import React from 'react';
import Label from '../Label';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './numbers.scss';
const Numbers = (props) => {
  // const route = props.sitecoreContext.route;
  // console.log('ROUTE -> ', route);
  // console.log('PROPS -> ', props);

  const labelsTab = Object.keys(props.fields.labelsTab).map((key) => {
    return props.fields.labelsTab[key];
  });

  return (
    <div className="container-fluid space-m">
      <div className="row">
        <div className="col-md-6 col-lg-5">
          <div className="numbers-container">
            <div className="numbers-content">
              <div className="numbers-labels">
                {labelsTab.map((item, index) => (
                  <Label key={'labelsTab-' + index} fields={item.fields} />
                ))}
              </div>
              <div className="numbers-body">
                <h2>{props.fields.price.value}</h2>
                <p>{props.fields.description.value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withSitecoreContext()(Numbers);
