import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
const { isServer } = require('../../util');

import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const ExternalScripts = (props) => {
  const announcesPage = 'opportunita-professionali';
  const registrationPage = 'candidatura-spontanea';
  let currentPage = props.sitecoreContext.route.name;
  if (isServer()) {
    switch (currentPage) {
      case announcesPage:
        // console.log('case announces');
        return <div className="inrecruiting_announces"></div>;
      case registrationPage:
        // console.log('case form');
        return <div className="inrecruiting_form"></div>;
      default:
        // console.log('case default');
        return <></>;
    }
  }

  useEffect(() => {
    switch (currentPage) {
      case announcesPage:
        inRecruiting('inrecruiting_announces').inrIframe({
          fontFamily: 'Open Sans',
          bgColor: 'FFFFFF',
          bgRowVacancy: 'FDFDFD',
          separatoColor: 'AAAAAA',
          filterColor: '494444',
          filterActiveColor: '42B2E5',
          jobTitleColor: '494444',
          jobTitleActiveColor: '42B2E5',
          jobDescColor: '888888',
          jobInfoColor: '494444',
        });
        // console.log('case announces 1');
        createAnnouncesIFrame();
      // return <div className="inrecruiting_announces"></div>;
      case registrationPage:
        // console.log('case form 1');
        createRegistrationIFrame();
      // return <div className="inrecruiting_form"></div>;
      default:
        return;
      // return <></>;
    }
  }, []);

  // window.location.reload(false);

  // setTimeout(() => {
  //   const head = document.querySelector('head');
  //   const script = document.createElement('script');

  //   script.setAttribute('src', props.fields.script.value);
  //   script.type = 'text/javascript';
  //   // script.defer = true;
  //   // script.setAttribute('defer', '');
  //   // script.setAttribute('crossorigin', 'anonymous');

  //   head.appendChild(script);

  //   return () => {
  //     head.removeChild(script);
  //   };
  // }, 100);
  // const head = document.querySelector('head');
  // const script = document.createElement('script');

  // var scripts = document.getElementsByTagName('script');
  // for (var i = scripts.length; i--; ) {
  //   if (scripts[i].src === props.fields.script.value) {
  //     return <div className="iframe-candidatura"></div>;
  //   } else {
  //     script.setAttribute('src', props.fields.script.value);
  //     script.type = 'text/javascript';
  //     head.appendChild(script);
  //     return <div className="iframe-candidatura"></div>;
  //   }
  // }
  // const iframe =
  //   '<iframe src="https://tinexta.intervieweb.it/app.php?module=iframeAnnunci&amp;lang=it&amp;k=c1886f06c1046ffaa6cee1b51db541b7&amp;d=localhost&amp;LAC=&amp;utype=&amp;act1=23&amp;defgroup=name&amp;gnavenable=1&amp;desc=1&amp;annType=published&amp;h=400&amp;typeView=small&amp;fontFamily=Open Sans&amp;bgColor=FFFFFF&amp;bgRowVacancy=FDFDFD&amp;separatoColor=AAAAAA&amp;filterColor=494444&amp;filterActiveColor=42B2E5&amp;jobTitleColor=494444&amp;jobTitleActiveColor=42B2E5&amp;jobDescColor=888888&amp;jobInfoColor=494444" id="inrecruiting_announcesIframe" onLoad="resizeIframe(this)" allowusermedia="" allow="camera;microphone" height="400px" width="100%" style="border: none"></iframe>';

  // return (
  //   <div className="inrecruiting_announces"></div>
  // );

  switch (currentPage) {
    case announcesPage:
      return <div className="inrecruiting_announces"></div>;
    case registrationPage:
      return <div className="inrecruiting_form"></div>;
    default:
      return <></>;
  }
};

export default withSitecoreContext()(ExternalScripts);

// <iframe
//   src="https://tinexta.intervieweb.it/app.php?module=iframeAnnunci&amp;lang=it&amp;k=c1886f06c1046ffaa6cee1b51db541b7&amp;d=localhost&amp;LAC=&amp;utype=&amp;act1=23&amp;defgroup=name&amp;gnavenable=1&amp;desc=1&amp;annType=published&amp;h=400&amp;typeView=small&amp;fontFamily=Open Sans&amp;bgColor=FFFFFF&amp;bgRowVacancy=FDFDFD&amp;separatoColor=AAAAAA&amp;filterColor=494444&amp;filterActiveColor=42B2E5&amp;jobTitleColor=494444&amp;jobTitleActiveColor=42B2E5&amp;jobDescColor=888888&amp;jobInfoColor=494444"
//   id="inrecruiting_announcesIframe"
//   allowusermedia=""
//   allow="camera;microphone"
//   height="400px"
//   width="100%"
//   style="border: none"
// ></iframe>;
