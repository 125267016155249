import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
const { isServer } = require('../../util');
import BtnPrimary from '../BtnPrimary/index';
gsap.registerPlugin(ScrollTrigger);

import './hero-think-next.scss';

const HeroThinkNext = (props) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  let countVar = 0;

  const history = useHistory();

  let killTimeline, heroStepAnimation;
  let disabelTrigger, enableTrigger, anchorId, loadScrollTrigger;

  if (isServer()) {
    // console.log('isServer');
    return <div className="hero-think-next-container" ref={itemRef}></div>;
  }
  const itemRef = useRef(null);

  let heroItems;

  if (props.fields) {
    heroItems = Object.keys(props.fields.heroSteps).map((key) => {
      return props.fields.heroSteps[key];
    });
  } else {
    heroItems = [];
  }
  // console.log(heroItems);

  useEffect(() => {
    // console.log(isVisible);
    const ctx = gsap.context(() => {
      let panels = gsap.utils.toArray('.hero-think-next-item');
      let panelsLength = panels.length;
      if (isVisible === true) {
        gsap.to('.hero-think-img-content ', {
          y: '0',
          duration: 0.1,
        });
        gsap.to('.hero-think-next-content', {
          y: '0',
          duration: 0.1,
        });
        panels.forEach((panel, i) => {
          panel.classList.add(`item-${i}`);
          itemRef.current.style.height = `${(panels.length + 1) * 100}vh`;
          panel.style.transform = `translateY(${i * 100}%)`;
          ScrollTrigger.create({
            start: 'top top',
            end: '100%',
            markers: false,
            pin: '.hero-think-next-content',
            pinSpacing: true,
            trigger: panel,
            onEnter: (self, e) => {
              if (panel.classList.contains('item-0')) {
                gsap.to('.hero-think-next-heading', {
                  opacity: 0,
                  yPercent: -200,
                  overwrite: 'true',
                  duration: 1,
                  delay: 0.1,
                });
                gsap.to('.hero-think-img-content', {
                  yPercent: -16.66,
                });
              }
              if (panel.classList.contains('item-1')) {
                // console.log(panel.classList);
                gsap.to('.item-1', {
                  duration: 1,
                  opacity: 1,
                  yPercent: -100,
                });
                gsap.to('.hero-think-img-content', {
                  duration: 1,
                  yPercent: -33.33,
                });
              }
            },
            onLeave: (self, e) => {
              if (panel.classList.contains('item-1')) {
                gsap.to('.item-1', {
                  duration: 1,
                  opacity: 1,
                  yPercent: -200,
                });
                gsap.to('.hero-think-img-content', {
                  duration: 1,
                  yPercent: -50,
                });
              }
            },
            onEnterBack: (self, e) => {
              if (panel.classList.contains('item-0')) {
                gsap.to('.hero-think-img-content', {
                  duration: 1,
                  yPercent: -16.66,
                });
              }
              if (panel.classList.contains('item-1')) {
                gsap.to('.item-1', {
                  duration: 1,
                  opacity: 1,
                  yPercent: -100,
                });
                gsap.to('.hero-think-img-content', {
                  duration: 1,
                  yPercent: -33.33,
                });
              }
            },
            onLeaveBack: (self, e) => {
              if (panel.classList.contains('item-0')) {
                gsap.to('.hero-think-next-heading', {
                  opacity: 1,
                  yPercent: -50,
                  overwrite: 'true',
                  duration: 1,
                  delay: 0,
                });
                gsap.to('.hero-think-img-content', {
                  duration: 1,
                  yPercent: 0,
                });
              }
              if (panel.classList.contains('item-1')) {
                // console.log(panel.classList);
                gsap.to('.item-1', {
                  opacity: 0,
                  yPercent: 100,
                  duration: 1,
                });
              }
            },
          });
        });
      }
    }, itemRef);
    return () => ctx.revert();
  });

  return (
    <div
      className="hero-think-next-container"
      ref={itemRef}
      onLoad={() => {
        setIsVisible(true);
      }}
    >
      <div className="hero-think-next-content">
        <div className="hero-think-next-content-animation">
          <div className="hero-think-img">
            <div
              className="hero-think-img-content"
              style={{ backgroundImage: `url(${props.fields.img.value.src})` }}
            >
              <img src={props.fields.img.value.src} />
            </div>
          </div>
          <div className="hero-think-next-heading">
            <div className="hero-think-next-heading-content">
              <h1>{props.fields.heading.value}</h1>
              <p>{props.fields.subHeading.value}</p>
            </div>
          </div>
          {heroItems.map((item, index) => {
            let itemContent;
            if (item.fields.body.value !== '') {
              itemContent = <p>{item.fields.body.value}</p>;
            } else {
              itemContent = <h2>{item.fields.title.value}</h2>;
            }
            return (
              <div className="hero-think-next-item" key={index}>
                <div className="hero-think-next-item-content">{itemContent}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(HeroThinkNext);
