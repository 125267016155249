import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import CardSlider from '../CardSlider/index';

import './hero-card-slide.scss';

import HeroImage from '../../assets/images/temp/hero-image.jpg';
import HeroImageMobile from '../../assets/images/temp/hero-image-mobile.jpg';
import { isServer } from '../../util';

const HeroCardSlider = (props) => {
  // console.log('hero', props);
  if (isServer()) {
    return <div className="hero-card-container"></div>;
  }

  // console.log('hero', props);
  return (
    <div className="hero-card-container">
      <div className="hero-card-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-lg-5">
              <CardSlider item={props.fields.cardsSlider} type="hero" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(HeroCardSlider);
