import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
const { isServer } = require('../../util');
import BtnPrimary from '../BtnPrimary/index';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import './storia-component.scss';

const StoriaComponent = (props) => {
  // console.log('storia props', props);
  let elContainer, killTimeline;
  let disabelTrigger, enableTrigger, checkActiveSlide, panelsLength, panels;

  if (isServer()) {
    return <div className="storia-component-container"></div>;
  }

  const itemRef = useRef(null);

  let storiaTl = gsap.timeline({});

  const storiaItems = Object.keys(props.fields.StoriaComponentItems).map((key) => {
    // console.log('storia items', props.fields.StoriaComponentItems[key]);
    return props.fields.StoriaComponentItems[key];
  });

  const goToSlide = (slideTarget) => {
    let targetId = `#${slideTarget}`;
    let target = document.querySelector(`#${slideTarget}`);
    let targetIndex = target.getAttribute('data-index');
    let left = target.offsetLeft;
    if (window.innerWidth > 991) {
      gsap.to('html', { scrollTo: left, duration: 1.5 });
    } else {
      gsap.to(window, {
        scrollTo: targetId,
        duration: 1.5,
      });
    }
  };

  const fixedNavLink = (panels) => {
    panels.forEach((panel, i) => {
      let panelId = panel.getAttribute('id');
      panel.addEventListener(
        'click',
        (event) => {
          event.preventDefault();
          goToSlide(panelId);
        },
        { once: true }
      );
    });
  };

  const cloneMarqueeItem = (extLength, innerLength, innerItem, innerContent) => {
    if (extLength > innerLength) {
      let contentClone = innerContent.cloneNode(true);
      innerItem.append(contentClone);
      // console.log(extLength);
      // console.log(innerLength);
    }
  };

  const checkMarqueeSize = (marquee) => {
    const checkSize = () => {
      let marqueeLength = marquee.offsetWidth;
      let marqueeInner = marquee.querySelector('.marquee-content');
      let marqueeInnerContent = marqueeInner.querySelector('p');
      let marqueeInnerLength = marqueeInner.offsetWidth;
      if (marqueeLength > marqueeInnerLength) {
        cloneMarqueeItem(marqueeLength, marqueeInnerLength, marqueeInner, marqueeInnerContent);
        checkSize();
      } else {
        let itemClone = marqueeInner.cloneNode(true);
        marquee.append(itemClone);
        return;
      }
    };
    checkSize();
  };

  useEffect(() => {
    let maxWidth = 0;
    let storiaContainer = document.querySelector('.storia-component-container');
    let storyNavItem = document.querySelector('.storia-nav-container');
    let storyNavClone = storyNavItem.cloneNode(true);
    let storyFixNav = document.querySelector('.storia-fixed-nav');

    const ctx = gsap.context(() => {
      let storiaTl = gsap.timeline({});
      elContainer = itemRef.current.querySelector('.storia-component-slide-container');
      panels = gsap.utils.toArray('.storia-component-outer-item');
      panelsLength = panels.length;
      if (window.innerWidth > 991) {
        storiaTl.to('.storia-component-slide-container', {
          // x: () => -(elContainer.offsetWidth + innerWidth) + 'px',
          x: () => -((props.fields.StoriaComponentItems.length - 1) * innerWidth) + 'px',
          ease: 'none',
          scrollTrigger: {
            trigger: elContainer,
            invalidateOnRefresh: true,
            pin: true,
            scrub: 1.6,
            // end: () => '+=' + 25 * innerWidth,
            end: () => '+=' + (props.fields.StoriaComponentItems.length - 1) * innerWidth,
            onEnter: () => {
              if (storyFixNav === null || storyFixNav === undefined) {
              } else {
                storyFixNav.classList.remove('show-nav');
              }
            },
            onUpdate: (self) => {
              storyFixNav = document.querySelector('.storia-fixed-nav');
              let splitProgress = 1 / panelsLength;
              // console.log(self.progress);
              // console.log(splitProgress);
              if (self.progress > splitProgress * 2) {
                if (storyFixNav === null || storyFixNav === undefined) {
                } else {
                  storyFixNav.classList.add('show-nav');
                }
              } else if (self.progress < splitProgress * 2 || self.progress > 1) {
                if (storyFixNav === null || storyFixNav === undefined) {
                } else {
                  storyFixNav.classList.remove('show-nav');
                }
              }

              if (storyFixNav === null || storyFixNav === undefined) {
              } else {
                let storyFixItem = storyFixNav.querySelectorAll('.storia-nav-item');
                // console.table(storyFixItem);
                storyFixItem.forEach((item, i) => {
                  let indexTarget = item.querySelector('.dot').getAttribute('id');
                  let targetId = `#${indexTarget}`;
                  let left = targetId.offsetLeft;
                  let indexNummber = document
                    .querySelector(`#${indexTarget}`)
                    .getAttribute('data-index');
                  // console.log('nav', {
                  //   progress: self.progress,
                  //   splitProgress: splitProgress,
                  //   indexNummber: indexNummber,
                  // });

                  if (self.progress > splitProgress * indexNummber) {
                    item.querySelector('.dot').classList.add('active');
                  }
                });
              }
            },
          },
        });
      }
      storyNavClone.classList.add('storia-fixed-nav');
      storiaContainer.appendChild(storyNavClone);
      let fixNavTems = storyNavClone.querySelectorAll('.dot');
      fixedNavLink(fixNavTems);
    }, itemRef);
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    let allMarquee = document.querySelectorAll('.marquee-container');
    allMarquee.forEach((item, index) => {
      checkMarqueeSize(item);
    });
  });

  return (
    <div className="storia-component-container" ref={itemRef}>
      <div className="storia-component-slide-container">
        {storiaItems.map((item, index) => {
          // debug
          // console.log('REF', itemRef.current);
          // if (
          //   item.fields.heading.value !== '' &&
          //   item.fields.img.value.src &&
          //   item.fields.img.value.src.length > 0
          // ) {
          //   console.log('caso 1', item.fields.slideId.value);
          // } else if (
          //   item.fields.heading.value === '' &&
          //   item.fields.body.value === '' &&
          //   item.fields.img.value.src &&
          //   item.fields.img.value.src.length > 0
          // ) {
          //   console.log('caso 2', item.fields.slideId.value);
          // } else if (
          //   item.fields.img.value.src &&
          //   item.fields.body.value &&
          //   item.fields.img.value.src.length > 0
          // ) {
          //   console.log('caso 3', item.fields.slideId.value);
          // } else if (item.fields.bodyTitle.value !== '') {
          //   console.log('caso 4', item.fields.slideId.value);
          // } else if (item.fields.body.value !== '') {
          //   console.log('caso 5', item.fields.slideId.value);
          //   console.table(item.fields);
          // } else {
          //   console.log('caso X', item.fields.slideId.value);
          // }
          // fine debug

          let bgImage, bodyHeading, bodyContent, slideImage, bodyImage, storiaNav;

          if (item.fields.StoriaNavigation.length > 0) {
            const storiaNavItems = Object.keys(item.fields.StoriaNavigation).map((key) => {
              return item.fields.StoriaNavigation[key];
            });
            storiaNav = (
              <div className="storia-nav-container">
                {storiaNavItems.map((item, index) => {
                  return (
                    <div className="storia-nav-item" key={index}>
                      <p>{item.fields.years.value}</p>
                      <div
                        className="dot"
                        id={item.fields.anchor.value}
                        onClick={(event) => {
                          event.preventDefault();
                          goToSlide(item.fields.anchor.value);
                        }}
                      ></div>
                      <p>{item.fields.label.value}</p>
                    </div>
                  );
                })}
              </div>
            );
          }
          if (
            item.fields.heading.value !== '' &&
            item.fields.img.value.src &&
            item.fields.img.value.src.length > 0
          ) {
            bgImage = item.fields.img.value.src;
          } else if (
            item.fields.heading.value === '' &&
            item.fields.body.value === '' &&
            item.fields.img.value.src &&
            item.fields.img.value.src.length > 0
          ) {
            slideImage = item.fields.img.value.src;
          } else if (
            item.fields.img.value.src &&
            item.fields.body.value &&
            item.fields.img.value.src.length > 0
            // && item.fields.body.value.length > 0
          ) {
            // CASO 3
            bodyImage = (
              <div className="slide-section-image">
                <div className="slide-section-image-content">
                  <img src={item.fields.img.value.src} />
                </div>
              </div>
            );
          }
          if (item.fields.bodyTitle.value !== '') {
            // CASO 4
            bodyHeading = (
              <div className="slide-section-heading">
                <div className="slide-section-heading-content">
                  <h4>{item.fields.bodyTitle.value}</h4>
                </div>
                {storiaNav}
              </div>
            );
          }
          if (item.fields.body.value !== '') {
            // CASO 5
            bodyContent = (
              <div className="slide-section-body">
                <p>{item.fields.body.value}</p>
              </div>
            );
          }

          return (
            <div
              key={index}
              data-index={index}
              className="storia-component-outer-item"
              id={item.fields.slideId.value}
            >
              {item.fields.marquee.value !== '' ? (
                <div className="marquee-container">
                  <div className="marquee-content">
                    <p>{item.fields.marquee.value}</p>
                  </div>
                </div>
              ) : (
                ''
              )}
              {item.fields.heading.value !== '' ? (
                <div
                  className="heading-section-container"
                  style={{ backgroundImage: `url(${bgImage})` }}
                >
                  <div className="container-fluid">
                    <div className="heading-section-content">
                      <h2>{item.fields.heading.value}</h2>
                      <h4>{item.fields.subHeading.value}</h4>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="slide-section-container"
                  style={{ backgroundImage: `url(${slideImage})` }}
                >
                  <div className="container-fluid">
                    <div className="slide-section-content">
                      {bodyHeading}
                      {bodyContent}
                      {bodyImage}
                    </div>
                  </div>
                </div>
              )}
              <div className="back-cta-container">
                <div className="back-cta">
                  <BtnPrimary
                    link={props.fields.linkUrl.value.href}
                    label={props.fields.linkLabel.value}
                    icon={'ArrowRight'}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withSitecoreContext()(StoriaComponent);
