import React, { useState, useRef, useEffect } from 'react';
import Label from '../Label/index';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const { isServer } = require('../../util');
import BtnPrimary from '../BtnPrimary/index';
import SecondaryButton from '../SecondaryButton/index';

// const useIsSsr = () => {
//   // we always start off in "SSR mode", to ensure our initial browser render
//   // matches the SSR render
//   const [isSsr, setIsSsr] = useState(true);

//   useEffect(() => {
//     // `useEffect` never runs on the server, so we must be on the client if
//     // we hit this block
//     setIsSsr(false);
//   }, []);

//   return isSsr;
// };

const Comunicato = (props) => {
  // const isSsr = useIsSsr();
  // if (isSsr) return null;
  if (isServer()) {
    // console.log('isServer');
    return <div className="comunicato-content"></div>;
  }
  const getComunicatoUrl = (path) => {
    const urlSegments = path.split('/');
    return urlSegments.slice(0, 4).join('/');
  };

  const comunicato = useRef();
  // console.log('Comunicato', props);
  let labelContainer, comunicatoLink, action, comunicatoParagraph;

  let labelsTabProps,
    linkUrlProps,
    linkLabelProps,
    dateProps,
    actionTypeProps,
    actionUrlProps,
    actionLabelProps,
    titleProps,
    paragraphProps;

  // linkUrlProps ---->>>> DA GENERARE DINAMICAMENTE

  if (props.item.fields !== undefined && props.item.fields !== null) {
    labelsTabProps = props.item.fields.labelsTab;
    titleProps = props.item.fields.title;
    paragraphProps = props.item.fields.paragraph;
    linkUrlProps = props.item.fields.linkUrl
      ? props.item.fields.linkUrl.value
      : props.item.fields.linkUrl;
    linkLabelProps = props.item.fields.linkLabel;
    dateProps =
      props.item.fields.date && props.item.fields.date.value === '0001-01-01T00:00:00Z'
        ? undefined
        : props.item.fields.date;
    actionTypeProps = props.actionType ? props.actionType : props.item.fields.actionType;
    actionUrlProps = props.item.fields.actionUrl;
    actionLabelProps = props.item.fields.actionLabel;
  } else {
    labelsTabProps = props.item.labelsTab;
    titleProps = props.item.title;
    linkUrlProps = props.item.url ? props.item.url.value : props.item.linkUrl;
    linkLabelProps = props.item.linkLabel || 'Scopri di più';
    dateProps = props.item.firstDate ? props.item.firstDate : props.item.date;
    actionTypeProps = props.actionType ? props.actionType : props.item.actionType;
    actionUrlProps = props.item.actionUrl;
    actionLabelProps = props.item.actionLabel || 'Download';
  }

  // console.log('CHECK', props.actionType ? props.actionType : props.item.actionType);
  // console.log('cmnct', {
  //   labelsTabProps,
  //   titleProps,
  //   linkUrlProps,
  //   linkLabelProps,
  //   dateProps,
  //   actionTypeProps,
  //   actionUrlProps,
  //   actionLabelProps,
  // });

  // console.log(
  //   'data',
  //   new Date(dateProps.value) +
  //     '=>' +
  //     new Date(dateProps.value).getDate() +
  //     '/' +
  //     (new Date(dateProps.value).getMonth() + 1) +
  //     '/' +
  //     new Date(dateProps.value).getFullYear()
  // );

  // useEffect(() => {
  //   // console.log('3', comunicato);
  //   // console.log('4', comunicato.current);

  //   // let localRef = null;
  //   // if (comunicato.current) localRef = comunicato.current;
  //   // return () => {
  //   //   console.log('component unmounting', localRef);
  //   // };

  // }, [comunicato]);

  const hoverBackground = (primary) => {
    comunicato.current.classList.add('hover-state');
    if (primary !== 'isPrimary') {
      comunicato.current.classList.add('primary');
    }
  };
  const unhoverBackground = () => {
    comunicato.current.classList.remove('hover-state');
    if (comunicato.current.classList.contains('primary')) {
      comunicato.current.classList.remove('primary');
    }
  };

  // Label Reference
  if (labelsTabProps !== undefined && labelsTabProps !== null) {
    // const labelsTab = Object.keys(labelsTabProps.value).map((key) => {
    //   return labelsTabProps[key];
    // });

    labelContainer = (
      <div className="comunicato-labels-container">
        {labelsTabProps?.value?.map((item, index) => {
          return <Label key={'labelsTab-' + index} label={item.label.value} />;
        })}
      </div>
    );
  }

  // Action content
  if (actionTypeProps) {
    if (actionTypeProps.value === 'download') {
      // console.log('first', actionUrlProps);
      action = (
        <BtnPrimary
          link={actionUrlProps.url}
          label={actionLabelProps.value}
          type={actionTypeProps.value}
        />
      );
    } else {
      // console.log('second');
      action = (
        <SecondaryButton
          link={linkUrlProps}
          linkLabel={linkLabelProps.value}
          type={actionTypeProps.value}
        />
      );
    }
  } else if (actionUrlProps) {
    action = <BtnPrimary link={actionUrlProps.value} label={actionLabelProps.value} />;
  } else {
    action = null;
  }

  return (
    <div
      className="comunicato-content"
      ref={(el) => {
        // console.log(el);
        // console.log('com', comunicato);
        comunicato.current = el;
      }}
    >
      {labelContainer}

      <div className="comunicato-body">
        {/* {actionTypeProps.value === 'download' &&
          linkUrlProps !== undefined &&
          linkUrlProps !== null && (
            <div
              className="comunicato-wrap-link"
              onMouseEnter={hoverBackground}
              onMouseLeave={unhoverBackground}
            >
              <a href={linkUrlProps}></a>
            </div>
          )} */}
        {linkUrlProps !== undefined && linkUrlProps !== null && (
          <div
            className="comunicato-wrap-link"
            onMouseEnter={hoverBackground}
            onMouseLeave={unhoverBackground}
          >
            <a href={linkUrlProps}></a>
          </div>
        )}
        <div className="comunicato-body-content">
          <h4>
            <Text field={titleProps} />
          </h4>
          {paragraphProps && (
            <p>
              <Text field={props.item.fields.paragraph} />
            </p>
          )}
          {actionTypeProps.value === 'download' &&
            linkUrlProps !== undefined &&
            linkUrlProps !== null && (
              <div
                className="comunicato-body-link"
                onMouseEnter={hoverBackground}
                onMouseLeave={unhoverBackground}
              >
                <SecondaryButton link={linkUrlProps} linkLabel={linkLabelProps.value} />
              </div>
            )}
        </div>
        <div
          className="comunicato-body-action my-auto"
          onMouseEnter={(e) => {
            hoverBackground('isPrimary');
          }}
          onMouseLeave={unhoverBackground}
        >
          {action}
        </div>
      </div>
      {dateProps ? (
        <div className={'comunicato-data' + (actionTypeProps.value === 'download' ? '' : ' mt-0')}>
          <p>
            {new Date(dateProps.value).getDate() +
              '/' +
              (new Date(dateProps.value).getMonth() + 1) +
              '/' +
              new Date(dateProps.value).getFullYear()}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default withSitecoreContext()(Comunicato);
