import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import './comunicato-stampa.scss';
import Comunicato from './Comunicato';

const ComunicatiStampa = (props) => {
  let itemsContent;
  if (props.item) {
    const itemsComunicati = Object.keys(props.item).map((key) => {
      return props.item[key];
    });
    itemsContent = itemsComunicati[0];
  } else {
    itemsContent = props;
  }
  return (
    <div className="comunicato-container">
      <Comunicato item={itemsContent} />
    </div>
  );
};

export default withSitecoreContext()(ComunicatiStampa);
