import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import Icon from '../Icon/index';
import ArrowRight from '../../assets/images/icons/arrow-right-blue.svg';
import Download from '../../assets/images/icons/download.svg';

const FilterListContent = (props) => {
  const ListRef = useRef();
  let thisYearList;
  let thisYearContent;
  let itemListClass;
  // console.log('props FilterListContent', props);
  const yearsTab = Object.keys(props.yearList).map((key) => {
    return props.yearList[key];
  });
  // console.log('yeartab', yearsTab);
  if (yearsTab.length > 0) {
    yearsTab.map((item) => {
      if (
        item.fields.year !== undefined &&
        (item.fields.year.value === String(props.year) ||
          item.fields.year.value === String(props.year + 1))
      ) {
        thisYearList = item.fields.yearList;
      }
    });
  }

  if (yearsTab.length > 0) {
    yearsTab.map((item) => {
      if (
        item.fields.year !== undefined &&
        (item.fields.year.value === String(props.year) ||
          item.fields.year.value === String(props.year + 1))
      ) {
        thisYearContent = item.fields.yearContent;
      }
    });
  }
  // console.log('listaref', ListRef);

  let listAlign = 'filter-list-content';
  if (thisYearList && thisYearList.length > 4) {
    listAlign = 'filter-list-content align-left';
  }
  // console.log(thisYearList.length);
  if (thisYearList && thisYearList.length > 4 && thisYearList.length % 2 !== 0) {
    itemListClass = 'filter-list-content align-left';
  } else {
    itemListClass = 'filter-list-content';
  }
  return (
    <div className={itemListClass} ref={ListRef}>
      {thisYearList &&
        thisYearList.map((item, index) => {
          if (thisYearList.length > 4 && ListRef.current !== undefined) {
            ListRef.current.classList.add('align-left');
          } else {
            if (ListRef.current !== undefined) {
              if (ListRef.current.classList.contains('align-left')) {
                ListRef.current.classList.remove('align-left');
              }
            }
          }
          return (
            <div className="filter-list-item" key={index}>
              <div className="filter-list-item-title">
                <h3>{item.fields.title.value}</h3>
              </div>
              <div className="filter-list">
                <ul>
                  {item.fields.links.map((item, index) => {
                    // console.log('subitem', item);
                    if (item.fields.type.value === 'download') {
                      return (
                        <li key={index}>
                          {item.fields.url.value.href !== '' && (
                            <a href={item.fields.url.value.href} target="_blank" rel="noreferrer">
                              {/* <Link to={item.fields.url.value}> */}
                              {item.fields.urlLabel.value}
                              {/* <Icon icon={'Download'} /> */}
                              <img src={Download} alt="download" />
                              {/* </Link> */}
                            </a>
                          )}
                        </li>
                      );
                    } else {
                      return (
                        <li key={index}>
                          {item.fields.url.value.href !== '' && (
                            <a href={item.fields.url.value.href}>
                              {/* <Link to={item.fields.url.value}> */}
                              {item.fields.urlLabel.value}
                              {/* <Icon icon={'ArrowRight'} /> */}
                              <img
                                src={ArrowRight}
                                alt="arrow right"
                                // style={{ width: 10, height: 10, marginLeft: 3 }}
                              />
                              {/* </Link> */}
                            </a>
                          )}
                          {/* <Link to={item.fields.url}>
                          {item.fields.urlLabel.value}
                          <Icon icon={'ArrowRight'} />
                          {/* <img src={ArrowRight} alt="arrow right" />
                        </Link> */}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      {thisYearContent && (
        <div>
          <RichText field={thisYearContent} />
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(FilterListContent);
