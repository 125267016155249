import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect, useRef, useMemo } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import NavigationItem from './NavigationItem';
import SearchBar from '../SearchBar/index';
import { gsap } from 'gsap';
import { useTranslation, withTranslation } from 'react-i18next';
import { ScrollTrigger, ScrollTween } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { isServer } from '../../util';

import Icon from '../Icon/index';

import './navigation.scss';

import logo from '../../assets/images/logo/tinexta-logo.svg';
import logoWhite from '../../assets/images/logo/tinexta-white-logo.svg';

import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { ReactComponent as PlatformIcon } from '../../assets/images/icons/group-menu.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/icons/group-menu.svg';
import { NavLink } from 'react-router-dom';

const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};

let navInterval, currentPageId, currentPage, mainNavigation, activeItem, currentLanguage;

export const navLinkLanguageCheck = () => {
  const isAbsolute = new RegExp('^(?:[a-z+]+:)?//', 'i');
  const pageLinks = document.querySelectorAll('a');
  pageLinks.forEach((item) => {
    if (item.getAttribute('href')) {
      const itemHref = item.getAttribute('href');
      if (isAbsolute.test(itemHref) === false && itemHref !== '/') {
        if (
          !itemHref.startsWith('/en', 0) &&
          !itemHref.startsWith('/it-IT', 0) &&
          !itemHref.startsWith('mailto', 0) &&
          !itemHref.startsWith('tel', 0)
        ) {
          item.setAttribute('href', `/${currentLanguage}${itemHref}`);
          // console.log(item);
        }
      }
    }
  });
};

const chekNavItemLoad = (item) => {
  // console.log(item);
  if (item === null || item === undefined) {
    getNavigationItem();
  } else {
    chekItemUrl();
  }
};

const getNavigationItem = () => {
  setTimeout(() => {
    mainNavigation = document.querySelector('.navbar-container');
    chekNavItemLoad(mainNavigation);
  }, 50);
};

const chekItemUrl = (path) => {
  if (document.body.classList.contains('menu-open')) {
    document.body.classList.remove('menu-open');
  }
  // debugger;
  if (path !== currentPage) {
    if (path === null || path === undefined) {
    } else {
      currentPageId = path.split('/');
      currentPage = currentPageId.pop() || currentPageId.pop();
    }
  } else if (path === undefined && currentPage === undefined) {
    currentPageId = document.body.id;
    currentPage = currentPageId.replace('page-', '');
  }

  editMenuClass(currentPage, mainNavigation);
};

const editMenuClass = (currentPage, mainNavigation) => {
  document.body.classList.remove('no-hero');
  document.body.classList.remove('hero');
  document.body.classList.remove('home');
  // console.log(currentPage);
  switch (currentPage) {
    case 'localhost:3000':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'tinexta.dev.local':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'tinexta.com':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'page-en':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'en':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'page-it-IT':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'it-IT':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case '/':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case '':
      document.body.classList.add('no-hero');
      document.body.classList.add('home');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.remove('hero-navigation');
      break;
    case 'chi-siamo':
      document.body.classList.add('hero');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'governance':
      document.body.classList.add('hero');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'vision-mission':
      document.body.classList.add('hero');
      mainNavigation.classList.remove('no-hero-navigation');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'crescere-in-tinexta':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'business-unit':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'management':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'voci-dal-gruppo':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'business-unit':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'governance':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'open-innovation':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'in-evidenza':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'comunicati-stampa':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'eventi':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'press-media-gallery':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'media-gallery':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'overview':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      document.body.classList.add('investors-overview');
      break;
    case 'la-nostra-strategia':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'titolo-e-numeri-principali':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'calendario-e-dati-finanziari':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'investire-in-tinexta':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'eventi-ir':
      mainNavigation.classList.add('no-hero-navigation');
      break;
    case 'lavorare-in-tinexta':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'corporate-academy':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    case 'opportunita-professionali':
      document.body.classList.add('hero');
      mainNavigation.classList.add('hero-navigation');
      break;
    default:
      mainNavigation.classList.add('no-hero-navigation');
  }
};

const MainNavigation = (props) => {
  if (isServer()) {
    return <></>;
  }
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const { i18n } = useTranslation();
  currentLanguage = i18n.language;
  const selectOption = [
    { value: 'it-IT', label: 'IT' },
    { value: 'en', label: 'EN' },
  ];

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
        chekItemUrl(location.pathname);
        // console.log(location.pathname);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          chekItemUrl(location.pathname);
          // console.log(location.pathname);
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          chekItemUrl(location.pathname);
          // console.log(location.pathname);
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    chekNavItemLoad();
    // console.log('scroll-top');
    gsap.to(window, { duration: 0.2, scrollTo: '0' });
    document.body.classList.remove('show-sub-navigation');
  }, []);

  const isSsr = useIsSsr();
  if (isSsr) return null;

  document.body.classList.remove('tab-stiky');

  let navType = 'navbar-container';
  const NavItems = Object.keys(props.fields.mainNavigationTab).map((key) => {
    return props.fields.mainNavigationTab[key];
  });
  // console.log(NavItems);
  const stickyNav = () => {
    const nav = document.querySelector('.navbar-container');
    const navHeight = nav.offsetHeight;
    const navTop = nav.offsetTop;
    const navItems = document.querySelectorAll('.main-nav-item');
    if (window.scrollY >= navTop + navHeight) {
      document.body.classList.add('fixed-nav');
      document.querySelector('body').classList.remove('menu-open');
      document.querySelector('.main-nav-item').classList.remove('active');
      for (var i = 0; i < navItems.length; i++) {
        if (navItems[i].classList.contains('active')) {
          navItems[i].classList.remove('active');
        }
      }
    } else {
      document.body.classList.remove('fixed-nav');
      if (window.innerWidth > 991) {
        const activeItem = document.querySelector('.active-item');
        activeItem.classList.add('active');
      }
    }
  };
  window.addEventListener('scroll', () => {
    if (document.querySelector('.navbar-container')) {
      stickyNav();
    }
  });

  const showPlatformMenu = (e) => {
    e.preventDefault();
    let hideSearchBar = document
      .querySelector('.right-nav-container')
      .querySelector('.search-bar-container');
    hideSearchBar.classList.remove('show');
    document.body.classList.add('platform-menu-open');
  };
  const handleClick = (e) => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/it-IT' ||
      window.location.pathname === '/en'
    ) {
      e.preventDefault();
    }
  };

  const showPlatformMenuMobile = (e) => {
    e.preventDefault();
    // closeMobileMenu();
    let hideSearchBar = document
      .querySelector('.right-nav-container')
      .querySelector('.search-bar-container');
    hideSearchBar.classList.remove('show');
    document.body.classList.add('platform-menu-open');
  };

  const openMobileMenu = (e) => {
    // console.log('qua');
    e.preventDefault();
    // const platformMobile = document.querySelector('platform-menu-open');
    document.body.classList.remove('platform-menu-open');
    document.body.classList.add('menu-open');
  };

  const closeMobileMenu = () => {
    const navItems = document.querySelectorAll('.main-nav-item');
    const arrNavItem = Object.keys(navItems).map((key) => {
      return navItems[key];
    });

    setTimeout(() => {
      arrNavItem.map((item, index) => {
        if (item.classList.contains('active')) {
          item.classList.remove('active');
        }
      });
      document.body.classList.remove('show-sub-navigation');
    }, 500);

    document.body.classList.remove('menu-open');
  };

  const closePlatformMenu = (e) => {
    e.preventDefault();
    document.body.classList.remove('platform-menu-open');
  };

  const waitForElm = (selector) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  waitForElm('.sub-nav-item').then((elm) => {
    navLinkLanguageCheck();
    const firstItems = document.querySelector('.main-nav-item');
    const mainItems = document.querySelectorAll('.main-nav-item');
    const subNavItems = document.querySelectorAll('.sub-nav-content > .sub-nav-item');
    if (window.innerWidth > 991) {
      // console.log(mainItems);
      // console.log(subNavItems);
      for (var i = 0; i < mainItems.length; i++) {
        mainItems[i].classList.remove('active');
        mainItems[i].classList.remove('active-item');
      }
      for (var i = 0; i < subNavItems.length; i++) {
        if (subNavItems[i].querySelector('a').classList.contains('active')) {
          activeItem = subNavItems[i];
        }
      }
      if (activeItem !== undefined) {
        document.querySelector('body').classList.add('menu-open');
        activeItem.closest('.main-nav-item').classList.add('active');
        activeItem.closest('.main-nav-item').classList.add('active-item');
        activeItem = undefined;
      } else {
        document.querySelector('body').classList.add('menu-open');
        firstItems.classList.add('active');
        firstItems.classList.add('active-item');
        activeItem = undefined;
      }
    }
  });

  return (
    <div className="navbar-container">
      <div className="row align-items-center">
        <div className="col col-sm-3">
          <div className="header-logo-container">
            <NavLink to="/" onClick={handleClick}>
              <img className="main-logo" src={logo} alt="logo" />
              <img className="white-logo" src={logoWhite} alt="logo white" />
            </NavLink>
          </div>
        </div>
        <div className="col col-sm-6">
          <nav>
            <div className="main-nav-container">
              <div className="d-block d-lg-none">
                <SearchBar />
              </div>
              <ul className="main-nav-content">
                {NavItems.map((item, index) => {
                  return (
                    <li key={index} className="main-nav-item">
                      <NavigationItem
                        menuItem={item}
                        menuVoice={item.fields.menuVoice.value}
                        id={index}
                      />
                    </li>
                  );
                })}
                {/* <div className="d-block d-lg-none"> */}
                <li key="lang" className="main-nav-item d-block d-lg-none bottom-nav-item">
                  <div>
                    <ul className="right-nav-switch-language">
                      {selectOption.map((item, index) => {
                        let url = document.location.origin + '/';
                        if (item.value === currentLanguage) {
                          return (
                            <li key={index} className="active">
                              {item.label}
                            </li>
                          );
                        } else {
                          const urlToRedirect = window.location.pathname;
                          const params = window.location.search;
                          const path = window.location.pathname.split('/');
                          if (path.indexOf('en') > 0 || path.indexOf('it-IT') > 0) {
                            path[1] = '';
                            if (path[0].indexOf('' > 0)) {
                              path.splice(0, 1);
                            }
                          }

                          return (
                            <li key={index}>
                              <a href={'/' + item.value + path.join('/') + params}>{item.label}</a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </li>
                <li
                  key="asd"
                  style={{ color: 'white', backgroundColor: '#00204A' }}
                  className="main-nav-item d-block d-lg-none"
                >
                  <a onClick={showPlatformMenuMobile}>
                    Il gruppo
                    <Icon icon={'DropdownOpen'} />
                  </a>
                </li>
                {/* </div> */}
                {/* <div className="right-nav-item d-block d-lg-none">
                  <div className="mobile-menu-icon">
                    <div className="mobile-icon" onClick={showPlatformMenu}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <CloseIcon onClick={closePlatformMenu} className="close-icon" />
                  </div>
                </div> */}
              </ul>
            </div>
          </nav>
        </div>
        <div className="col col-sm-3">
          <div className="right-nav-container">
            <div className="right-nav-item d-none d-lg-block">
              <ul className="right-nav-switch-language">
                {selectOption.map((item, index) => {
                  let url = document.location.origin + '/';
                  if (item.value === currentLanguage) {
                    return (
                      <li key={index} className="active">
                        {item.label}
                      </li>
                    );
                  } else {
                    const urlToRedirect = window.location.pathname;
                    const params = window.location.search;
                    const path = window.location.pathname.split('/');
                    if (path.indexOf('en') > 0 || path.indexOf('it-IT') > 0) {
                      path[1] = '';
                      if (path[0].indexOf('' > 0)) {
                        path.splice(0, 1);
                      }
                    }

                    return (
                      <li key={index}>
                        <a href={'/' + item.value + path.join('/') + params}>{item.label}</a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className="right-nav-item d-none d-lg-block">
              <SearchBar />
            </div>
            <div className="right-nav-item d-none d-lg-block">
              <div className="platform-menu-icon">
                <PlatformIcon onClick={showPlatformMenu} className="open-icon" />
                <CloseIcon onClick={closePlatformMenu} className="close-icon" />
              </div>
            </div>
            <div className="right-nav-item d-block d-lg-none">
              <div className="mobile-menu-icon">
                <div className="mobile-icon" onClick={openMobileMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <CloseIcon onClick={closeMobileMenu} className="close-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(MainNavigation);
