import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';

const TestComponent = (props) => (
  <div>
    <NavLink to="/">Test</NavLink>
    <NavLink to="page">Test</NavLink>
  </div>
);

export default withSitecoreContext()(TestComponent);
