import React from 'react';
import { RichText, withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import BtnPrimary from '../BtnPrimary/index';

import Icon from '../Icon/index';

import './card-lavori.scss';
import { isServer } from '../../util';

const CardLavori = (props) => {
  if (isServer()) {
    return <></>;
  }

  return (
    <div className="lavori-card-container">
      <div className="lavori-card-content">
        <div className="lavori-card-title">
          {props.item.fields ? (
            <h3>{props.item.fields.title.value}</h3>
          ) : (
            <h3>{props.item.title}</h3>
          )}
        </div>
        <div className="lavori-card-subtitle">
          {props.item.fields ? (
            <h4>{props.item.fields.subTitle.value}</h4>
          ) : (
            <h4>
              <RichText field={props.item.subtitles} />
            </h4>
          )}
        </div>
        <div className="lavori-card-location">
          <Icon icon={'Pin'} />
          {props.item.fields ? (
            <p>{props.item.fields.location.value}</p>
          ) : (
            <p>{props.item.location}</p>
          )}
        </div>
        <div className="lavori-card-cta">
          {props.item.fields ? (
            <BtnPrimary
              link={props.item.fields.url.value}
              label={props.item.fields.urlLabel.value}
              icon={'ArrowRight'}
            />
          ) : (
            <BtnPrimary link={props.item.eventLink} label={'Apply Now'} icon={'ArrowRight'} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(CardLavori);
