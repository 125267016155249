import React, { useState } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import './filter-list.scss';
import TabMenuYears from '../TabMenuYears/index';
import FilterListContent from './FilterListContent';
import SecondaryButton from '../SecondaryButton/index';

const FilterList = (props) => {
  // console.log('FilterList', props);
  const ListContent = Object.keys(props.fields.filterListYear).map((key) => {
    return props.fields.filterListYear[key];
  });

  // console.log('ListContent', ListContent);
  let yearsArr = ListContent.map((a) => a.name);
  // console.log('years', aaa);
  // console.log(Math.max(...yearsArr));
  const [years, setYears] = useState(String(Math.max(...yearsArr)));
  // const [years, setYears] = useState('2023');

  const filterYear = (itemYear) => {
    // console.log('itemyear', itemYear);
    setYears(itemYear.thisYear);
  };

  // console.log('ListContent', ListContent);
  // console.log('title', props);
  return (
    // <>
    <div className="filter-list-container ">
      <div className="container-fluid">
        <div className="filter-list-title">
          <h2>{props.fields.title.value}</h2>
        </div>
        {!props.fields.hideTabYears.value && (
          <div className="filter-list-tab-container">
            <TabMenuYears years={ListContent} filterYear={filterYear} />
          </div>
        )}
        {props.fields.filterListYear[0].fields.year !== '1000' ? (
          <FilterListContent year={years} yearList={ListContent} />
        ) : (
          <FilterListContent yearList={ListContent} />
        )}
      </div>
      {props.fields.linkUrl && props.fields.linkUrl.value && (
        <>
          {/* <div className="space-m"></div> */}
          <div className="row justify-content-center">
            <div className="col-md-2">
              <div style={{ textAlign: 'center' }}>
                {props.fields.linkLabel.value !== '' ? (
                  <SecondaryButton
                    link={props.fields.linkUrl.value.href}
                    linkLabel={props.fields.linkLabel.value}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    // </>
  );
};
export default withSitecoreContext()(FilterList);
