import React, { useRef, useState, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { isServer } from '../../util';

// const useIsSsr = () => {
//   // we always start off in "SSR mode", to ensure our initial browser render
//   // matches the SSR render
//   const [isSsr, setIsSsr] = useState(true);

//   useEffect(() => {
//     // `useEffect` never runs on the server, so we must be on the client if
//     // we hit this block
//     setIsSsr(false);
//   }, []);

//   return isSsr;
// };
const TabMenuYears = (props) => {
  const tabEl = useRef(null);

  const [swiperRef, setSwiperRef] = useState(0);
  // const isSsr = useIsSsr();
  // if (isSsr) return null;

  if (isServer()) {
    return <div className="tab-menu-container year-tab"></div>;
  }

  const yearsTab = Object.keys(props.years).map((key) => {
    return props.years[key];
  });
  useEffect(() => {
    // console.log('swiperRef', swiperRef);
    // if (swiperRef) {
    setTimeout(() => {
      let activeCounter = 0;
      let widthCounter = 0;
      let tabItem = tabEl.current.querySelectorAll('.tab-menu-item');
      let tabItemButtons = tabEl.current.querySelectorAll('.tab-menu-item-button');
      const tabItemButtonsActive = Object.keys(tabItemButtons).map((key) => {
        return tabItemButtons[key];
      });
      tabItemButtonsActive.map((item, index) => {
        if (item.classList.contains('active')) {
          activeCounter = 1;
        }
      });

      if (activeCounter === 0) {
        for (let i = 0; i < tabItemButtons.length; i++) {
          if (i === 0) {
            tabItemButtons[i].classList.add('active');
          }
        }
      }
      for (let i = 0; i < yearsTab.length; i++) {
        widthCounter += tabItem[i].offsetWidth;
      }

      if (widthCounter > window.innerWidth - 80) {
        tabEl.current.classList.add('justify-content-start');
      }
    }, 400);
  });
  // }, [swiperRef]);

  // console.log('qua', yearsTab);
  return (
    <div className="tab-menu-container year-tab">
      <div className="tab-menu-content" ref={tabEl}>
        {yearsTab.map((item, index) => {
          let thisYear = item.fields.year.value;
          // console.log(thisYear);
          return (
            <div
              className="tab-menu-item"
              key={index}
              onClick={(e) => {
                // console.log('boh', props.filterYear({ thisYear }));
                props.filterYear({ thisYear });
                const yearTabItem = tabEl.current.querySelectorAll('.tab-menu-item-button');
                // console.log('boh', yearTabItem);
                for (let i = 0; i < yearTabItem.length; i++) {
                  if (yearTabItem[i].classList.contains('active')) {
                    yearTabItem[i].classList.remove('active');
                  }
                }
                e.target.classList.add('active');
              }}
            >
              <button className="tab-menu-item-button">{item.fields.year.value}</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withSitecoreContext()(TabMenuYears);
