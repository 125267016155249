import React, { Fragment, useState } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Spinner from 'react-bootstrap/Spinner';

import Comunicato from '../ComunicatiStampa/Comunicato';
import FilterTab from '../FilterTab/index';
import ListPagination from './ListPagination';

import { isServer } from '../../util';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import { registerLocale } from 'react-datepicker';

const ConnectedDemoQuery = gqlLoader('./query.graphql');
let language = 'it-IT';

let page = 1;
let dtStart = '';
let dtEnd = '';
let selectedCategory = '';
let orderby = 'DESC';
if (typeof window !== 'undefined') {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  page = params.get('page') !== null ? params.get('page') : 1;
  dtStart = params.get('start') !== null ? new Date(params.get('start') + '-1').getTime() : '';
  dtEnd = params.get('end') !== null ? new Date(params.get('end') + '-31').getTime() : '';
  selectedCategory = params.get('c') !== null ? params.get('c') : '';
  orderby = params.get('orderbydesc') !== null ? params.get('orderbydesc') : 'DESC';

  // console.log('HOC orderbydesc', orderby);
  // console.log('dtEnd', dtEnd);
}

let quantity = 0;
let after = 0;

if (!dtStart && !dtEnd && !selectedCategory) {
  quantity = 10;
  after = quantity * (page - 1);
  after = Buffer.from(after.toString()).toString('base64');
}

const ComunicatiListAll = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const noPressReleaseError = {
    'it-IT': 'Nessun Comunicato Stampa',
    en: 'No press release to show',
  };
  // if (isServer()) {
  //   return <></>;
  // }
  const graphQLResult = props.connectedQuery;
  const { error, loading, comunicatiList, categories } = graphQLResult;
  let contentHTML = [];
  let comunicatiFiltered = [];
  if (loading) {
    return (
      <>
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div className="space-l"></div>
      </>
    );
  }
  if (
    !graphQLResult ||
    (!loading &&
      (!comunicatiList || comunicatiList.length === 0) &&
      (!categories || categories.length === 0))
  ) {
    return <div>{noPressReleaseError[language].value}</div>;
  } else {
    if (!loading && !error) {
      let comunicati = comunicatiList.results;
      let arrCategories = [];
      let allCategories = [];
      if (categories.results) {
        categories.results.map((res) => {
          console.log(res.labelsTab.cat);
          return res.labelsTab.cat.map((x) => {
            arrCategories.push(x.name);
          });
        });
        allCategories = [...new Set(arrCategories)];
        // console.log('categories.results', allCategories);
      }

      if (selectedCategory !== '') {
        // console.log('selectedCategory', selectedCategory.split('&'));

        comunicati.map((item) => {
          selectedCategory.split('&').map((cat) => {
            item.labelsTab.value.map((x) => {
              return x.name === cat ? comunicatiFiltered.push(item) : false;
            });
            // if (item.labelsTab.value[0]) {
            //   // console.log('ads', item.labelsTab.value[0].name);
            //   return item.labelsTab.value[0].name === cat
            //     ? true
            //     : item.labelsTab.value[1] && item.labelsTab.value[1].name === cat;
            // }
            // item.labelsTab.value.map((x) => {
            //   console.log('category 2', x.name);
            //   // return x.name === selectedCategory;
            //   return true;
            // });
          });
        });
      } else {
        comunicatiFiltered = comunicati;
      }

      // console.log('POST', comunicati);
      if (dtStart || dtEnd) {
        comunicatiFiltered = comunicatiFiltered.filter((item) => {
          // console.log(item.labelsTab.value[0].name);
          if (!dtEnd) {
            return item.date.value >= dtStart;
          } else if (!dtStart) {
            // console.log('check start', item.date.value + ' - ' + Date.now());
            return item.date.value <= dtEnd;
          } else if (dtStart && dtEnd) {
            return item.date.value >= dtStart && item.date.value <= dtEnd;
          }
        });
      }
      if (props.fields.title && props.fields.title.value !== '') {
        contentHTML.push(
          <div className="comunicati-titles" key="com-title">
            <h2>{props.fields.title.value}</h2>
            {props.fields.subtitle ? <p>{props.fields.subtitle.value}</p> : null}
          </div>
        );
      } else {
        contentHTML.push(
          <FilterTab items={comunicatiFiltered} categories={allCategories} key="filterTab" />
        );
      }

      contentHTML.push(
        comunicatiFiltered.map((item, index) => {
          return <Comunicato item={item} key={index} />;
        })
      );

      if (comunicatiList.total > quantity && !dtStart && !dtEnd && !selectedCategory) {
        let pages = comunicatiList.total / quantity;
        if (comunicatiList.total % quantity > 0) {
          pages++;
        }
        contentHTML.push(
          <div className="row justify-content-center" key="com-pag">
            <div className="col-md-12">
              <div className="text-center pagination-container">
                <ListPagination
                  itemsCount={comunicatiList.total}
                  itemsPerPage={quantity}
                  currentPage={page}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                />
              </div>
            </div>
          </div>
        );
        contentHTML.push(<div className="space-l" key="spx"></div>);
      }
    }
  }

  return <Fragment>{contentHTML}</Fragment>;
};

export default GraphQLData(ConnectedDemoQuery, {
  name: 'connectedQuery',
  options: {
    variables: {
      quantity: quantity,
      after: after,
      language: language,
      order: orderby,
    },
  },
})(ComunicatiListAll);
