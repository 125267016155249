import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Tabs, Tab } from 'react-bootstrap';

const { isServer } = require('../../util');
import './nostri-progetti.scss';
import ProjectsCard from '../ProjectsCard/index';

const NostriProgetti = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div className="projects-container"></div>;
  }
  // console.log('prog', props);
  let projectsContent, projectsTab, label, title;
  if (props.item) {
    projectsContent = Object.keys(props.item).map((key) => {
      return props.item[key];
    });
    label = projectsContent[0].fields.label.value;
    title = projectsContent[0].fields.title.value;

    projectsTab = Object.keys(projectsContent[0].fields.projectTab).map((key) => {
      return projectsContent[0].fields.projectTab[key];
    });
  } else {
    projectsTab = Object.keys(props.fields.projectTab).map((key) => {
      return props.fields.projectTab[key];
    });
    label = props.fields.label.value;
    title = props.fields.title.value;
  }

  return (
    <div className="projects-container">
      <div className="projects-titles">
        <label>{label}</label>
        <h2>{title}</h2>
      </div>
      <div className="project-tab-container">
        <Tabs id="projects-tab" fill defaultActiveKey="0">
          {projectsTab.map((item, index) => {
            return (
              <Tab
                eventKey={index}
                key={index}
                title={item.fields.category.value}
                className={`tab-${item.fields.category.value}`}
              >
                <p>{item.fields.description.value}</p>
                <div className="projects-tab-cards-container">
                  <ProjectsCard
                    items={item.fields.projectCardTab}
                    icon={`Card${item.fields.category.value}`}
                  />
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default withSitecoreContext()(NostriProgetti);
