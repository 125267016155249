import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withSitecoreContext, Text, Placeholder, Image } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
const { isServer } = require('../../util');
import BtnPrimary from '../BtnPrimary/index';
gsap.registerPlugin(ScrollTrigger);

// import Image from '../../assets/images/bg-images/half-page-hero.jpg';

import './half-page-hero-slider.scss';
import HalfPageHeroItem from '../HalfPageHeroItem';

const HalfPageHeroSlider = (props) => {
  let killTimeline, heroSliderAnimation;
  let disabelTrigger, enableTrigger, anchorId, loadScrollTrigger;

  let elContainer, panels;

  if (isServer()) {
    // console.log('isServer');
    // return <div className="half-page-hero-slider-container"></div>;
    return <></>;
  }

  // console.log('NOTisServer');
  const itemRef = useRef(null);

  let heroItems;
  if (props.fields) {
    heroItems = Object.keys(props.fields.halfPageHeroItems).map((key) => {
      return props.fields.halfPageHeroItems[key];
    });
  } else {
    heroItems = [];
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let panels = gsap.utils.toArray('.half-page-hero-item');
        let panelsLength = panels.length;
        if (window.innerWidth > 991) {
          panels.forEach((panel, i) => {
            panel.classList.add(`item-${i}`);
            itemRef.current.style.height = `${(panels.length + 1) * 100}vh`;
            panel.style.transform = `translateY(${i * 100}%)`;
            ScrollTrigger.create({
              start: 'top top',
              end: '100%',
              markers: false,
              pin: '.half-page-hero-slider-content',
              pinSpacing: false,
              trigger: panel,
              onEnter: (self) => {
                panel.style.transform = 'translateY(0)';
                panel.classList.add('item-enter');
                panel.classList.remove('item-leave-back');
                panel.classList.remove('item-leave-back-completed');
                panel.classList.remove('item-leave');
              },
              onLeave: (self) => {
                panel.classList.remove('item-leave-back');
                if (!panel.classList.contains(`item-${panelsLength - 1}`)) {
                  panel.classList.remove('item-leave-back-completed');
                  panel.classList.remove('item-enter');
                  panel.classList.add('item-leave');
                }
              },
              onEnterBack: (self) => {
                panel.classList.remove('item-leave-back-completed');
                panel.classList.remove('item-leave-back');
                panel.classList.remove('item-leave');
                panel.classList.add('item-enter');
              },
              onLeaveBack: (self) => {
                panel.classList.remove('item-leave');
                if (!panel.classList.contains('item-0')) {
                  panel.classList.remove('item-enter');
                  panel.classList.add('item-leave-back');
                  setTimeout(() => {
                    panel.classList.add('leave-back-completed');
                    if (panel.classList.contains('item-0')) {
                      panel.style.transform = `translateY(${i * 100}%)`;
                    }
                  }, 1000);
                }
              },
            });
          });
        }
      }, 600);
    }, itemRef);
    return () => ctx.revert();
  }, []);

  // console.log('HalfPageHeroSlider', props);

  return (
    <>
      {/* DESKTOP */}
      <div className="half-page-hero-slider-container" ref={itemRef}>
        <div className="container-fluid">
          <div className="half-page-hero-slider-content">
            {heroItems.map((item, index) => {
              // console.log('halfhero', item);
              if (item.fields.anchorId) {
                anchorId = item.fields.anchorId.value;
              }
              return <HalfPageHeroItem props={item} key={'HalfPageHeroItem_' + index} />;
            })}
            <Placeholder name="jss-halfPageHeroSlides" rendering={props.rendering} />
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="half-page-hero-slider-container-mobile">
        <div className="container-fluid">
          <div className="half-page-hero-slider-content-mobile">
            {heroItems.map((item, index) => {
              return (
                <div className="half-page-hero-item-mobile" key={'HalfPageHeroItemMobile_' + index}>
                  <div className="row align-items-center">
                    <div className="column col-lg-6 ">
                      <div className="half-page-hero-body">
                        <h3>
                          <Text field={item.fields.title} />
                        </h3>
                        <p>
                          <Text field={item.fields.description} />
                        </p>
                        {item.fields.linkType &&
                        item.fields.linkType.value !== null &&
                        item.fields.linkType.value !== '' ? (
                          <BtnPrimary
                            link={item.fields.linkUrl.value.href}
                            label={item.fields.linkLabel.value}
                            type={item.fields.linkType.value}
                            icon={item.fields.linkType.value}
                          />
                        ) : (
                          <BtnPrimary
                            link={item.fields.linkUrl.value.href}
                            label={item.fields.linkLabel.value}
                            icon={'ArrowRight'}
                          />
                        )}
                      </div>
                    </div>
                    <div className="column col-lg-6">
                      <div
                        className="half-page-hero-image"
                        style={{ backgroundImage: `url(${item.fields.img.value.src})` }}
                      >
                        <img src={item.fields.img.value.src} alt={item.fields.img.value.alt} />
                        {/* <Image field={item.fields.img} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default withSitecoreContext()(HalfPageHeroSlider);

// {item.fields.linkType ? (
//   <BtnPrimary
//     link={item.fields.linkUrl.value}
//     label={item.fields.linkLabel.value}
//     type={'download'}
//     icon={'Download'}
//   />
// ) : (
//   <BtnPrimary
//     link={item.fields.linkUrl.value}
//     label={item.fields.linkLabel.value}
//     icon={'ArrowRight'}
//   />
// )}
