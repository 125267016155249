import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
const { isServer } = require('../../util');

import Image from '../../assets/images/bg-images/bg-welfare.jpg';

import './welfare-wellbeing.scss';

const WelfareWellbeingComponent = (props) => {
  let elContainer, killTimeline;

  if (isServer()) {
    // console.log('isServer');
    return <div className="welfare-wellbeing-container"></div>;
  }
  const itemRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const ctx = gsap.context(() => {
        gsap.to('.welfare-wellbeing-body', { yPercent: 350 });
        let welfareStepTl = gsap.timeline({
          scrollTrigger: {
            trigger: '.welfare-wellbeing-main-content',
            scrub: true,
            start: 'top top',
            end: '+=300%',
            pin: '.welfare-wellbeing-main-content',
            pinSpacer: false,
            onEnter: (self) => {
              if (self.progress > 0.1) {
                document
                  .querySelector('.welfare-wellbeing-main-content')
                  .classList.add('show-gradient');
              }
            },
            onUpdate: (self) => {
              if (self.progress < 0.1) {
                if (
                  document
                    .querySelector('.welfare-wellbeing-main-content')
                    .classList.contains('show-gradient')
                ) {
                  document
                    .querySelector('.welfare-wellbeing-main-content')
                    .classList.remove('show-gradient');
                }
              } else {
                if (
                  !document
                    .querySelector('.welfare-wellbeing-main-content')
                    .classList.contains('show-gradient')
                ) {
                  document
                    .querySelector('.welfare-wellbeing-main-content')
                    .classList.add('show-gradient');
                }
              }
            },
            onLeaveBack: (self) => {
              document
                .querySelector('.welfare-wellbeing-main-content')
                .classList.remove('show-gradient');
              // self.disable();
            },
          },
        });
        welfareStepTl.fromTo('.welfare-wellbeing-body ', { yPercent: 350 }, { yPercent: -50 });
      }, itemRef);
    }, 300);
    return () => ctx.revert();
  }, []);

  return (
    <div className="welfare-wellbeing-container" ref={itemRef}>
      <div
        className="welfare-wellbeing-main-content"
        style={{ backgroundImage: `url(${props.fields.img.value.src})` }}
      >
        <div className="welfare-wellbeing-content">
          <div className="welfare-wellbeing-heading">
            <h2>{props.fields.heading.value}</h2>
          </div>
          <div className="welfare-wellbeing-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                  <h4>{props.fields.bodyTitle.value}</h4>
                  <p>{props.fields.body.value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelfareWellbeingComponent;
