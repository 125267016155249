import React, { Fragment, useEffect, useState } from 'react';
import SubNavigationItem from './SubNavigationItem';
import { useTranslation, withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '../../util';

import Icon from '../Icon/index';

const NavigationItem = (props) => {
  if (isServer()) {
    return <></>;
  }
  const subNavItems = Object.keys(props.menuItem.fields.subNavigationTab).map((key) => {
    return props.menuItem.fields.subNavigationTab[key];
  });
  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;
  const selectOption = [
    { value: 'it-IT', label: 'IT' },
    { value: 'en', label: 'EN' },
  ];

  const openMenu = (e) => {
    // console.log('opeeeeeeeeeeeeeeeeeeeeeeen', e);
    e.preventDefault();
    const itemTarget = e.target;
    const navItem = document.querySelectorAll('.main-nav-item');
    document.body.classList.add('menu-open');
    if (itemTarget.parentElement.classList.contains('active')) {
      document.body.classList.remove('menu-open');
      itemTarget.parentElement.classList.remove('active');
    } else {
      [].forEach.call(navItem, (el) => {
        el.classList.remove('active');
      });
      itemTarget.parentElement.classList.add('active');
      if (window.innerWidth < 991) {
        document.body.classList.add('show-sub-navigation');
      }
    }
  };
  const navigationBack = (e) => {
    e.preventDefault();
    const navItems = document.querySelectorAll('.main-nav-item');
    const arrNavItem = Object.keys(navItems).map((key) => {
      return navItems[key];
    });
    document.body.classList.remove('show-sub-navigation');
    setTimeout(() => {
      arrNavItem.map((item, index) => {
        if (item.classList.contains('active')) {
          item.classList.remove('active');
        }
      });
    }, 500);
  };

  const closeSubNavigation = () => {
    document.body.classList.remove('show-sub-navigation');
  };

  const showPlatformMenuMobile = (e) => {
    e.preventDefault();
    // closeMobileMenu();
    let hideSearchBar = document
      .querySelector('.right-nav-container')
      .querySelector('.search-bar-container');
    hideSearchBar.classList.remove('show');
    document.body.classList.add('platform-menu-open');
  };

  return (
    <Fragment>
      <a
        href="#"
        id={props.id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={openMenu}
      >
        {props.menuVoice}
        <Icon icon={'DropdownOpen'} className="d-block d-lg-none" />
      </a>
      <div className="sub-navigation">
        <ul className="sub-nav-content">
          <li className="d-flex d-lg-none back-tab" onClick={navigationBack}>
            <span className="back-icon">
              <Icon icon={'DropdownOpen'} />
            </span>
            {props.menuVoice}
          </li>
          {subNavItems.map((item, index) => {
            return (
              <li key={index} className="sub-nav-item">
                <SubNavigationItem
                  menuVoice={item.fields.label.value}
                  menuLink={item.fields.menuLink}
                  onClick={closeSubNavigation}
                />
                <Icon icon={'ArrowRight'} />
              </li>
            );
          })}
          <li key="lang" className="sub-nav-item d-block d-lg-none bottom-nav-item">
            <div>
              <ul className="right-nav-switch-language">
                {selectOption.map((item, index) => {
                  let url = document.location.origin + '/';
                  if (item.value === currentLanguage) {
                    return (
                      <li key={index} className="active">
                        {item.label}
                      </li>
                    );
                  } else {
                    const urlToRedirect = window.location.pathname;
                    const params = window.location.search;
                    const path = window.location.pathname.split('/');
                    if (path.indexOf('en') > 0 || path.indexOf('it-IT') > 0) {
                      path[1] = '';
                      if (path[0].indexOf('' > 0)) {
                        path.splice(0, 1);
                      }
                    }

                    return (
                      <li key={index}>
                        <a href={'/' + item.value + path.join('/') + params}>{item.label}</a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </li>
          <li
            key="asd"
            style={{ color: 'white', backgroundColor: '#00204A' }}
            className="sub-nav-item d-block d-lg-none"
          >
            <a onClick={showPlatformMenuMobile}>
              Il gruppo
              <Icon icon={'DropdownOpen'} />
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default withSitecoreContext()(NavigationItem);
