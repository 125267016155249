import React, { useRef, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import SecondaryButton from '../SecondaryButton/index';

import './checkbox-hidden-component.scss';
import { isServer } from '../../util';

const ChecboxHiddenComponent = (props) => {
  // if (isServer) {
  //   return <div class="checkbox-content-container space-xs"></div>;
  // }
  useEffect(() => {
    // return () => {
    // debugger;
    checkboxCheck();
    // };
  }, []);

  const checkContainer = useRef(null);
  const hiddenFieldsContainer = useRef(null);
  let checked = false;
  const checkboxCheck = () => {
    let thisCheckbox = checkContainer.current;
    let checkboxItem = thisCheckbox.querySelectorAll('.checkbox-input');
    checked = false;
    for (let i = 0; i < checkboxItem.length; i++) {
      if (checkboxItem[i].checked) {
        checked = true;
      } else {
        checked = false;
        i = checkboxItem.length;
      }
    }

    if (checked === true) {
      showHiddenFields();
    } else {
      hideHiddenFields();
    }
  };

  const showHiddenFields = () => {
    let thisHiddenFileds = hiddenFieldsContainer.current;
    let hiddenContent = thisHiddenFileds.querySelector('.checkbox-hidden-content');
    let itemHeight = thisHiddenFileds.querySelector('.checkbox-hidden-item-container').offsetHeight;
    thisHiddenFileds.classList.add('show-hidden-fields');
    gsap.to(hiddenContent, {
      minHeight: itemHeight,
      duration: 0.5,
    });
  };

  const hideHiddenFields = () => {
    let thisHiddenFileds = hiddenFieldsContainer.current;
    let hiddenContent = thisHiddenFileds.querySelector('.checkbox-hidden-content');
    let itemHeight = thisHiddenFileds.querySelector('.checkbox-hidden-item-container').offsetHeight;
    thisHiddenFileds.classList.remove('show-hidden-fields');
    gsap.to(hiddenContent, {
      minHeight: 0,
      duration: 0.5,
    });
  };

  const checkList = Object.keys(props.fields.checkTab).map((key) => {
    return props.fields.checkTab[key];
  });

  // console.log(checkList);

  return (
    <div className="checkbox-content-container space-xs">
      <div className="container-fluid">
        <div className="checkbox-content" ref={checkContainer}>
          {checkList.map((item, index) => {
            return (
              <div className="checkbox-wrapper" key={index}>
                <label>
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    onClick={() => {
                      checkboxCheck();
                    }}
                  />
                  <span className="checkmark"></span>
                  <span>{item.fields.body.value}</span>
                </label>
              </div>
            );
          })}
        </div>
        <div className="checkbox-hidden-container" ref={hiddenFieldsContainer}>
          <div className="checkbox-hidden-content">
            <div className="checkbox-hidden-item-container">
              <div className="checkbox-hidden-item">
                <RichText field={props.fields.hiddenContent} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(ChecboxHiddenComponent);
