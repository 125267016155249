import React, { useEffect, useRef, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Accordion, Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Icon from '../Icon/index';
import Plus from '../../assets/images/icons/plus.svg';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './base-accordion.scss';
import { isServer } from '../../util';

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => handleClick());

  return (
    <button type="button" style={{ backgroundColor: 'transparent' }} onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

const BaseAccordion = (props) => {
  if (isServer()) {
    return <></>;
  }

  const [activeKey, setActiveKey] = useState(0);
  const [loadElem, setLoadElem] = useState(true);

  let year = 0;
  // if (typeof window !== 'undefined') {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  year = params.get('year') !== null ? params.get('year') : 0;
  // }

  // console.log('baseAccordion', props);
  const yearsTab = Object.keys(props.fields.accordionTab).map((key) => {
    return props.fields.accordionTab[key];
  });

  // useEffect(() => {
  // if (year > 0) {
  //   var index = yearsTab.findIndex((i) => {
  //     return i.name === '2020';
  //   });

  //   if (index >= 0) {
  //     setActiveKey('2020');
  //   }
  // }
  // });

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  waitForElm('.accordion-collapse').then((elm) => {
    if (loadElem) {
      const activeItem = document.querySelector('.accordion-collapse.show');
      const parentItem = activeItem.closest('.card');
      const buttonItem = parentItem.querySelector('.card-header button');
      buttonItem.classList.add('collapsed');
      setLoadElem(false);

      if (year > 0) {
        var index = yearsTab.findIndex((i) => {
          return i.name === year;
        });

        if (index >= 0) {
          setActiveKey(index);
        }
      }
    }
  });

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="accordion-container">
            {/* <Accordion>
              {yearsTab.map((item, index) => {
                return (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>
                      {item.fields.title.value}
                      <div className="circle-icon">
                        <img src={Plus} alt={item.fields.title.value} />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <RichText
                        field={item.fields.content}
                        tag="section"
                        editable={false}
                        className="text-center"
                        data-sample="other-attributes-pass-through"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion> */}
            <Accordion defaultActiveKey={0} activeKey={activeKey}>
              {yearsTab.map((item, index) => {
                return (
                  <Card key={index}>
                    <Card.Header>
                      <CustomToggle
                        eventKey={index}
                        handleClick={(e) => {
                          if (activeKey === index) {
                            setActiveKey(null);
                          } else {
                            setActiveKey(index);
                          }
                          setTimeout(() => {
                            const allButtonElem = document.querySelectorAll('.card-header button');
                            if (document.querySelector('.accordion-collapse.show')) {
                              const activeItem = document.querySelector('.accordion-collapse.show');
                              const parentItem = activeItem.closest('.card');
                              const buttonItem = parentItem.querySelector('.card-header button');
                              allButtonElem.forEach((item) => {
                                item.classList.remove('collapsed');
                              });
                              buttonItem.classList.add('collapsed');
                            } else {
                              allButtonElem.forEach((item) => {
                                item.classList.remove('collapsed');
                              });
                            }
                          }, 100);
                        }}
                      >
                        {item.fields.title.value}
                        <div className="circle-icon">
                          <img src={Plus} alt={item.fields.title.value} />
                        </div>
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body>
                        <RichText
                          field={item.fields.content}
                          tag="section"
                          editable={false}
                          className="text-center"
                          data-sample="other-attributes-pass-through"
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(BaseAccordion);
