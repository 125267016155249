import React from 'react';
import { withSitecoreContext, Text, Placeholderxt } from '@sitecore-jss/sitecore-jss-react';
import IconTabItems from '../IconTabItems/index';

import './icon-tab-content.scss';

const IconTabContent = (props) => {
  let iconTab;
  if (props.item) {
    iconTab = Object.keys(props.item).map((key) => {
      return props.item[key];
    });
  } else {
    iconTab = Object.keys(props.fields.iconTab).map((key) => {
      return props.fields.iconTab[key];
    });
  }

  return (
    <div className="container-fluid space-l">
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div className="icon-tab-content-container">
            <IconTabItems items={iconTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(IconTabContent);
