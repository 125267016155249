import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import BtnPrimary from '../BtnPrimary';

const HomepageSliderDemo = (props) => {
  // console.log(props);
  return (
    <div className="homepage-slider-container">
      <div className="homepage-slider-image">
        <img className="d-none d-lg-block" src={props.fields.img.value.src} />
        <img className="d-block d-lg-none" src={props.fields.imgMobile.value.src} />
      </div>
      <div className="homepage-slider-content">
        <div className="homepage-slider-category">
          <label>comunicati stampa</label>
        </div>
        <h1>{props.fields.heading.value}</h1>
        <div className="cta-container">
          <BtnPrimary link={props.fields.linkUrl.value} label={props.fields.linkLabel.value} />
        </div>
      </div>
    </div>
  );
};

export default HomepageSliderDemo;
