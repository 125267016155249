import React from 'react';
import { isServer } from '../../util';
import FotoListAll from './FotoListAll';
import ComunicatiListLast3 from './comunicatiListLast3';
import ComunicatiListCustom from './comunicatiListCustom';
import Icon from '../Icon/index';
import SecondaryButton from '../SecondaryButton/index';

const FotoList = (props) => {
  if (isServer()) {
    return <div className="container-fluid comunicati-list-container"></div>;
  }

  const graphQLResult = props.connectedQuery;

  // let content;

  // content = <FotoListAll fields={props.fields} />;

  return (
    <div className="container-fluid comunicati-list-container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="comunicato-container space-m" id="fotoItem">
            <FotoListAll fields={props.fields} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="cta-container text-center">
              {/* {props.fields?.linkLabel.value !== '' ? (
                <SecondaryButton
                  link={props.fields.urlAllComunicati.value.href}
                  linkLabel={props.fields.linkLabel.value}
                />
              ) : (
                ''
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FotoList;
