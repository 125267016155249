import React from 'react';
import { CalendarContainer } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const CustomCalendarContainer = (props) => {
  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;
  const labelPulisci = { en: 'Clear', 'it-IT': 'Pulisci' };
  //   console.log('props', props);
  // console.log('pulisci', labelPulisci[currentLanguage]);
  return (
    <div>
      <CalendarContainer className={props.className}>
        {props.children}
        {/* <div className="filter-tab-dropdown"> */}
        <div className="button-section">
          <button className="btn-pulisci" onClick={(e) => props.onClear(props.period)}>
            {labelPulisci[currentLanguage]}
          </button>
        </div>
        {/* </div> */}
        {/* <button className="btn-pulisci">Pulisci</button> */}
      </CalendarContainer>
    </div>
  );
};

export default CustomCalendarContainer;
