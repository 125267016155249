import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Button } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

import Icon from '../Icon/index';
import { isServer } from '../../util';

import './secondary-button.scss';

const SecondaryButton = (props) => {
  if (isServer()) {
    return <></>;
  }
  // console.log(props);
  let buttonClass, link, label, linkType, icon;
  if (props.fields) {
    link = props.fields.link.value;
    label = props.fields.linkLabel.value;
    linkType = props.fields.linkType.value;
    icon = props.fields.icon.value;
    if (props.fields.center !== undefined && props.fields.center !== null) {
      buttonClass = 'custom-secondary-button centered';
    }
  } else {
    link = props.link;
    label = props.linkLabel;
    linkType = props.linkType;
    icon = props.icon;
    if (props.white !== undefined && props.white !== null) {
      buttonClass = 'custom-secondary-button white';
    } else {
      buttonClass = 'custom-secondary-button';
    }
    if (linkType === 'media') {
      icon = 'Download';
    }
  }

  // console.log('SecondaryButton', props);
  return (
    <div className={buttonClass}>
      {props.link && props.icon !== 'Download' && linkType !== 'media' && (
        // <NavLink to={link}>
        //   {label}
        //   <Icon icon={props.icon} />
        // </NavLink>
        <a href={link}>
          {label}
          <Icon icon={props.icon} />
        </a>
      )}
      {!props.link && props.icon !== 'Download' && linkType !== 'media' && (
        <Button>
          {label}
          <Icon icon={props.icon} />
        </Button>
      )}
      {((props.link && props.icon === 'Download') || linkType === 'media') && (
        <a href={props.link} target="_blank" rel="noreferrer">
          {label}
          <Icon icon={icon} />
        </a>
      )}
    </div>
  );
};

export default withSitecoreContext()(SecondaryButton);
