import React, { useRef, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder, Image } from '@sitecore-jss/sitecore-jss-react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
const { isServer } = require('../../util');
import BtnPrimary from '../BtnPrimary/index';
gsap.registerPlugin(ScrollTrigger);

// import Image from '../../assets/images/bg-images/half-page-hero.jpg';

import './half-page-hero-item.scss';

const HalfPageHeroItem = (props) => {
  if (isServer()) {
    return <></>;
  }
  // console.log(props);
  return (
    <div className="half-page-hero-item" id={props.props.fields.anchorId.value}>
      <div className="row align-items-center">
        <div className="column col-lg-6 ">
          <div className="half-page-hero-body">
            <h3>
              <Text field={props.props.fields.title} />
            </h3>
            <p>
              <Text field={props.props.fields.description} />
            </p>
            {props.props.fields.linkType &&
            props.props.fields.linkType.value !== null &&
            props.props.fields.linkType.value !== '' ? (
              <BtnPrimary
                link={props.props.fields.linkUrl.value.href}
                label={props.props.fields.linkLabel.value}
                type={props.props.fields.linkType.value}
                icon={props.props.fields.linkType.value}
              />
            ) : (
              <BtnPrimary
                link={props.props.fields.linkUrl.value.href}
                label={props.props.fields.linkLabel.value}
                icon={'ArrowRight'}
              />
            )}
          </div>
        </div>
        <div className="column col-lg-6">
          <div
            className="half-page-hero-image"
            style={{ backgroundImage: `url(${props.props.fields.img.value.src})` }}
          >
            {/* <img src={props.fields.img.value.src} alt={props.fields.img.value.alt} /> */}
            <Image field={props.props.fields.img} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(HalfPageHeroItem);

// {props.fields.linkType ? (
//   <BtnPrimary
//     link={props.fields.linkUrl.value}
//     label={props.fields.linkLabel.value}
//     type={'download'}
//     icon={'Download'}
//   />
// ) : (
//   <BtnPrimary
//     link={props.fields.linkUrl.value}
//     label={props.fields.linkLabel.value}
//     icon={'ArrowRight'}
//   />
// )}
