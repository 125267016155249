import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import SecondaryButton from '../SecondaryButton';

import './corporate-academy.scss';

const CorporateAcademyComponent = (props) => {
  return (
    <div className="corporate-academy-container">
      <div className="container-fluid">
        <div className="corporate-academy-content">
          <div
            className="corporate-academy-image"
            style={{ backgroundImage: `url(${props.fields.img.value.src})` }}
          ></div>
          <div className="corporate-academy-body">
            <h2>{props.fields.heading.value}</h2>
            <p>{props.fields.body.value}</p>
            <SecondaryButton
              link={props.fields.linkUrl.value}
              linkLabel={props.fields.linkLabel.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateAcademyComponent;
