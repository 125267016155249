import React from 'react';
import { isServer } from '../../util';
import ComunicatiListAll from './comunicatiListAll';
import ComunicatiListLast3 from './comunicatiListLast3';
import ComunicatiListCustom from './comunicatiListCustom';
import Icon from '../Icon/index';
import SecondaryButton from '../SecondaryButton/index';

const ComunicatiList = (props) => {
  if (isServer()) {
    return <div className="container-fluid comunicati-list-container"></div>;
  }

  const graphQLResult = props.connectedQuery;

  let content;
  // const content =
  //   props.fields && props.fields.onlyLastThree && props.fields.onlyLastThree.value ? (
  //     <ComunicatiListLast3 fields={props.fields} />
  //   ) : (
  //     <ComunicatiListAll fields={props.fields} />
  //   );

  if (props.fields && props.fields.onlyLastThree && props.fields.onlyLastThree.value) {
    content = <ComunicatiListLast3 fields={props.fields} />;
  } else {
    if (props.fields.customSelection && props.fields.customSelection.length > 0) {
      content = <ComunicatiListCustom fields={props.fields.customSelection} />;
    } else {
      content = <ComunicatiListAll fields={props.fields} />;
    }
  }

  // console.log('ComunicatiList', props);
  return (
    <div className="container-fluid comunicati-list-container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="comunicato-container space-m">
            {/* {content} */}
            {props.fields && props.fields.onlyLastThree && props.fields.onlyLastThree.value ? (
              <ComunicatiListLast3 fields={props.fields} />
            ) : props.fields.customSelection && props.fields.customSelection.length > 0 ? (
              <ComunicatiListCustom fields={props.fields.customSelection} />
            ) : (
              <ComunicatiListAll fields={props.fields} />
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="cta-container text-center">
              {props.fields.linkLabel.value !== '' ? (
                <SecondaryButton
                  link={props.fields.urlAllComunicati.value.href}
                  linkLabel={props.fields.linkLabel.value}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ComunicatiList;
