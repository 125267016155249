import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Pagination, Navigation } from 'swiper';

import Icon from '../Icon/index';
const { isServer } = require('../../util');

import './project-cards.scss';

const ProjectsCard = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div className="project-card-item"></div>;
  }
  // console.log('progetti', props);
  const [swiperRef, setSwiperRef] = useState(null);
  let slidesView;

  if (window.innerWidth < 543) {
    slidesView = 1;
  } else if (window.innerWidth > 542 && window.innerWidth < 991) {
    slidesView = 1.5;
  } else {
    slidesView = 2.5;
  }

  return (
    <Swiper
      onSwiper={setSwiperRef}
      slidesPerView={slidesView}
      freeMode={true}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="progects-cards"
      spaceBetween={24}
    >
      {props.items
        ? props.items.map((item, index) => {
            // console.log('item card', item);
            // console.log(props.icon);
            return (
              <SwiperSlide key={index}>
                <div className="project-card-item">
                  <div className="project-card-image">
                    <Icon icon={`${props.icon}`} />
                    {/* <Icon icon={item.fields.icon.value} /> */}
                  </div>
                  <div className="project-card-body">
                    <label>{item.fields.label.value}</label>
                    <h3>{item.fields.title.value}</h3>
                    <p>{item.fields.description.value}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        : null}
    </Swiper>
  );
};

export default withSitecoreContext()(ProjectsCard);
