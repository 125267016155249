import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';

import './gradient-banner.scss';

const GradientBannerComponent = (props) => {
  return (
    <div className="gradient-banner-container">
      <div className="gradient-banner-content">
        <h3>{props.fields.heading.value}</h3>
        <div className="gradient-banner-people">
          <div className="gradient-banner-people-image">
            <img src={props.fields.img.value.src} alt={props.fields.img.value.alt} />
          </div>
          <div className="gradient-banner-people-name">
            <p>
              {props.fields.name.value}
              <span>{props.fields.role.value}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(GradientBannerComponent);
