import React, { useState } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import Image from '../../assets/images/temp/people-01.jpg';
import CarouselImage from '../../assets/images/temp/carousel-image-people.jpg';

const PeopleSliderPage = (props) => {
  const [swiperRef, setSwiperRef] = useState(null);

  const peopleTab = Object.keys(props.fields.peopleTab).map((key) => {
    return props.fields.peopleTab[key];
  });

  return (
    <div className="people-slider-container">
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        freeMode={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="people-slider"
      >
        {peopleTab.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="people-slider-inner-content">
                <div className="people-slider-image">
                  <img src={CarouselImage} alt="carousel image" />
                </div>
                <div className="people-slider-inner-body">
                  <div className="people-slider-body-content">
                    <h4>{item.fields.name.value}</h4>
                    <h3>{item.fields.role.value}</h3>
                    <p>{item.fields.description.value}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default withSitecoreContext()(PeopleSliderPage);
