import React, { useRef, useState, useEffect } from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import BtnPrimary from '../BtnPrimary/index';
import SecondaryButton from '../SecondaryButton';

// import Image from '../../assets/images/bg-images/half-page-hero.jpg';

import './half-page-hero.scss';
import IconTabItems from '../IconTabItems/index';
import { isServer } from '../../util';

const HalfPageHero = (props) => {
  const heroRef = useRef();
  if (isServer()) {
    return <div className="half-page-hero-main-container no-slider container-fluid"></div>;
  }
  // console.log('halfhero', props);
  const heroItems = Object.keys(props.fields.halfPageHeroItems).map((key) => {
    return props.fields.halfPageHeroItems[key];
  });

  let bodyClass, label, rowClass, sideContent, iconTabClass, containerClass, anchorId;

  const calculateBodyTop = () => {
    if (heroRef.current !== undefined) {
      let itemBody = heroRef.current.querySelector('.half-page-hero-body');
      let itemBodyHeight = itemBody.offsetHeight;
      let halfItemBodyHeight = itemBodyHeight / 2;
      itemBody.style.top = `calc(50% - ${halfItemBodyHeight}px)`;
    }
  };

  useEffect(() => {
    calculateBodyTop();
  }, []);

  return (
    <div className="half-page-hero-main-container no-slider container-fluid">
      {heroItems.map((item, index) => {
        // console.log(item);
        // console.log(item.fields.secondaryLinkUrl);
        if (item.fields.label !== undefined && item.fields.img !== null) {
          label = <label>{item.fields.label.value}</label>;
        }

        if (
          item.fields.iconTab !== undefined &&
          item.fields.iconTab !== null &&
          item.fields.iconTab.length > 0 &&
          item.fields.iconTabSection.length === 0
        ) {
          bodyClass = 'half-page-hero-body sticky';
          containerClass = 'half-page-hero-container space-l both';
          iconTabClass = 'col-lg-6';
          rowClass = 'row';
          sideContent = (
            <div className="icon-tab-container">
              <IconTabItems items={item.fields.iconTab} />
            </div>
          );
        } else if (
          item.fields.iconTabSection !== undefined &&
          item.fields.iconTabSection !== null &&
          item.fields.iconTabSection.length > 0 &&
          item.fields.iconTab.length === 0
        ) {
          bodyClass = 'half-page-hero-body';
          containerClass = 'half-page-hero-container obiettivi';
          iconTabClass = 'col-12';
          rowClass = 'row';
          sideContent = (
            <div className="icon-tab-container obiettivi">
              <IconTabItems items={item.fields.iconTabSection} />
            </div>
          );
        } else {
          bodyClass = 'half-page-hero-body';
          containerClass = 'half-page-hero-container';
          iconTabClass = 'col-lg-6';
          rowClass = 'row align-items-center';
          sideContent = (
            <div
              className="half-page-hero-image"
              style={{ backgroundImage: `url(${item.fields.img.value.src})` }}
            >
              <img src={item.fields.img.value.src} alt={item.fields.img.value.alt} />
            </div>
          );
        }

        if (item.fields.anchorId) {
          anchorId = item.fields.anchorId.value;
        }
        // console.log(item);
        return (
          <div className={containerClass} id={anchorId} key={index} ref={heroRef}>
            <div className={rowClass}>
              <div className="col-lg-6">
                <div className={bodyClass}>
                  {label}
                  <h3>
                    <Text field={item.fields.title} />
                  </h3>
                  <p>
                    <Text field={item.fields.description} />
                  </p>
                  {item.fields.linkLabel.value !== '' ? (
                    item.fields.linkType.value !== '' ? (
                      <BtnPrimary
                        link={item.fields.linkUrl.value.href}
                        label={item.fields.linkLabel.value}
                        type={'download'}
                        icon={'Download'}
                      />
                    ) : (
                      <BtnPrimary
                        link={item.fields.linkUrl.value.href}
                        label={item.fields.linkLabel.value}
                        icon={'ArrowRight'}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {item.fields.secondaryLinkLabel.value !== '' ? (
                    <SecondaryButton
                      link={item.fields.secondaryLinkUrl.value}
                      linkLabel={item.fields.secondaryLinkLabel.value}
                      icon={'Download'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className={iconTabClass}>{sideContent}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withSitecoreContext()(HalfPageHero);
