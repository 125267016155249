import React from 'react';
import { withSitecoreContext, Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const HtmlBlock = ({ props }) => {
  // console.log('aaaaa', props);
  return <div></div>;
  // return (
  //   <>
  //     <div className="contentBlock">
  //       <div className="contentDescription">{props.fields.content}</div>
  //     </div>
  //   </>
  // );
};

export default withSitecoreContext()(HtmlBlock);
