import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';

const Robots = (props) => {
  // console.log('ssd');

  return (
    <pre style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap;' }}>
      <Text field={props.sitecoreContext.route.fields.content} />
    </pre>
  );
};

export default withSitecoreContext()(Robots);
