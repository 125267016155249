import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useTranslation } from 'react-i18next';

import './dot-list.scss';

const DotListComponent = (props) => {
  // console.log('Dot', props);
  const listItems = Object.keys(props.fields.items).map((key) => {
    return props.fields.items[key];
  });

  let listContent;

  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;

  let url =
    currentLanguage === 'en'
      ? 'https://syndication.teleborsa.it/tinexta/en/home.html'
      : 'https://syndication.teleborsa.it/tinexta/it/home.html';

  return (
    <div className="dot-list-container">
      {listItems.map((item, index) => {
        return (
          <div className="dot-list-item" key={index}>
            {item.fields.type.value.toLowerCase() === 'numbers' ? (
              <iframe src={url}></iframe>
            ) : item.type === undefined || item.type === '' ? (
              <ul>
                <li>{item.fields.body.value}</li>
              </ul>
            ) : (
              ''
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DotListComponent;
