import React from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';

const ConnectedDemoQuery = gqlLoader('../EventiList/query.graphql');

import CardEvento from '../CardEvento/index';
import BasicCard from '../BasicCard/index';
import CardLavori from '../CardLavori/index';
import CardSlider from '../CardSlider/index';

import './cards.scss';
import { isServer } from '../../util';

const CardsContainer = (props) => {
  // if (isServer()) {
  //   console.log('isServer props', props);
  //   if (
  //     (props.fields.cardsEvento && props.fields.cardsEvento.length > 0) ||
  //     (props.fields.cardsTab && props.fields.cardsTab.length > 0) ||
  //     (props.fields.cardsLavori && props.fields.cardsLavori.length > 0)
  //   ) {
  //     return <div className="cards-container"></div>;
  //   } else {
  //     return <></>;
  //   }
  // }

  let cardsEvento, cardsTab, cardsLavori;
  const graphQLResult = props.connectedQuery;

  if (
    !graphQLResult ||
    (!graphQLResult.loading && (!graphQLResult.eventsList || graphQLResult.eventsList.length === 0))
  ) {
    return <></>;
  } else {
    const { error, loading, eventsList } = graphQLResult;

    if (props.fields.cardsEventoTab) {
      // if (props.fields.cardsEventoTab && props.fields.cardsEventoTab.length > 2) {
      cardsEvento = Object.keys(props.fields.cardsEventoTab).map((key) => {
        return props.fields.cardsEventoTab[key];
      });
    } else if (!loading && eventsList.total > 0) {
      const now = Date.now();
      cardsEvento = eventsList.results.filter(
        (x) => x.firstDate.value >= now || x.secondDate.value >= now
      );
    }

    if (!cardsEvento || cardsEvento.length === 0) {
      return <></>;
    }

    if (props.fields.cardsTab) {
      cardsTab = Object.keys(props.fields.cardsTab).map((key) => {
        return props.fields.cardsTab[key];
      });
    }
    if (props.fields.cardsLavori) {
      cardsLavori = Object.keys(props.fields.cardsLavori).map((key) => {
        return props.fields.cardsLavori[key];
      });
    }

    // console.log('cards', props);

    return (
      <div className="cards-container">
        {props.fields.title && props.fields.title.value !== '' ? (
          <div className="cards-container-title">
            <h3>{props.fields.title.value}</h3>
          </div>
        ) : null}
        {cardsEvento ? (
          <div id="cards-evento" className="container-fluid space-m">
            <div className="row justify-content-center justify-content-lg-start">
              {cardsEvento.map((evento, index) => {
                return (
                  <div className="col-sm-5 col-lg-3" key={index}>
                    <CardEvento item={evento} />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {props.fields.cardsTab ? (
          <div id="cards-base" className="container-fluid space-m">
            <div className="row align-items-center">
              {cardsTab.map((item, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <BasicCard item={item} />
                  </div>
                );
              })}
              {cardsLavori.map((lavoro, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <CardLavori item={lavoro} />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {/* {props.fields.cardsLavori ? (
        <div id="cards-slider" className="container-fluid space-m">
          <div className="row">
            <div className="col-md-5">
              <CardSlider item={props.fields.cardsSlider} />
            </div>
          </div>
        </div>
      ) : null} */}
      </div>
    );
  }
};

// export default withSitecoreContext()(CardsContainer);
export default GraphQLData(ConnectedDemoQuery, { name: 'connectedQuery' })(CardsContainer);
