import React from 'react';
import { withSitecoreContext, Text, Placeholder, Link } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';

import './preFooter.scss';

const PreFooter = (props) => {
  const preFooterItems = Object.keys(props.fields.preFooterItems).map((key) => {
    return props.fields.preFooterItems[key];
  });

  return (
    <div className="pre-footer-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            <div className="pre-footer-title">
              <h2>{props.fields.title.value}</h2>
            </div>
          </div>
          <div className="col-md-7">
            <div className="pre-footer-content">
              {preFooterItems.map((item, index) => {
                if (item.fields.preFooterLinks !== undefined) {
                  const preFooterLinks = Object.keys(item.fields.preFooterLinks).map((key) => {
                    return item.fields.preFooterLinks[key];
                  });
                  // console.log('pf', item);
                  return (
                    <div key={index} className="pre-footer-item">
                      <div className="pre-footer-item-title">
                        <h4>{item.fields.title.value}</h4>
                      </div>
                      <div className="pre-footer-item-body">
                        <p>{item.fields.content.value}</p>
                        {preFooterLinks.map((item, index) => (
                          <a
                            key={index}
                            href={item.fields.linkUrl.value.url}
                            target={item.fields.linkUrl.value.target}
                          >
                            {item.fields.linkLabel.value}
                          </a>
                        ))}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className="pre-footer-item">
                      <div className="pre-footer-item-title">
                        <h4>{item.fields.title.value}</h4>
                      </div>
                      <div className="pre-footer-item-body">
                        <p>{item.fields.content.value}</p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(PreFooter);
