import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
gsap.registerPlugin(ScrollTrigger);
import { isServer } from '../../util';

import './vision-mission.scss';
import { findBreakingChanges } from 'graphql';

const VisonMissionComponent = (props) => {
  if (isServer()) {
    return <div className="vision-mission-container"></div>;
  }
  const itemRef = useRef();
  let visionPrValue, missionPrValue, topValue;

  useEffect(() => {
    if (window.innerWidth > 1099) {
      topValue = 'top 48px';
      missionPrValue = 0.8;
      visionPrValue = 0.5;
    } else if (window.innerWidth > 1099) {
      topValue = 'top 60px';
      missionPrValue = 0.4;
      visionPrValue = 0.2;
    } else {
      topValue = 'top 60px';
      missionPrValue = 0.5;
      visionPrValue = 0.3;
    }
    // const ctx = gsap.context(() => {
    //   let maxItemHeight = 0;
    //   let minItemHeight = 0;
    //   let visionItems = document.querySelectorAll('.vision-mission-item');
    //   visionItems.forEach((item, index) => {
    //     let thisHeight = item.offsetHeight;
    //     if (thisHeight > maxItemHeight) {
    //       if (maxItemHeight > 0) {
    //         minItemHeight = maxItemHeight;
    //         maxItemHeight = thisHeight;
    //       } else {
    //         maxItemHeight = thisHeight;
    //       }
    //     }
    //   });
    //   itemRef.current.querySelector('.vision-mission-content').style.height = `${maxItemHeight}px`;
    //   gsap.to('.mission', {
    //     scale: 0.9,
    //     opacity: 0,
    //   });
    //   gsap.to('.vision', {
    //     scale: 0.9,
    //   });
    //   // if (window.innerWidth > 1199) {
    //   gsap.to('.vision-mission-content', {
    //     scrollTrigger: {
    //       trigger: '.vision-mission-content',
    //       start: 'top top',
    //       end: '+=200%',
    //       // markers: true,
    //       pin: '.vision-mission-content',
    //       pinSpacer: true,
    //       onEnter: (self) => {
    //         gsap.to('.vision', {
    //           scale: 1,
    //           duration: 1,
    //         });
    //       },
    //       onLeaveBack: (self) => {
    //         // console.log('leave-back');
    //         gsap.to('.vision', {
    //           scale: 0.9,
    //         });
    //       },
    //       onUpdate: (self) => {
    //         if (self.direction === 1) {
    //           if (self.progress > 0.3) {
    //             gsap.to('.mission', {
    //               opacity: 1,
    //               duration: 0.2,
    //               delay: 0.6,
    //               zIndex: 1,
    //             });
    //             gsap.to('.mission', {
    //               y: `-${minItemHeight}`,
    //               duration: 1.5,
    //               delay: 0.6,
    //             });
    //             gsap.to('.mission', {
    //               scale: 1,
    //               duration: 1,
    //               delay: 1.3,
    //             });
    //           }
    //         } else {
    //           if (self.progress < 0.6) {
    //             gsap.to('.mission', {
    //               scale: 0.9,
    //               y: 0,
    //               duration: 1,
    //               delay: 0.5,
    //             });
    //             gsap.to('.mission', {
    //               opacity: 0,
    //               duration: 0.5,
    //               delay: 1.5,
    //             });
    //           }
    //         }
    //       },
    //     },
    //   });
    //   // }
    // }, itemRef);

    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.mission',
        {
          yPercent: 140,
        },
        {
          defaults: { duration: 1 },
          yPercent: 0,
          scrollTrigger: {
            anticipatePin: 1,
            end: () => {
              const contentElem = document.querySelector('.vision-mission-content');
              const visionElem = document.querySelector('.vision');
              const missionElem = document.querySelector('.mission');
              const offsetSpace = visionElem.offsetHeight + missionElem.offsetHeight;
              const contentHeight =
                contentElem.offsetHeight +
                (missionElem.offsetHeight - visionElem.offsetHeight) +
                80;
              contentElem.style.height = `${contentHeight}px`;
              return `+=${offsetSpace}`;
            },
            onEnter: (self) => {
              const visionElem = document.querySelector('.vision');
              visionElem.classList.add('show');
            },
            onUpdate: (self) => {
              const missionElem = document.querySelector('.mission');
              if (self.progress > visionPrValue && self.progress < 1.1) {
                if (self.direction === 1) {
                  missionElem.classList.add('show');
                  if (self.progress > missionPrValue) {
                    missionElem.classList.add('show-full');
                  }
                }
              } else {
                missionElem.classList.remove('show');
                missionElem.classList.remove('show-full');
              }
            },
            onEnterBack: (self) => {
              const missionElem = document.querySelector('.mission');
              missionElem.classList.remove('show-full');
            },
            onLeaveBack: (self) => {
              const visionElem = document.querySelector('.vision');
              visionElem.classList.remove('show');
            },
            markers: false,
            pin: '.vision-mission-content',
            // pinSpacer: true,
            scrub: true,
            start: topValue,
            trigger: '.vision',
          },
        }
      );
    }, itemRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className="vision-mission-container" ref={itemRef}>
      <div className="vision-mission-content">
        <div className="vision-mission-item vision">
          <div className="vision-mission-title">
            <h2>{props.fields.visionTile.value}</h2>
            <p>
              <RichText field={props.fields.visionBody} />
            </p>
          </div>
        </div>
        <div className="vision-mission-item mission">
          <div className="vision-mission-title">
            <h2>{props.fields.missionTitle.value}</h2>
            <p>
              <RichText field={props.fields.missionBody} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(VisonMissionComponent);
