import React, { useRef, useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '../../util';
import BtnPrimary from '../BtnPrimary';

import './esg-cover.scss';

// import Image from '../../assets/images/temp/hero-image.jpg';
// import ImageMobile from '../../assets/images/temp/hero-image-mobile.jpg';
import { ReactComponent as PlayIcon } from '../../assets/images/icons/play.svg';
import SecondaryButton from '../SecondaryButton';

const EsgCover = (props) => {
  if (isServer()) {
    return <div></div>;
  } else {
    let image, breadcrumb, heading, body, ctaSection;
    let containerClass = 'esgcover-images-container';

    const thisesgcover = useRef(null);

    // const playVideo = () => {
    //   const video = document.querySelector('.esgcover-video');
    //   const playIcon = document.querySelector('.play-video');
    //   video.play();
    //   playIcon.classList.add('play');
    //   video.addEventListener('ended', () => {
    //     playIcon.classList.remove('play');
    //   });
    // };

    // if (props.fields.video.value !== null) {
    //   image = (
    //     <div className="esgcover-video-content">
    //       <div className="play-video">
    //         <PlayIcon onClick={playVideo} />
    //       </div>
    //       <video className="esgcover-video" poster={image}>
    //         <source src="/" type="video/mp4" />
    //       </video>
    //     </div>
    //   );
    // } else
    if (
      props.fields.image.value === null ||
      (props.fields.image.value.src !== undefined &&
        props.fields.image.value.src.includes('no-hero-bg.png'))
    ) {
      containerClass = 'esgcover-images-container no-image';
    } else {
      image = (
        <div className="esgcover-image">
          <img className="d-none d-sm-block" src={props.fields.image.value.src} alt="esgcover" />
          <img className="d-block d-sm-none" src={props.fields.image.value.src} alt="esgcover" />
        </div>
      );
    }

    if (props.fields.breadcrumb !== undefined) {
      breadcrumb = props.fields.breadcrumb.value;
    }
    if (props.fields.heading !== undefined) {
      heading = props.fields.heading.value;
    }
    if (props.fields.description !== undefined) {
      body = <Text field={props.fields.description} />;
    }
    if (props.fields.linkLabel !== undefined && props.fields.linkLabel.value !== '') {
      // console.log(props.fields.linkLabel.value);
      ctaSection = (
        <div className="cta-container">
          <SecondaryButton
            link={props.fields.linkUrl.value.href}
            linkLabel={props.fields.linkLabel.value}
            icon={'ArrowRight'}
          />
        </div>
      );
    }

    // console.log(props);
    return (
      <div>
        {window && (
          <div className={containerClass} ref={thisesgcover}>
            {image}
            <div className="esgcover-images-body">
              <h4 className="space-s bottom">{breadcrumb}</h4>
              <h1>{heading}</h1>
              <p>
                <React.Fragment>{body}</React.Fragment>
              </p>
              {ctaSection}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default withSitecoreContext()(EsgCover);
