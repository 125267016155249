import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-scroll';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { isServer } from '../../util';

import './tab-menu.scss';
let startPosition;

const TabMenu = (props) => {
  if (isServer()) {
    return <div className="tab-menu-container"></div>;
  }

  const tabEl = useRef(null);
  const refItem = useRef(null);
  const tabContainer = useRef(null);
  const [offsetSpace, setOffsetSpace] = useState(-90);

  const [y, setY] = useState(document.scrollingElement.scrollHeight);
  const [scrollDirection, setScrollDirection] = useState('you have not scrolled yet');

  const getStartMenuPosition = () => {
    return document.querySelector('.tab-menu-container').offsetTop;
  };

  const handleNavigation = useCallback(
    (e) => {
      // debugger;
      let tabMenu = document.querySelector('.tab-menu-container');
      const currentTabMenuPosition = tabMenu.offsetTop;
      let scrollBottom = startPosition;
      if (y > window.scrollY) {
        setScrollDirection('Scrolling Up');
        currentTabMenuPosition > startPosition
          ? document.body.classList.add('tab-stiky')
          : document.body.classList.remove('tab-stiky');
      } else if (y < window.scrollY) {
        setScrollDirection('Scrolling Down');
        currentTabMenuPosition > startPosition
          ? document.body.classList.add('tab-stiky')
          : document.body.classList.remove('tab-stiky');
      }
      setY(window.scrollY);
    },
    [y]
  );

  const tabMenuItems = Object.keys(props.fields.menuTab).map((key) => {
    return props.fields.menuTab[key];
  });

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', handleNavigation);
    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      startPosition = getStartMenuPosition();
    }, 500);
  }, []);

  const clickAnchor = (e, point) => {
    // console.log('e', e);
    e.preventDefault();
    const tabMenuItem = document.querySelectorAll('.tab-menu-item');
    for (let i = 0; i < tabMenuItem.length; i++) {
      if (tabMenuItem[i].querySelector('a').classList.contains('active')) {
        tabMenuItem[i].querySelector('a').classList.remove('active');
        // console.log('active rimosso da: ', tabMenuItem[i]);
      }
    }
    e.target.classList.add('active');
    // console.log('active aggiunto a: ', e.target);
  };

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  waitForElm('.tab-menu-item').then((elm) => {
    let bodyId = document.querySelector('body').getAttribute('id');
    let widthCounter = 0;
    let tabItem = tabEl.current.querySelectorAll('.tab-menu-item');

    for (let i = 0; i < tabMenuItems.length; i++) {
      widthCounter += tabItem[i].offsetWidth;
    }
    if (widthCounter > window.innerWidth) {
      tabEl.current.classList.add('justify-content-start');
      tabEl.current.style.width = `${widthCounter}px`;
    } else {
      tabEl.current.classList.add('justify-content-center');
      tabEl.current.style.width = '100%';
    }

    if (bodyId === 'page-business-unit' || bodyId === 'page-media-gallery') {
      setOffsetSpace(-46);
    }
  });

  // setTimeout(() => {
  //   let widthCounter = 0;
  //   let tabItem = tabEl.current.querySelectorAll('.tab-menu-item');

  //   for (let i = 0; i < tabMenuItems.length; i++) {
  //     widthCounter += tabItem[i].offsetWidth;
  //   }
  //   if (widthCounter > window.innerWidth) {
  //     tabEl.current.classList.add('justify-content-start');
  //     tabEl.current.style.width = `${widthCounter}px`;
  //   }
  // }, 400);

  return (
    <div className="tab-menu-container" ref={tabContainer}>
      <div className="tab-menu-content" ref={tabEl}>
        {tabMenuItems.map((item, index) => {
          let tab = item.fields.url.value;
          if (tab.charAt(0) === '#') {
            let cutTub = tab.slice(1);
            tab = cutTub;
          }
          return (
            <div className="tab-menu-item" key={index}>
              {window && (
                <Link
                  ref={refItem}
                  to={tab}
                  spy={true}
                  smooth={true}
                  offset={offsetSpace}
                  duration={500}
                  // onClick={(e) => clickAnchor(e, item.fields.url.value)}
                >
                  {item.fields.label.value}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withSitecoreContext()(TabMenu);
