import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';

import './open-innovation.scss';

// import Img01 from '../../assets/images/bg-images/open-innovation-01.jpg';
// import Img02 from '../../assets/images/bg-images/open-innovation-02.jpg';
import BtnPrimary from '../BtnPrimary';
const { isServer } = require('../../util');

const OpenInnovationComponent = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div className="open-innovation-container"></div>;
  }
  return (
    <div className="open-innovation-container">
      <div className="open-innovation-content">
        <div className="open-innovation-left-area">
          <div className="open-innovation-image">
            <img src={props.fields.img01.value.src} alt={props.fields.img01.value.alt} />
          </div>
        </div>
        <div className="open-innovation-right-area">
          <div className="open-innovation-body">
            <img
              style={{ maxWidth: '50%' }}
              src={props.fields.logoImage.value.src}
              alt={props.fields.logoImage.value.alt}
            />
            <div className="space-s"></div>
            {/* <h2>{props.fields.heading.value}</h2> */}
            <p>{props.fields.body.value}</p>
            <div className="cta-container">
              <BtnPrimary
                link={props.fields.linkUrl.value}
                label={props.fields.linkLabel.value}
                icon={'ArrowRight'}
              />
            </div>
          </div>
          <div className="open-innovation-image">
            <img src={props.fields.img02.value.src} alt={props.fields.img02.value.alt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenInnovationComponent;
