import React from 'react';
import FooterNavLinks from './FooterNavLinks';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const FooterNav = (props) => {
  // const footerNavItems = Object.keys(props.items).map((key) => {
  //   return props.items[key];
  // });

  const menuItems = props.fields.menuItems;

  // console.log('FooterNav -> ', props);
  // console.log('FooterNav menuItems -> ', menuItems);

  if (menuItems !== undefined) {
    return (
      <div className="footer-navigation-container">
        <div className="container-fluid">
          <div className="row">
            {menuItems.map((item, index) => {
              if (item.displayName === 'InformationTab') {
                return (
                  <div key={index} className="footer-navigation-item col-md-3 col-lg-2">
                    <h4>{item.fields.title.value}</h4>
                    <ul className="footer-navigation-links-container">
                      <FooterNavLinks items={item.fields.subNavigationTab} />
                    </ul>
                  </div>
                );
              }
              return (
                <div key={index} className="footer-navigation-item col-md-3 col-lg-2">
                  <h4>{item.fields.title.value}</h4>
                  <ul className="footer-navigation-links-container">
                    <FooterNavLinks items={item.fields.subNavigationTab} />
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withSitecoreContext()(FooterNav);
