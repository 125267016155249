import React from 'react';
import { NavLink } from 'react-router-dom';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const FooterNavLinks = (props) => {
  // const footerNavLinks = Object.keys(props.items).map((key) => {
  //   return props.items[key];
  // });

  // console.log('FooterNavLinks -> ', props);

  const footerNavLinks = props.items;

  if (footerNavLinks) {
    return footerNavLinks.map((item, index) => (
      <li key={index} className="footer-navigation-links">
        {/* <NavLink to={item.fields.menuLink.value.href}>{item.fields.label.value}</NavLink> */}
        <a href={item.fields.menuLink.value.href}>{item.fields.label.value}</a>
      </li>
    ));
  } else {
    return null;
  }
};

export default withSitecoreContext()(FooterNavLinks);
