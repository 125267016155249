import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ReactComponent as IconDigitalTrust } from '../../assets/images/icons/digital-trust.svg';
import { ReactComponent as IconCyberSecurity } from '../../assets/images/icons/cyber-security.svg';
import { ReactComponent as IconBusinessInnovation } from '../../assets/images/icons/business-innovation.svg';

import './business-unit.scss';
const { isServer } = require('../../util');

import BtnPrimary from '../BtnPrimary/index';

const BusinessUnit = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div className="business-unit-container"></div>;
  }
  let businessUnitCards, heading, linkLabel, linkUrl;
  // console.log(props);
  if (props.item !== undefined && props.item !== null) {
    businessUnitCards = Object.keys(props.item[0].fields.businessUnitTab).map((key) => {
      return props.item[0].fields.businessUnitTab[key];
    });
    heading = props.item[0].fields.heading;
    linkUrl = props.item[0].fields.ctaLink.value;
    linkLabel = props.item[0].fields.ctaLabel.value;
  } else {
    businessUnitCards = Object.keys(props.fields.businessUnitTab).map((key) => {
      return props.fields.businessUnitTab[key];
    });
    heading = props.fields.heading;
    linkUrl = props.fields.ctaLink.value;
    linkLabel = props.fields.ctaLabel.value;
  }

  // console.log(businessUnitCards);

  return (
    <div className="business-unit-container">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <div className="business-unit-heading">
              <RichText field={heading} />
              <div className="cta-container">
                <BtnPrimary link={linkUrl} label={linkLabel} icon={'ArrowRight'} />
              </div>
            </div>
          </div>
        </div>
        <div className="business-unit-card-content">
          <div className="row justify-content-center">
            {businessUnitCards.map((item, index) => {
              // console.log('bu', item);
              return (
                <div className="col-md-7 col-lg-4 offset-lg-0" key={index}>
                  <div className="business-unit-card">
                    <a href={item.fields.buLink.value.href}>
                      <div className="business-unit-card-icon">
                        {item.fields.label.value === 'Digital Trust' ? (
                          <IconDigitalTrust />
                        ) : item.fields.label.value === 'Cyber Security' ? (
                          <IconCyberSecurity />
                        ) : (
                          <IconBusinessInnovation />
                        )}
                      </div>
                      <div className="business-unit-card-title">
                        <p>{item.fields.label.value}</p>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(BusinessUnit);
