import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import CustomCalendarContainer from './calendarContainer';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import it from 'date-fns/locale/it';
import en from 'date-fns/locale/en-GB';

import 'react-datepicker/dist/react-datepicker.css';

import './filter-tab.scss';

registerLocale('it', it);
registerLocale('en', en);

const FilterTab = (props) => {
  // console.log('filter', props);
  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  // const [selectedCategory, setSelectedCategory] = useState();
  const [orderByDesc, setOrderByDesc] = useState('');

  const labelPulisci = { en: 'Clear', 'it-IT': 'Pulisci' };
  const labelPulisciTutti = { en: 'Clear all filters', 'it-IT': 'Pulisci tutti i filtri' };
  const labelPeriodo = { en: 'Range', 'it-IT': 'Periodo' };
  const labelCategoria = { en: 'Category', 'it-IT': 'Categoria' };
  const labelOrderBy = { en: 'Order by:', 'it-IT': 'Ordina per:' };
  const labelOrderByDescending = { en: 'Newest', 'it-IT': 'Più recente' };
  const labelOrderByAscending = { en: 'Oldest', 'it-IT': 'Meno recente' };
  const labelOrderByOption = [];
  const labelStart = { en: 'From', 'it-IT': 'Da' };
  const labelEnd = { en: 'To', 'it-IT': 'A' };
  let orderby = 'DESC';

  // useEffect(() => {
  //   const selCategories = searchParams.get('c');
  //   if (selCategories) {
  //     setSelectedCategory(selCategories);
  //   }
  // }, []);

  useEffect(() => {
    if (startDate) {
      const refDate = new Date(startDate);

      searchParams.set('start', refDate.getFullYear() + '-' + (refDate.getMonth() + 1));
      window.location.search = searchParams.toString();
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      const refDate = new Date(endDate);
      // var searchParams = new URLSearchParams(window.location.search);
      searchParams.set('end', refDate.getFullYear() + '-' + (refDate.getMonth() + 1));
      window.location.search = searchParams.toString();
    }
  }, [endDate]);

  // useEffect(() => {
  //   if (selectedCategory) {
  //     searchParams.set('c', selectedCategory);
  //     window.location.search = searchParams.toString();
  //   }
  // }, [selectedCategory]);

  // useEffect(() => {
  //   // orderby = searchParams.get('orderby');
  //   // if (orderby) {
  //   setOrderByDesc(JSON.parse(window.localStorage.getItem('orderbydesc')));
  //   // }
  // }, []);

  useEffect(() => {
    if (orderByDesc !== '' && orderByDesc !== searchParams.get('orderbydesc')) {
      const refOrder = orderByDesc;
      searchParams.set('orderbydesc', refOrder);
      window.location.search = searchParams.toString();
      // window.localStorage.setItem('orderbydesc', orderByDesc);
      // window.location.reload(false);
    }
  }, [orderByDesc]);

  const thisPicker = useRef(null);
  const isGraphQL = props.items && props.items[0] && props.items[0].fields === undefined;

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="date-selctor" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  CustomInput.displayName = 'DateInput';

  let category = [];
  let categoryTab = [];
  let labelsTabProps;
  if (props.item) {
    props.items.map((item) => {
      if (!isGraphQL) {
        // console.log(1);
        labelsTabProps = item.fields.labelsTab;
      } else {
        // console.log(2, item);
        labelsTabProps = item.labelsTab;
      }
      // console.log('labelsTabProps', labelsTabProps);
      // if (labelsTabProps) {
      categoryTab = Object.keys(labelsTabProps).map((key) => {
        return labelsTabProps[key];
      });

      if (isGraphQL) {
        categoryTab = categoryTab.length > 0 ? categoryTab[1] : categoryTab;
      }

      // console.log('categoryTab', categoryTab);

      categoryTab = props.categories;

      categoryTab.map((item) => {
        let label;
        if (!isGraphQL) {
          label = item.fields.label.value;
        } else {
          label = item;
        }

        let categoryLabel = label;
        if (category.indexOf(categoryLabel) === -1) {
          category.push(item);
        }
      });
      // }
    });
  } else if (props.categories && props.categories.length > 0) {
    if (isGraphQL) {
      categoryTab = categoryTab.length > 0 ? categoryTab[1] : categoryTab;
    }

    categoryTab = props.categories;
    // console.log('categoryTab', props.categories);

    categoryTab.map((item) => {
      // let label;
      // if (!isGraphQL) {
      //   label = item;
      // } else {
      //   label = item;
      // }

      // let categoryLabel = item;
      if (category.indexOf(item) === -1) {
        category.push(item);
      }
    });
  }

  function clearSelectedDate(period) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (period === 'start') {
      params.delete('start');
    } else {
      params.delete('end');
    }
    // console.log(params.toString());
    history.push({ search: params.toString() });
    history.go(0);
  }

  function clearSelectedCategory(category) {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    params.delete('c');

    // console.log(params.toString());
    history.push({ search: params.toString() });
    history.go(0);
  }

  const handleCategoryChange = (item) => {
    // if (selectedCategory === item) {
    //   setSelectedCategory(0);
    // } else {
    const prevState = searchParams.get('c');
    // console.log('prevstate', prevState);
    if (prevState) {
      let prevArr = prevState.split('&');
      if (prevArr.indexOf(item) > -1) {
        prevArr = prevArr.filter((x) => {
          return x !== item;
        });
      } else {
        prevArr.push(item);
      }
      prevArr.length === 0
        ? searchParams.delete('c')
        : searchParams.set('c', [...prevArr].join('&'));
    } else {
      searchParams.set('c', item);
    }
    window.location.search = searchParams.toString();
  };

  const clearAllFilters = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    history.push({ search: '' });
    history.go(0);
  };

  const toggleOrderBy = () => {
    // console.log('orderByDesc', orderByDesc);
    // console.log('params', searchParams.get('orderbydesc'));
    const params = searchParams.get('orderbydesc');
    if (params === null || params === 'DESC') {
      setOrderByDesc('ASC');
      orderby = 'ASC';
      // console.log(1);
    } else {
      setOrderByDesc('DESC');
      orderby = 'DESC';
      // console.log(2);
    }
  };

  const CustomContainer = ({ children, className, period, containerCallback }) => {
    return (
      <div>
        <CalendarContainer className={className}>
          {children}
          <div className="button-section">
            <button className="btn-pulisci" onClick={(e) => containerCallback(period)}>
              {labelPulisci[currentLanguage]}
            </button>
          </div>
        </CalendarContainer>
      </div>
    );
  };

  // console.log('category', category);
  return (
    <div className="filter-tab-container">
      {category.length > 0 && (
        <div className="filter-tab-item category-tab">
          <div className="filter-title">
            <p>Filtra per:</p>
          </div>
          <Dropdown className="filter-tab-dropdown">
            <Dropdown.Toggle id="dropdown-categorie">
              {labelCategoria[currentLanguage]}
            </Dropdown.Toggle>
            <Dropdown.Menu className="filter-tab">
              {category.map((item, index) => {
                // console.log('cat', category);
                let toCheck = [];
                if (searchParams.get('c')) {
                  if (searchParams.get('c').includes('&')) {
                    toCheck = searchParams.get('c')?.split('&');
                  } else {
                    toCheck = [searchParams.get('c')];
                  }
                }
                // console.log('toCheck', toCheck[0], item, toCheck[0] === item);
                return (
                  <div key={index} className="dropdown-item form-group">
                    <div className="form-check">
                      <label>{item}</label>
                      <input
                        type="checkbox"
                        checked={toCheck.includes(item)}
                        onChange={() => handleCategoryChange(item)}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="button-section">
                <button className="btn-applica" onClick={() => clearSelectedCategory()}>
                  Pulisci
                </button>
                {/* <button className="btn-pulisci">Applica</button> */}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div className="filter-tab-item period-tab">
        <div className="filter-title">
          <p>{labelPeriodo[currentLanguage]}:</p>
        </div>
        <div className="datapiker-item start-date">
          <label>{labelStart[currentLanguage]}</label>
          <DatePicker
            locale={currentLanguage === 'en' ? 'en' : 'it'}
            selected={searchParams.get('start') ? new Date(searchParams.get('start')) : 0}
            onCalendarOpen={() => {
              if (!document.querySelector('.start-date').classList.contains('open')) {
                document.querySelector('.start-date').classList.add('open');
              }
            }}
            onCalendarClose={() => {
              document.querySelector('.start-date').classList.remove('open');
            }}
            onChange={(date) => {
              setStartDate(date);
              document.querySelector('.start-date').classList.add('selected');
            }}
            dateFormat="MMM yyyy"
            customInput={<CustomInput />}
            showMonthYearPicker
            isClearable={false}
            calendarContainer={(config) => {
              // console.log(className);
              return (
                <CustomCalendarContainer
                  className={config.className}
                  period="start"
                  onClear={(period) => clearSelectedDate(period)}
                >
                  {config.children}
                </CustomCalendarContainer>
              );
            }}
          />
        </div>
        <div className="datapiker-item end-date">
          <label>{labelEnd[currentLanguage]}</label>
          <DatePicker
            locale={currentLanguage === 'en' ? 'en' : 'it'}
            ref={thisPicker}
            selected={searchParams.get('end') ? new Date(searchParams.get('end')) : 0}
            onCalendarOpen={() => {
              document.querySelector('.end-date').classList.add('open');
            }}
            onCalendarClose={() => {
              document.querySelector('.end-date').classList.remove('open');
            }}
            onChange={(date) => {
              setEndDate(date);
              document.querySelector('.end-date').classList.add('selected');
            }}
            dateFormat="MMM yyyy"
            customInput={<CustomInput />}
            showMonthYearPicker
            isClearable={false}
            calendarContainer={(config) => {
              // console.log(className);
              return (
                <CustomCalendarContainer
                  className={config.className}
                  period="end"
                  onClear={(period) => clearSelectedDate(period)}
                >
                  {config.children}
                </CustomCalendarContainer>
              );
            }}
          />
        </div>
      </div>
      <div className="filter-tab-item custom-secondary-button">
        <button onClick={clearAllFilters}>{labelPulisciTutti[currentLanguage]}</button>
      </div>

      <div className="filter-tab-item order-tab">
        <div className="filter-title">
          <p>{labelOrderBy[currentLanguage]}</p>
        </div>
        <div className="custom-secondary-button">
          <button onClick={() => toggleOrderBy()}>
            {searchParams.get('orderbydesc') === 'DESC' || searchParams.get('orderbydesc') === null
              ? labelOrderByAscending[currentLanguage]
              : labelOrderByDescending[currentLanguage]}
          </button>
        </div>
        {/* <Dropdown className="filter-tab-dropdown">
          <Dropdown.Toggle id="dropdown-orderby"></Dropdown.Toggle>
          <Dropdown.Menu className="filter-tab">
            <Dropdown.Item eventKey="1">Più recente</Dropdown.Item>
            <Dropdown.Item eventKey="2">Meno recente</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    </div>
  );
};

export default withSitecoreContext()(FilterTab);
