import React from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const TempHomePage = ({
  sitecoreContext: {
    route: { fields },
  },
}) => {
  return <div></div>;
};

export default withSitecoreContext()(TempHomePage);
