import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BtnPrimary from '../BtnPrimary/index';
import './ourhistory-component.scss';

const OurHistory = (props) => {
  // console.log('OurHistory', props);

  return (
    <div className="homepage-container">
      <div className="story-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="story-content">
                <div className="story-content-body">
                  <h2>{props.fields.title.value}</h2>
                  <p>{props.fields.content.value}</p>
                  <div className="cta-container">
                    <BtnPrimary
                      link={props.fields.linkUrl.value.href}
                      label={props.fields.linkLabel.value}
                      icon={'ArrowRight'}
                    />
                  </div>
                </div>
                <div className="story-content-image">
                  <img src={props.fields.img.value.src} alt={props.fields.img.value.alt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(OurHistory);
