import React, { Fragment } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Tabs, Tab } from 'react-bootstrap';

import Comunicato from '../ComunicatiStampa/Comunicato';

import './search-content.scss';
import { isServer } from '../../util';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import { Redirect } from 'react-router-dom';

const ConnectedDemoQuery = gqlLoader('./search.graphql');
let searchedValue = '';
if (!isServer()) {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  searchedValue = params.get('search');
}
let language = 'it-IT';

const SearchContent = (props) => {
  if (isServer()) {
    return <></>;
  }

  if (!searchedValue || searchedValue === '') {
    return <Redirect to="/" />;
  }

  language = props.sitecoreContext.language;
  // console.log('langSearch', language);

  const graphQLResult = props.connectedQuery;
  const hasNoResults =
    !graphQLResult ||
    graphQLResult.loading ||
    !graphQLResult.searchResults ||
    !graphQLResult.searchResults.results ||
    !graphQLResult.searchResults.results.length > 0;

  // const categoryTab = Object.keys(props.fields.categoryTab).map((key) => {
  //   return props.fields.categoryTab[key];
  // });

  // console.log('SearchContent', props);
  // console.log('SearchContent searchedValue', searchedValue);
  // console.log('SearchContent graphQLResult', graphQLResult);

  return (
    <div className="search-results-container">
      <div className="container-fluid">
        <div className="search-results-heading">
          <label>{language === 'it-IT' ? 'Risultati di ricerca per' : 'Search result for'}</label>
          <h2>
            {searchedValue} <span>({hasNoResults ? '0' : graphQLResult.searchResults.total})</span>
          </h2>
        </div>
        <div className="search-results-tab">
          {hasNoResults && (
            <div className="space-s">
              {language === 'it-IT'
                ? 'Nessun risultato per la ricerca'
                : 'No results for your search'}
              <div className="space-l"></div>
            </div>
          )}
          {!hasNoResults && (
            <Fragment>
              {/* <Tabs id="search-results-tab" fill defaultActiveKey="0">
                {categoryTab.map((item, index) => {
                  return (
                    <Tab
                      eventKey={index}
                      key={index}
                      title={item.fields.category.value}
                      className="search-results-item"
                    >
                      {item.fields.resultList.map((item, index) => {
                        return <Comunicato item={item} key={index} />;
                      })}
                    </Tab>
                  );
                })}
              </Tabs> */}
              <div className="search-results-item">
                {graphQLResult.searchResults.results.map((item, index) => {
                  // console.log('URL', item.url.value);
                  // if (!item.url.value.startsWith('/sitecore/content/tinexta/Components')) {
                  return <Comunicato item={item} key={index} actionType="link" />;
                  // }
                })}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

// export default withSitecoreContext()(SearchContent);
export default GraphQLData(ConnectedDemoQuery, {
  name: 'connectedQuery',
  options: {
    variables: {
      search: searchedValue,
      language: language,
    },
  },
})(SearchContent);
