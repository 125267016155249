import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// const options = {
//   damping: 0.07,
//   delegateTo: document,
// };

const SmoothScroll = ({ children }) => {
  // const bodyScrollBar = Scrollbar.init(document.body, { damping: 0.1, delegateTo: document });

  // bodyScrollBar.setPosition(0, 0);
  // bodyScrollBar.track.xAxis.element.remove();

  // // How to get them to work together
  // ScrollTrigger.scrollerProxy('body', {
  //   scrollTop(value) {
  //     if (arguments.length) {
  //       bodyScrollBar.scrollTop = value;
  //     }
  //     return bodyScrollBar.scrollTop;
  //   },
  // });

  return null;

  //   return (
  //     <div className="scroll-container">
  //       <Scrollbar
  //         plugins={{
  //           overscroll: {
  //             effect: 'bounce',
  //           },
  //         }}
  //       >
  //         {children}
  //       </Scrollbar>
  //     </div>
  //     // <div className="scroll-container">
  //     //   <div ref={scrollingContainerRef}>{children}</div>
  //     // </div>
  //   );
};

export default SmoothScroll;
