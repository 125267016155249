import React, { useEffect } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import BtnPrimary from '../BtnPrimary/index';
import { useTranslation } from 'react-i18next';

import './card-evento.scss';
import { isServer } from '../../util';

const CardEvento = (props) => {
  if (isServer()) {
    return <div className="card-evento-container"></div>;
  }

  const componentProps = props.item && props.item.fields ? props.item.fields : props.item;

  // console.log('CardEvento', props);

  // console.log('CardEvento', componentProps);

  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;

  const toMonthName = (date, monthNumber) => {
    date.setMonth(monthNumber);
    return date.toLocaleString(currentLanguage, {
      month: 'long',
    });
  };

  const isEvent = componentProps.__typename
    ? componentProps.__typename.toLowerCase().includes('event')
    : true;
  let linkUrl =
    componentProps.url && componentProps.url.value && componentProps.url.value !== ''
      ? componentProps.url.value
      : componentProps.linkUrl.value.href;

  let downloadUrl =
    componentProps.downloadUrl &&
    componentProps.downloadUrl.value &&
    componentProps.downloadUrl.value !== ''
      ? componentProps.downloadUrl.value
      : componentProps.downloadUrl.value.href;

  let eventDate;
  let eventTitle = componentProps.title;

  // console.log('linkUrl', linkUrl);
  // console.log('downloadUrl', downloadUrl);

  let firstDate = componentProps.startDate
    ? new Date(componentProps.startDate.value)
    : new Date(componentProps.firstDate.value);
  let secondDate = componentProps.endDate
    ? new Date(componentProps.endDate.value)
    : new Date(componentProps.secondDate.value);

  let firstDateYear = firstDate.getFullYear();
  let firstDateMonth = firstDate.getMonth();
  let firstDateDay = firstDate.getDate();

  let secondDateYear = secondDate.getFullYear();
  let secondDateMonth = secondDate.getMonth();
  let secondDateDay = secondDate.getDate();

  if (firstDate.toString() === secondDate.toString()) {
    secondDateMonth = toMonthName(secondDate, secondDate.getMonth());
    eventDate =
      currentLanguage === 'it-IT' ? (
        <span>{secondDateDay + ' ' + secondDateMonth.toLocaleString() + ' ' + secondDateYear}</span>
      ) : (
        <span>
          {secondDateMonth.toLocaleString() + ' ' + secondDateDay + ', ' + secondDateYear}
        </span>
      );
  } else {
    firstDateMonth = toMonthName(firstDate, firstDate.getMonth());
    secondDateMonth = toMonthName(secondDate, secondDate.getMonth());
    eventDate =
      currentLanguage === 'it-IT' ? (
        <>
          <span>{firstDateDay + ' ' + firstDateMonth.toLocaleString() + ' ' + firstDateYear}</span>
        </>
      ) : (
        <>
          <span>{firstDateMonth.toLocaleString() + ' ' + firstDateDay + ', ' + firstDateYear}</span>
        </>
      );
  }

  // console.log('eventdate', eventDate);

  return (
    <div className={'card-evento-container' + (isEvent ? '' : ' bg-news')}>
      <div className="card-evento-content">
        <div className="card-body">
          {/* <div className="card-tag">
            <h4>{isEvent ? 'TBD: Eventi' : 'News'}</h4>
          </div> */}
          <div className="card-date">
            {/* {componentProps ? <h4>{componentProps.dataEvent.value}</h4> : <h4>{eventDate}</h4>} */}
            <h4>{eventDate}</h4>
          </div>
          <div className="card-label">
            {/* {componentProps ? <h3>{componentProps.label.value}</h3> : <h3>{eventTitle}</h3>} */}
            <h3>
              <Text field={eventTitle} />
            </h3>
          </div>
        </div>
        {downloadUrl && downloadUrl.href !== '' && (
          <div className="card-cta">
            <BtnPrimary
              link={downloadUrl.href}
              label={componentProps.downloadLab.value}
              icon={'Download'}
              type="download"
            />
          </div>
        )}
        {linkUrl && linkUrl !== '' && (
          <div className="card-cta" style={{ marginTop: '5px' }}>
            <BtnPrimary link={linkUrl} label={componentProps.urlLabel.value} icon={'ArrowRight'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withSitecoreContext()(CardEvento);
