import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { withSitecoreContext, Link, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const SubNavigationItem = (props) => {
  const [activeState, setActiveState] = useState('');
  const location = useLocation();
  const thisUrlSplit = location.pathname.split('/')[1];
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const langPathLink = `/${currentLanguage}${props.menuLink.value.href}`;
  // return <NavLink to={props.menuLink.value.href}>{props.menuVoice}</NavLink>;
  return (
    <>
      {thisUrlSplit === currentLanguage && langPathLink === location.pathname ? (
        <a className="active" href={props.menuLink.value.href}>
          {props.menuVoice}
        </a>
      ) : (
        <a href={props.menuLink.value.href}>{props.menuVoice}</a>
      )}
    </>
  );
};

export default withSitecoreContext()(SubNavigationItem);
