import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { gsap } from 'gsap';
import { ScrollTrigger, scrollTween } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import BtnPrimary from '../BtnPrimary/index';
import GradientText from '../GradientText/index';
import BusinessUnit from '../BusinessUnit/index';

import './homepage-component.scss';

import HeroImage from '../../assets/images/temp/hero-image.jpg';
import Group01 from '../../assets/images/bg-images/group-01.png';
import Group02 from '../../assets/images/bg-images/group-02.png';
import Group03 from '../../assets/images/bg-images/group-03.png';
import Group01Mobile from '../../assets/images/bg-images/group-01-mobile.png';
import Group02Mobile from '../../assets/images/bg-images/group-02-mobile.png';
import Group03Mobile from '../../assets/images/bg-images/group-03-mobile.png';
import CardGradient from '../CardGradient';

const HomePageComponent = (props) => {
  const groupSteps = Object.keys(props.fields.groupSection).map((key) => {
    return props.fields.groupSection[key];
  });
  const numbers = Object.keys(props.fields.numbersSection).map((key) => {
    return props.fields.numbersSection[key];
  });
  let groupStepsItems;

  for (var i = 0; i <= groupSteps.length; i++) {
    groupStepsItems = i;
  }

  useEffect(() => {
    if (window.innerWidth > 991) {
      let stepsContainer = document.querySelector('.group-container');
      let stepsWidth = 100 * groupStepsItems;
      document.querySelector('.group-container').style.width = `${stepsWidth}vw`;

      gsap.to('.group-container', {
        xPercent: -100,
        x: () => innerWidth,
        ease: 'none',
        scrollTrigger: {
          trigger: '.group-container',
          start: 'top top',
          end: () => innerWidth * groupStepsItems,
          scrub: true,
          pin: true,
          invalidateOnRefresh: true,
          anticipatePin: 1,
        },
      });
    }
  });

  return (
    <div className="homepage-container">
      <div className="container-fluid space-s">
        <div className="row">
          <div className="col-md-12">
            <div className="homepage-gradient-text">
              <GradientText text={props.fields.heading.value} />
            </div>
            <div className="space-l"></div>
          </div>
        </div>
      </div>
      <div className="homepage-numbers-container">
        <div className="container-fluid">
          {isCarousel && (
            <div className="col-12">
              <Swiper
                onSwiper={setSwiperRef}
                slidesPerView={'auto'}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                centeredSlides={false}
                ref={slider}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {numbers.map((item, index) => {
                  let numbersColumn;
                  if (item.fields.number !== undefined && item.fields.number.value !== '') {
                    numbersColumn = '';
                  } else {
                    numbersColumn = '';
                  }

                  return (
                    <SwiperSlide key={index}>
                      <div className={numbersColumn} key={index}>
                        {item.fields.number !== undefined && item.fields.number.value !== '' ? (
                          <div className="numbers-body">
                            {item.fields.label && (
                              <span className="numbers-label">
                                <Text field={item.fields.label} />
                              </span>
                            )}
                            <h2>
                              <Text field={item.fields.number} />
                            </h2>
                            <label>
                              <Text field={item.fields.body} />
                            </label>
                          </div>
                        ) : (
                          <div className="numbers-body">
                            <p>
                              <Text field={item.fields.body} />
                            </p>
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
          {!isCarousel && (
            <div className="row">
              {numbers.map((item, index) => {
                let numbersColumn;
                if (item.fields.number !== undefined && item.fields.number.value !== '') {
                  numbersColumn = 'col-lg-4';
                } else {
                  numbersColumn = 'col-lg-5 offset-lg-1';
                }
                return (
                  <div className={numbersColumn} key={index}>
                    {item.fields.number !== undefined && item.fields.number.value !== '' ? (
                      <div className="numbers-body">
                        {item.fields.label && (
                          <span className="numbers-label">
                            <Text field={item.fields.label} />
                          </span>
                        )}
                        <h2>
                          <Text field={item.fields.number} />
                        </h2>
                        <label>
                          <Text field={item.fields.body} />
                        </label>
                      </div>
                    ) : (
                      <div className="numbers-body">
                        <p>
                          <Text field={item.fields.body} />
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="business-unit-section">
        <BusinessUnit item={props.fields.businessUnit} />
      </div>
      <div className="group-section">
        <div className="group-container">
          {groupSteps.map((item, index) => {
            let groupImage;
            if (index === 0) {
              if (window.innerWidth > 991) {
                groupImage = Group01;
              } else {
                groupImage = Group01Mobile;
              }
            } else if (index === 2) {
              if (window.innerWidth > 991) {
                groupImage = Group02;
              } else {
                groupImage = Group02Mobile;
              }
            } else if (index === 4) {
              if (window.innerWidth > 991) {
                groupImage = Group03;
              } else {
                groupImage = Group03Mobile;
              }
            }
            return (
              <div className="group-item" key={index}>
                <div className="group-item-body">
                  <div className="number-content">
                    <span>{`0${index + 1}`}</span>
                  </div>
                  <div className="group-item-body-content">
                    <h4>{item.fields.label.value}</h4>
                    <GradientText text={item.fields.heading.value} />
                  </div>
                </div>
                <div className="group-item-image">
                  {groupImage !== undefined ? (
                    <img src={groupImage} alt="alt" />
                  ) : (
                    <img className="d-none" alt="none" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="space-l"></div>
      </div>
      <div className="story-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="story-content">
                <div className="story-content-body">
                  <h2>La nostra storia</h2>
                  <p>
                    Siamo nati nel 2009 con l’apporto di Tecno Holding, società partecipata dalle
                    principali Camere di commercio italiane e da Unioncamere. La radice
                    istituzionale garantisce ancora oggi la nostra missione pubblica: supportare lo
                    sviluppo economico coniugando la capacità imprenditoriale e la crescita sociale,
                    l’efficienza operativa e la responsabilità nei confronti del territorio.
                  </p>
                  <div className="cta-container">
                    <BtnPrimary link={'#'} label={'Scopri la nostra storia'} icon={'ArrowRight'} />
                  </div>
                </div>
                <div className="story-content-image">
                  <img src={HeroImage} alt="hero image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-l"></div>
    </div>
  );
};

export default HomePageComponent;
