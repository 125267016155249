import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withSitecoreContext, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { gsap, power2 } from 'gsap';
import { ReactComponent as EsgLogo } from '../../assets/images/logo/esg-logo.svg';
import { ReactComponent as EsgLogoMobile } from '../../assets/images/logo/esg-logo-mobile.svg';
import { ScrollTrigger, ScrollTween } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import GradientText from '../GradientText/index';

const { isServer } = require('../../util');
import Icon from '../Icon/index';
import IconTabContent from '../IconTabContent/index';
import NostriProgetti from '../NostriProgetti/index';
import ComunicatiStampa from '../ComunicatiStampa/index';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import './esg.scss';

const EsgComponent = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div id="esg-section" className="esg-component-container"></div>;
  }
  const [scrollDirection, setScrollDirection] = useState(true);
  const itemRef = useRef(null);
  // console.log('esg', props);

  useEffect(() => {
    const ctx = gsap.context(() => {
      let hedingHeight = document.querySelector('.esg-logo-heading .container').offsetHeight;
      gsap.to('.esg-content-item', { yPercent: 350 });
      let esgAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: '.esg-content-container',
          scrub: true,
          start: 'top top',
          end: '+=300%',
          pin: '.esg-content-container',
          pinSpacer: false,
          markers: false,
          onEnter: (self) => {
            document.querySelector('.esg-logo-heading').style.minHeight = `${hedingHeight}px`;
          },
          onUpdate: (self) => {
            if (self.progress > 0.01 && self.progress < 1) {
              if (!document.querySelector('.esg-logo-heading').classList.contains('hide-heading')) {
                document.querySelector('.esg-logo-heading').classList.add('hide-heading');
              }
            }
            if (self.progress > 0.01 && self.progress < 1) {
              if (!document.querySelector('.esg-logo-content').classList.contains('zoom-logo')) {
                document.querySelector('.esg-logo-content').classList.add('zoom-logo');
              }
            }
            if (self.progress <= 0.01) {
              if (document.querySelector('.esg-logo-heading').classList.contains('hide-heading')) {
                document.querySelector('.esg-logo-heading').classList.remove('hide-heading');
              }
            }
            if (self.progress <= 0.01) {
              document.querySelector('.esg-logo-content').classList.remove('zoom-logo');
            }
          },
        },
      });
      esgAnimation
        .fromTo('.esg-content-item', { yPercent: 350 }, { yPercent: -50 })
        .fromTo(
          '.esg-gradient-container',
          { opacity: '0' },
          { opacity: '1' },
          { opacity: '1' },
          { opacity: '1' }
        );
    }, itemRef);
    return () => ctx.revert();
  }, []);

  // console.log(props);

  return (
    <div id="esg-section" className="esg-component-container" ref={itemRef}>
      <div className="esg-content-container">
        <div className="esg-logo-container">
          <div className="esg-logo-content">
            <EsgLogo className="d-none d-sm-block" preserveAspectRatio="xMinYMin" />
            <EsgLogoMobile className="d-block d-sm-none" preserveAspectRatio="xMinYMin" />
          </div>
          <div className="esg-logo-heading">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-6">
                  {/* <p>{props.fields.heading.value}</p> */}
                  {/* <div className="cta-container">
                    <div className="custom-secondary-button">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          startAnimation();
                        }}
                      >
                        {props.fields.linkLabel.value}
                      </span>
                      <Icon icon={'ArrowRight'} />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="esg-gradient-container"></div>
        <div className="esg-gradient-text-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-6">
                <div className="esg-content-item">
                  <p>{props.fields.paragraph.value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="esg-subtext space-m">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-6">
              <GradientText text={props.fields.subText.value} />
              {/* <p>{props.fields.subText.value}</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="esg-icon-tab">
        <IconTabContent item={props.fields.iconTab} />
      </div>
      <div className="esg-custom-block">
        <RichText field={props.fields.policy} />
      </div>
      <div className="esg-projects">
        <NostriProgetti item={props.fields.projectsContent} />
      </div>
      <div className="esg-comunicati space-l">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <ComunicatiStampa item={props.fields.comunicatiStampa} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="esg-custom-block">
        <RichText field={props.fields.comitato} />
      </div>
    </div>
  );
};

export default EsgComponent;
