import React, { useEffect, useRef, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import BtnPrimary from '../BtnPrimary/index';
import './numbers-component.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
const { isServer } = require('../../util');

const NumbersSection = (props) => {
  if (isServer()) {
    // console.log('isServer');
    return <div className="homepage-numbers-container"></div>;
  }

  const slider = useRef(null);
  const numbSliderRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);

  useEffect(() => {
    if (swiperRef) {
      swiperRef.slideTo(0);
      setTimeout(() => {
        let loadHeight = slider.current.offsetHeight;
        slider.current.querySelector('.swiper-wrapper').style.minHeight = `${loadHeight}px`;
      }, 600);
    }

    if (isCarousel) {
      numbSliderRef.current.classList.add('number-slider');
    }
  }, []);

  const isCarousel = props.fields.isCarousel && props.fields.isCarousel.value;
  const numbers = Object.keys(props.fields.numbersSection).map((key) => {
    return props.fields.numbersSection[key];
  });

  const backgroundColor = isCarousel ? '' : 'white-bg';

  return (
    <div className={`homepage-numbers-container ${backgroundColor}`} ref={numbSliderRef}>
      <div className="container-fluid">
        {isCarousel && (
          <div className="col-12">
            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={'auto'}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              centeredSlides={false}
              ref={slider}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {numbers.map((item, index) => {
                let numbersColumn;
                if (item.fields.number !== undefined && item.fields.number.value !== '') {
                  numbersColumn = '';
                } else {
                  numbersColumn = '';
                }

                return (
                  <SwiperSlide key={index}>
                    <div className={numbersColumn} key={index}>
                      {item.fields.number !== undefined && item.fields.number.value !== '' ? (
                        <div className="numbers-body">
                          {item.fields.label && item.fields.label.value && (
                            <span className="numbers-label">
                              <Text field={item.fields.label} />
                            </span>
                          )}
                          <h2>
                            <Text field={item.fields.number} />
                          </h2>
                          <label>
                            <Text field={item.fields.body} />
                          </label>
                        </div>
                      ) : (
                        <div className="numbers-body">
                          <p>
                            <Text field={item.fields.body} />
                          </p>
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
        {!isCarousel && (
          <div className="row justify-content-center">
            {numbers.map((item, index) => {
              let numbersColumn;
              if (item.fields.number !== undefined && item.fields.number.value !== '') {
                numbersColumn = 'col-lg-3 justify-content-center d-flex';
              } else {
                numbersColumn = 'col-lg-5 offset-lg-1';
              }
              return (
                <div className={numbersColumn} key={index}>
                  {item.fields.number !== undefined && item.fields.number.value !== '' ? (
                    <div className="numbers-body">
                      {item.fields.label && item.fields.label.value !== '' && (
                        <span className="numbers-label">
                          <Text field={item.fields.label} />
                        </span>
                      )}
                      <h2>
                        <Text field={item.fields.number} />
                      </h2>
                      <label>
                        <Text field={item.fields.body} />
                      </label>
                    </div>
                  ) : (
                    <div className="numbers-body">
                      <p>
                        <Text field={item.fields.body} />
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default withSitecoreContext()(NumbersSection);
