import React from 'react';
import { withSitecoreContext, Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';

import './comunicati-detail-content.scss';
// import { Link } from 'react-router-dom';
import BtnPrimary from '../BtnPrimary/index';
import { isServer } from '../../util';
import { Redirect } from 'react-router-dom';
import ComunicatoGallery from '../ComunicatoGallery';

const FotoGalleryDetail = (props) => {
  // console.log('ComunicatiDetailContent', props);

  if (isServer()) {
    return <></>;
  }
  window.scrollTo(0, 0);
  let componentProps;
  if (props && props.sitecoreContext && props.sitecoreContext.route) {
    componentProps = props.sitecoreContext.route.fields;
  } else if (props.fields) {
    componentProps = props.fields;
  } else {
    return <Redirect to="/" />;
  }

  // console.log('props', componentProps.mediaGallery.imageList === undefined);
  return (
    <div className="container-fluid comunicati-detail-container">
      <div className="row">
        <div className="m-auto col-lg-10 col-xl-8">
          <div className="comunicati-detail-item">
            <div className="comunicati-detail-heading">
              <div className="event-detail-date-container">
                {componentProps.date && (
                  <div className="event-detail-date-item">
                    <div className="event-detail-date">
                      {/* <span>
                        {new Date(componentProps.date.value).getDate() +
                          '/' +
                          (new Date(componentProps.date.value).getMonth() + 1) +
                          '/' +
                          new Date(componentProps.date.value).getFullYear()}
                      </span> */}
                    </div>
                  </div>
                )}
              </div>
              <div className="comunicati-detail-title">
                <h1>
                  <Text field={componentProps.title} />
                </h1>
                {componentProps.description && (
                  <h3>
                    <RichText field={componentProps.description} />
                  </h3>
                )}
              </div>
              {componentProps.paragraph && (
                <div className="comunicati-detail-content">
                  <RichText field={componentProps.paragraph} />
                </div>
              )}
              <div className="comunicati-detail-content">
                {componentProps.mediaGallery.imageList === undefined ||
                  (componentProps.mediaGallery.imageList === '' && (
                    <img src={componentProps.image.value.src} style={{ maxWidth: '100%' }}></img>
                  ))}
              </div>
            </div>
            {componentProps.mediaGallery.imageList !== '' && (
              <ComunicatoGallery imageList={componentProps.mediaGallery} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(FotoGalleryDetail);
