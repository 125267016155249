import React, { Fragment } from 'react';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Comunicato from '../ComunicatiStampa/Comunicato';
import FilterTab from '../FilterTab/index';

import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';

const ConnectedDemoQuery = gqlLoader('./query.graphql');
let language = 'it-IT';

const ComunicatiListLast3 = (props) => {
  language = props.sitecoreContext.language;

  const graphQLResult = props.connectedQuery;
  let contentHTML = [];
  if (
    !graphQLResult ||
    (!graphQLResult.loading &&
      (!graphQLResult.comunicatiList || graphQLResult.comunicatiList.length === 0))
  ) {
    contentHTML.push(
      <div>
        {language === 'it-IT' ? 'Nessun comunicato da visualizzare' : 'No press release to show'}
      </div>
    );
  } else {
    const { error, loading, comunicatiList } = graphQLResult;

    if (!loading && !error) {
      const comunicati = comunicatiList.results;
      if (props.fields.title && props.fields.title.value !== '') {
        contentHTML.push(
          <div className="comunicati-titles" key="comunicati-titles">
            <h2>{props.fields.title.value}</h2>
            {props.fields.subtitle ? <p>{props.fields.subtitle.value}</p> : null}
          </div>
        );
      } else {
        // contentHTML.push(<FilterTab items={comunicati} key="filterTab" />);
      }

      contentHTML.push(
        comunicati.map((item, index) => {
          return <Comunicato item={item} key={index} />;
        })
      );
    }
  }

  return <Fragment>{contentHTML}</Fragment>;
};
export default GraphQLData(ConnectedDemoQuery, {
  name: 'connectedQuery',
  options: {
    variables: {
      quantity: 3,
      language: language,
    },
  },
})(ComunicatiListLast3);
