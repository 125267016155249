import React from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Redirect } from 'react-router-dom';

const RedirectComponent = (props) => {
  return (
    <Redirect
      to={props.redirect && props.redirect.value.html !== '' ? props.redirect.value.html : '/'}
    />
  );
};

export default withSitecoreContext()(RedirectComponent);
