import React, { Fragment, useState } from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Spinner from 'react-bootstrap/Spinner';
import Comunicato from '../ComunicatiStampa/Comunicato';
import FilterTab from '../FilterTab';
import ListPagination from '../ComunicatiList/ListPagination';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import { isServer } from '../../util';

const ConnectedDemoQuery = gqlLoader('./query.graphql');
let language = 'it-IT';
let sourceFolder = 'FA72EE0F-D59D-49B6-9C36-21A5023EE223';

let page = 1;
let dtStart = '';
let dtEnd = '';
let orderby = 'DESC';
if (typeof window !== 'undefined') {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  page = params.get('page') !== null ? params.get('page') : 1;
  dtStart = params.get('start') !== null ? new Date(params.get('start') + '-1').getTime() : '';
  dtEnd = params.get('end') !== null ? new Date(params.get('end') + '-31').getTime() : '';
  orderby = params.get('orderbydesc') !== null ? params.get('orderbydesc') : 'DESC';
}

let quantity = 0;
let after = 0;

if (!dtStart && !dtEnd) {
  quantity = 10;
  after = quantity * (page - 1);
  after = Buffer.from(after.toString()).toString('base64');
}

// For archived events
const EventiList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  language = props.sitecoreContext.language;

  if (isServer()) {
    return <></>;
  }
  const graphQLResult = props.connectedQuery;
  let contentHTML = [];

  // console.log('EventiList props', props);
  // console.log('EventiList graphQLResult', graphQLResult);

  if (graphQLResult.loading) {
    return (
      <>
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div className="space-l"></div>
      </>
    );
  }
  if (
    !graphQLResult ||
    (!graphQLResult.loading && (!graphQLResult.eventsList || graphQLResult.eventsList.length === 0))
  ) {
    contentHTML.push(<div key="noEvents">Nessun Evento IR</div>);
  } else {
    const { error, loading, eventsList } = graphQLResult;

    if (!loading && !error) {
      const now = Date.now();
      let eventi = eventsList.results;
      // const eventi = eventsList.results.filter(
      //   (x) => x.firstDate.value < now && x.secondDate.value < now
      // );
      if (dtStart || dtEnd) {
        eventi = eventsList.results.filter((item) => {
          if (!dtEnd) {
            return item.firstDate.value >= dtStart;
          } else if (!dtStart) {
            // console.log('check start', item.date.value + ' - ' + Date.now());
            return item.firstDate.value <= dtEnd;
          } else if (dtStart && dtEnd) {
            return item.firstDate.value >= dtStart && item.firstDate.value <= dtEnd;
          }
        });
      }

      // console.log('EVENTILIST', eventi);

      if (props.fields.title && props.fields.title.value !== '') {
        contentHTML.push(
          <div className="comunicati-titles" key="comunicatiTitles">
            <h2>{props.fields.title.value}</h2>
            {props.fields.subtitle ? <p>{props.fields.subtitle.value}</p> : null}
          </div>
        );
        contentHTML.push(<FilterTab items={eventi} key="filterTab" />);
      } else {
        contentHTML.push(<FilterTab items={eventi} key="filterTab" />);
      }

      if (eventi.length > 0) {
        contentHTML.push(
          eventi.map((item, index) => {
            return <Comunicato item={item} key={'archivedEvents_' + index} />;
          })
        );

        if (eventsList.total > quantity && !dtStart && !dtEnd) {
          let pages = eventsList.total / quantity;
          if (eventsList.total % quantity > 0) {
            pages++;
          }
          contentHTML.push(
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="text-center pagination-container">
                  <ListPagination
                    itemsCount={eventsList.total}
                    itemsPerPage={quantity}
                    currentPage={page}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                  />
                </div>
              </div>
            </div>
          );
          contentHTML.push(<div className="space-l"></div>);
        }
      } else {
        contentHTML.push(<div key="noArchivedEvents">Nessun Evento IR archiviato</div>);
      }
    }
  }

  return (
    <div className="container-fluid comunicati-list-container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="comunicato-container space-l">
            <Fragment>{contentHTML}</Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphQLData(ConnectedDemoQuery, {
  name: 'connectedQuery',
  options: {
    variables: {
      language: language,
      sourceFolder: sourceFolder,
      quantity: quantity,
      after: after,
      order: orderby,
    },
  },
})(EventiList);
